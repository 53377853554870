import React, { Component, Fragment } from 'react';
import PropTypes, { any, func } from 'prop-types';
import { toLower, split, endsWith, some } from 'lodash';
import NumberFormat from 'react-number-format';

import { transactionService } from '../../services';
import { CurrencyMap, invokeIfFunction } from '../../../Common/utilities';
import { UNEXPECTED_ERROR_MSG } from '../../../components/error';
import { withCancelable } from '../cancelable';

const requestKeys = {
	FETCH: 'fetch',
};

class AdjustComponent extends Component {
	constructor(props) {
		super(props);

		const [paymentMethod, transactionType] = split(toLower(props.type), ':');

		const tip = props.tip || 0;

		this.state = {
			amount: props.amount - tip,
			fullAmount: props.amount,
			tip: tip,
			description: props.description,
			orderId: props.orderId,
			custom1: props.custom1,
			custom2: props.custom2,
			custom3: props.custom3,
			paymentMethod,
			transactionType,
			isLoading: false,
			customDisplayLabels: props.customDisplayLabels,
		};
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.props.currency);
	}

	async componentDidMount() {
		const { handleError, makePendingRequest, refNum, xMerchantId } = this.props;
		const { orderId, custom1, custom2, custom3 } = this.state;

		try {
			if (some([orderId, custom1, custom2, custom3], item => item === undefined)) {
				this.showLoader(true);
				const { xOrderID = '', xCustom01 = '', xCustom02 = '', xCustom03 = '' } = await makePendingRequest(
					transactionService.getTransaction(refNum, xMerchantId),
					requestKeys.FETCH
				);
				this.setState(
					{ orderId: xOrderID, custom1: xCustom01, custom2: xCustom02, custom3: xCustom03 },
					this.showLoader
				);
			}
		} catch (e) {
			this.closeModal();
			this.showLoader();
			handleError(e);
		}
	}

	showLoader = (isLoading = false) => this.setState({ isLoading });

	handleChange = e => {
		const { name, value, checked, type } = e.target;
		const val = type === 'checkbox' ? checked : value;
		this.setState({
			[name]: val,
		});
	};

	blurFullAmountInput = () => {
		if (this.fullAmountRef && !endsWith(this.state.fullAmount, '.')) {
			this.fullAmountRef.blur();
			this.fullAmountRef.focus();
		}
	};

	blurTipInput = () => {
		if (this.tipRef && !endsWith(this.state.tip, '.')) {
			this.tipRef.blur();
			this.tipRef.focus();
		}
	};

	handleTipChange = ({ floatValue = 0, value }) => {
		const { amount = 0 } = this.state;
		const fullAmount = amount + floatValue;
		this.setState(
			{
				tip: value,
				fullAmount,
			},
			this.blurTipInput
		);
	};

	handleFullAmountChange = ({ floatValue = 0, value }) => {
		const { tip = '0' } = this.state;
		const floatTip = parseFloat(tip);
		if (floatValue < floatTip) {
			floatValue = floatTip;
			value = tip;
		}
		let amount = floatValue;
		if (floatTip) {
			amount -= floatTip;
		}
		this.setState(
			{
				amount,
				fullAmount: value,
			},
			this.blurFullAmountInput
		);
	};

	showLoader = isLoading => {
		this.setState({ isLoading });
		invokeIfFunction(this.props.setLoading, isLoading);
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	closeModal = () => {
		this.props.closeModal();
	};

	adjustTransaction = () => {
		const { refNum, xRefnumCurrent, xMerchantId } = this.props;
		const {
			fullAmount,
			tip,
			description,
			orderId,
			custom1,
			custom2,
			custom3,
			paymentMethod,
			transactionType,
			isLoading,
		} = this.state;
		if (isLoading) {
			return;
		}
		this.showLoader(true);

		transactionService
			.transactionAdjust(
				refNum,
				xRefnumCurrent,
				paymentMethod,
				transactionType,
				fullAmount,
				tip,
				description,
				orderId,
				custom1,
				custom2,
				custom3,
				false,
				xMerchantId
			)
			.then(rsp => {
				this.showLoader(false);
				this.props.notificationRef.addNotification({
					ref: rsp.xRefNum,
					showViewTransaction: true,
					message: 'Transaction adjusted',
					success: true,
					onClose: this.refreshGridData,
				});
				this.closeModal();
			})
			.catch(e => {
				if (!e || !e.isCanceled) {
					this.showLoader(false);
					this.props.notificationRef.addNotification({
						ref: e && e.ref,
						message: (e && e.message) || UNEXPECTED_ERROR_MSG,
						success: false,
						onClose: this.refreshGridData,
					});
					this.closeModal();
				}
			});
	};

	getCustomDisplayLabel = (label, defaultLabel) => {
		const { customDisplayLabels } = this.state;
		let labels = customDisplayLabels;
		if (!labels) return defaultLabel;
		if (customDisplayLabels.data) {
			labels = customDisplayLabels.data;
		}
		return labels[label] || defaultLabel;
	};

	renderBody = () => {
		const { fullAmount, tip, description, orderId, custom1, custom2, custom3, transactionType, isLoading } = this.state;

		return (
			<form className="modal__body">
				{isLoading ? (
					<div className="loader--modal__holder">
						<div className="loader__spinner"></div>
					</div>
				) : (
					<Fragment>
						<div className="message message--default display--b spc--bottom--sml">
							<p>Fields that currently contain information cannot be left blank.</p>
						</div>
						{transactionType === 'authonly' && (
							<Fragment>
								<div className="spc--bottom--sml">
									<label className="type--wgt--medium">
										Amount{' '}
										<span className="spc--left--nano type--color--text--light type--wgt--regular">
											{tip ? '(including tip)' : null}
										</span>
									</label>
									<div className="inputgroup">
										<div className="inputgroup--aside inputgroup--aside--bordered inputgroup--aside--iconed">
											<span>{this.currencyCode}</span>
										</div>
										<div className="inputgroup--main inputgroup--main--bordered">
											<NumberFormat
												className="input input--med inputgroup__input"
												getInputRef={el => {
													this.fullAmountRef = el;
												}}
												placeholder="0"
												thousandSeparator=","
												decimalSeparator="."
												inputMode="numeric"
												allowNegative={false}
												decimalScale={2}
												onValueChange={this.handleFullAmountChange}
												value={fullAmount}
											/>
										</div>
									</div>
								</div>
								<div className="spc--bottom--sml">
									<label className="type--wgt--medium">Tip</label>
									<div className="inputgroup">
										<div className="inputgroup--aside inputgroup--aside--bordered inputgroup--aside--iconed">
											<span>{this.currencyCode}</span>
										</div>
										<div className="inputgroup--main inputgroup--main--bordered">
											<NumberFormat
												className="input input--med inputgroup__input"
												getInputRef={el => {
													this.tipRef = el;
												}}
												placeholder="0"
												thousandSeparator=","
												inputMode="numeric"
												decimalSeparator="."
												allowNegative={false}
												decimalScale={2}
												onValueChange={this.handleTipChange}
												value={tip}
											/>
										</div>
									</div>
								</div>
							</Fragment>
						)}
						<div className="spc--bottom--sml">
							<label className="type--wgt--medium">Description</label>
							<input className="input input--med" name="description" value={description} onChange={this.handleChange} />
						</div>
						<div className="spc--bottom--sml">
							<label className="type--wgt--medium">{this.getCustomDisplayLabel('orderId', 'Order ID')}</label>
							<input
								className="input input--med"
								inputMode="numeric"
								name="orderId"
								value={orderId}
								onChange={this.handleChange}
							/>
						</div>
						<div className="spc--bottom--sml">
							<label className="type--wgt--medium">{this.getCustomDisplayLabel('custom1', 'Custom01')}</label>
							<input className="input input--med" name="custom1" value={custom1} onChange={this.handleChange} />
						</div>
						<div className="spc--bottom--sml">
							<label className="type--wgt--medium">{this.getCustomDisplayLabel('custom2', 'Custom02')}</label>
							<input className="input input--med" name="custom2" value={custom2} onChange={this.handleChange} />
						</div>
						<div>
							<label className="type--wgt--medium">{this.getCustomDisplayLabel('custom3', 'Custom03')}</label>
							<input className="input input--med" name="custom3" value={custom3} onChange={this.handleChange} />
						</div>
					</Fragment>
				)}
			</form>
		);
	};

	renderHeader = () => {
		return (
			<div className="modal__header">
				<div className="modal__header__title">Adjust</div>
			</div>
		);
	};

	renderFooter = () => {
		return (
			<div className="modal__footer">
				<button
					disabled={this.state.isLoading}
					type="button"
					className="btn btn--med btn--primary"
					onClick={this.adjustTransaction}
				>
					Adjust
				</button>
			</div>
		);
	};

	render() {
		return (
			<Fragment>
				{this.renderHeader()}
				{this.renderBody()}
				{this.renderFooter()}
			</Fragment>
		);
	}
}

AdjustComponent.propTypes = {
	refNum: PropTypes.string.isRequired,
	xRefnumCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string.isRequired,
	amount: PropTypes.number,
	tip: PropTypes.number,
	description: PropTypes.string,
	orderId: PropTypes.string,
	custom1: PropTypes.string,
	custom2: PropTypes.string,
	custom3: PropTypes.string,
	currency: PropTypes.string,
	refreshGridData: PropTypes.func,
	closeModal: PropTypes.func,
	notificationRef: PropTypes.object.isRequired,
	makePendingRequest: func.isRequired,
	xMerchantId: any,
	customDisplayLabels: PropTypes.object,
	setLoading: PropTypes.func,
};

export default withCancelable(AdjustComponent);
