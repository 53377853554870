import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const FilterAlert = ({ targetRef }) => {
	const [style, setStyle] = useState({});

	useEffect(() => {
		if (targetRef.current) {
			const rect = targetRef.current.nextSibling
				? targetRef.current.nextSibling.getBoundingClientRect()
				: targetRef.current.getBoundingClientRect();
			setStyle({
				position: 'absolute',
				top: `${rect.top + window.scrollY + 10}px`,
				left: `${rect.left + window.scrollX - 65}px`,
				zIndex: 1000,
			});
		}
	}, [targetRef]);

	return createPortal(
		<div
			style={style}
			className="flex--no-shrink datatooltip--down"
			data-tooltip="Alert: Results may not contain all transactions. Click All to retrieve all transactions."
		>
			<i className="icon icon--tny icon--regular--info--error"></i>
		</div>,
		document.body
	);
};

FilterAlert.propTypes = {
	targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

export default FilterAlert;
