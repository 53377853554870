import { OutsideClick } from 'common/utilities';
import { func } from 'prop-types';
import React from 'react';

const Requirements = ({ handleClose }) => {
	return (
		<div className="modal__overlay">
			<OutsideClick action={handleClose} className="modal__content">
				<button
					tabIndex="-1"
					className="btn btn--action btn--action--secondary modal__close--header"
					onClick={handleClose}
				>
					<i className="icon icon--sml icon--close--text"></i>
				</button>
				<div className="modal__content__wrapper">
					<div className="modal__header">
						<h4>Instructions</h4>
					</div>
					<div className="modal__body">
						<div className="spc--bottom--xlrg">
							<h5 className="spc--bottom--sml">General Info</h5>
							<ul className="list--disc spc--bottom--lrg">
								<li>Supported file format: CSV.</li>
								<li>For each customer either Billing First Name, Billing Last Name or Company Name is required.</li>
							</ul>
						</div>
						<div className="spc--bottom--xlrg">
							<h5 className="spc--bottom--sml">Payment Info</h5>
							<ul className="list--disc spc--bottom--lrg">
								<li>
									Adding a customer without a payment method will generate a customer record without a stored payment
									method.
								</li>
								<li>When uploading Cardknox tokens, expiration is not required.</li>
								<li>When uploading card numbers expiration is required. The supported format is MMYY.</li>
								<li>
									When uploading Check/ACH an Account Name, 9 digit Routing Number and 4 - 17 digit Account number is
									required.
								</li>
								<li>Payment Type is required and supports either "CC" or "Check".</li>
							</ul>
						</div>
						<h5 className="spc--bottom--sml">Schedule Info</h5>
						<ul className="list--disc spc--bottom--lrg">
							<li>
								A payment method. When uploading multiple payment methods the first payment method will be the default.
							</li>
							<li>Every supports an interval of “day,” “week,” “month,” or “year”.</li>
							<li>
								Frequency supports a number indicating the frequency at which the schedule will run. For example: to run
								a schedule every month, input "month" in Every and "1" in Frequency.
							</li>
							<li>Start Date formatted as MM/DD/YYYY.</li>
							<li>
								Until supports a number of payments, an end date formatted as MM/DD/YYYY or blank to run indefinitely.
							</li>
						</ul>
					</div>
				</div>
			</OutsideClick>
		</div>
	);
};

Requirements.propTypes = {
	handleClose: func.isRequired,
};

export default Requirements;
