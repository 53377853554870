import React, { Component } from 'react';
import PropTypes, { bool } from 'prop-types';
import NumberFormat from 'react-number-format';
import { endsWith, split, toLower } from 'lodash';

import { transactionService } from '../../services';
import { CurrencyMap, invokeIfFunction } from '../../../Common/utilities';
import { Spinner, withLoader } from '../loader';
import { withCancelable } from '../cancelable';
import { UNEXPECTED_ERROR_MSG } from '../../../components/error';

const requestKeys = {
	TRANSACTION: 'transaction',
	ADJUST: 'adjust',
};

class CaptureComponent extends Component {
	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.props.currency);
	}

	get isPartialAuth() {
		const {
			row: { xAmount },
		} = this.props;
		const { fullAmount } = this.state;
		return fullAmount < xAmount;
	}

	constructor(props) {
		super(props);

		const {
			transactionStatus: { isSplitCaptured, canSplitCapture },
		} = props;

		const command = isSplitCaptured && canSplitCapture ? 'cc:SplitCapture' : 'cc:Capture';
		const tip = props.row.xTip || 0;
		const amountValue = props.row.xAmount || 0;
		const amount = command === 'cc:SplitCapture' ? 0 : amountValue - tip;

		this.state = {
			amount,
			fullAmount: command === 'cc:SplitCapture' ? 0 : amountValue,
			tip,
			note: '',
			showAdvanced: !!tip,
			command,
		};
	}

	blurFullAmountInput = () => {
		if (this.fullAmountRef && !endsWith(this.state.fullAmount, '.')) {
			this.fullAmountRef.blur();
			this.fullAmountRef.focus();
		}
	};

	blurTipInput = () => {
		if (this.tipRef && !endsWith(this.state.tip, '.')) {
			this.tipRef.blur();
			this.tipRef.focus();
		}
	};

	blurInvoiceInput = () => {
		if (this.invoiceRef && !endsWith(this.state.invoice, '.')) {
			this.invoiceRef.blur();
			this.invoiceRef.focus();
		}
	};

	handleChange = e => {
		const { name, value, checked, type } = e.target;
		const val = type === 'checkbox' ? checked : value;
		this.setState({
			[name]: val,
			amount: 0,
			fullAmount: '',
		});
	};

	handleTipChange = ({ floatValue = 0, value }) => {
		const { amount = 0 } = this.state;
		const fullAmount = amount + floatValue;
		this.setState(
			{
				tip: value,
				fullAmount,
			},
			this.blurTipInput
		);
	};
	handleInvoiceChange = ({ value }) => {
		this.setState(
			{
				invoice: value,
			},
			this.blurInvoiceInput
		);
	};

	handleFullAmountChange = ({ floatValue = 0, value }) => {
		const { tip = '0', showAdvanced } = this.state;
		const floatTip = parseFloat(tip);
		if (showAdvanced && floatValue < floatTip) {
			floatValue = floatTip;
			value = tip;
		}
		let amount = floatValue;
		if (showAdvanced && floatTip) {
			amount -= floatTip;
		}
		this.setState(
			{
				amount,
				fullAmount: value,
			},
			this.blurFullAmountInput
		);
	};

	showLoader = isLoading => {
		this.setState({ isLoading });
		invokeIfFunction(this.props.setLoading, isLoading);
	};

	showHideTip = () => {
		let { amount, showAdvanced } = this.state;
		const fullAmount = amount;
		this.setState({
			showAdvanced: !showAdvanced,
			fullAmount: fullAmount,
			tip: 0,
			invoice: '',
		});
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	closeModal = () => {
		this.props.closeModal();
	};

	captureTransaction = async () => {
		let captureRefNum = null;
		const {
			row: {
				xCustomerID,
				xCustID,
				xCommand,
				xRefNum,
				xCurrency,
				xCustom01,
				xCustom02,
				xCustom03,
				xDescription,
				xOrderID,
				xMerchantId,
			},
			autoPartialAuthReversal,
			isLoading,
		} = this.props;
		const { fullAmount, tip, note, command, invoice } = this.state;
		const [paymentType, transactionType] = split(toLower(xCommand), ':');
		try {
			if (isLoading) {
				return;
			}
			this.showLoader(true);
			if (this.isPartialAuth && autoPartialAuthReversal && split(toLower(command), ':')[1] !== 'splitcapture') {
				await this.props.makePendingRequest(
					transactionService.transactionAdjust(
						xRefNum,
						null,
						paymentType,
						transactionType,
						fullAmount,
						tip,
						xDescription,
						xOrderID,
						xCustomerID || xCustID || xCustom01,
						xCustom02,
						xCustom03,
						autoPartialAuthReversal,
						xMerchantId,
						invoice
					),
					requestKeys.ADJUST
				);
			}
			const { xRefNum: captureTransactionRefNum } = await this.props.makePendingRequest(
				transactionService.transactionCapture(command, xRefNum, fullAmount, tip, note, xCurrency, xMerchantId, invoice),
				requestKeys.TRANSACTION
			);
			captureRefNum = captureTransactionRefNum;
		} catch (e) {
			if (
				e &&
				toLower(e.message) === 'partial void not supported for amex (e22)' &&
				this.isPartialAuth &&
				autoPartialAuthReversal
			) {
				try {
					const { xRefNum: captureTransactionRefNum } = await this.props.makePendingRequest(
						transactionService.transactionCapture(
							command,
							xRefNum,
							fullAmount,
							tip,
							note,
							xCurrency,
							xMerchantId,
							invoice
						),
						requestKeys.TRANSACTION
					);
					captureRefNum = captureTransactionRefNum;
				} catch (e) {
					this.showLoader(false);
					return this.handleError(e);
				}
			} else if (!e || !e.isCanceled) {
				this.showLoader(false);
				return this.handleError(e);
			}
		}
		this.showLoader(false);
		this.props.notificationRef.addNotification({
			ref: captureRefNum,
			showViewTransaction: true,
			message: 'Transaction captured',
			success: true,
			onClose: this.refreshGridData,
		});
		this.closeModal();
	};

	handleError = e => {
		this.showLoader(false);
		this.props.notificationRef.addNotification({
			ref: e && e.ref,
			message: (e && e.message) || UNEXPECTED_ERROR_MSG,
			success: false,
			onClose: this.refreshGridData,
		});
		this.closeModal();
	};

	renderAdvancedBody = () => {
		const { tip, invoice } = this.state;
		return (
			<React.Fragment>
				<div className="form__group">
					<div className="form__group__header">
						<p className="form__group__label">Tip</p>
					</div>
					<div className="input--prefix">
						<span>{this.currencyCode}</span>
						<NumberFormat
							className="input--prefix__main"
							getInputRef={el => {
								this.tipRef = el;
							}}
							placeholder="0"
							thousandSeparator=","
							decimalSeparator="."
							allowNegative={false}
							decimalScale={2}
							onValueChange={this.handleTipChange}
							value={tip}
						/>
					</div>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<p className="form__group__label">Invoice</p>
					</div>
					<NumberFormat
						className="input input--med"
						getInputRef={el => {
							this.invoiceRef = el;
						}}
						placeholder="Enter Invoice number here"
						allowNegative={false}
						onValueChange={this.handleInvoiceChange}
						value={invoice}
					/>
				</div>
			</React.Fragment>
		);
	};

	renderBody = () => {
		const { fullAmount, showAdvanced, tip, command, isLoading } = this.state;
		const {
			transactionStatus: { isSplitCaptured, splitCapturedAmount, canSplitCapture },
			splitCaptureEnabled,
		} = this.props;
		if (isLoading) {
			return <Spinner />;
		}
		return (
			<div className="modal__body">
				<form>
					{isSplitCaptured && (
						<p className="type--p1 spc--bottom--lrg">
							Split Captured Amount: {this.currencyCode}
							{splitCapturedAmount}
						</p>
					)}
					{!isSplitCaptured && canSplitCapture && splitCaptureEnabled && (
						<div className="flex--primary flex--gap--lrg spc--bottom--med">
							<div>
								<input
									type="radio"
									className="input--radio"
									id="capture"
									name="command"
									value="cc:Capture"
									checked={command === 'cc:Capture'}
									onChange={this.handleChange}
								/>
								<label htmlFor="capture">Capture</label>
							</div>
							<div>
								<input
									type="radio"
									className="input input--radio"
									id="splitCapture"
									name="command"
									value="cc:SplitCapture"
									checked={command === 'cc:SplitCapture'}
									onChange={this.handleChange}
								/>
								<label htmlFor="splitCapture">Split Capture</label>
							</div>
						</div>
					)}

					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">
								Amount <span className="spc--left--tny">{showAdvanced && tip ? '(including tip)' : null}</span>
							</p>
						</div>
						<div className="input--prefix">
							<span className="type--color--text--light">{this.currencyCode}</span>
							<NumberFormat
								className={`input--prefix__main${fullAmount < 0.01 ? ' is-invalid' : ''}`}
								getInputRef={el => {
									this.fullAmountRef = el;
								}}
								placeholder="0"
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								decimalScale={2}
								onValueChange={this.handleFullAmountChange}
								value={fullAmount}
								autoFocus={true}
							/>
						</div>
					</div>
					{showAdvanced ? <React.Fragment>{this.renderAdvancedBody()}</React.Fragment> : null}
				</form>
			</div>
		);
	};

	onCancel = () => {
		this.setState({
			amount: this.props.amount,
			tip: '',
			note: '',
			showAdvanced: false,
		});
		this.closeModal();
	};

	renderHeader = () => {
		return (
			<div className="modal__header">
				<div className="modal__header__title">Capture</div>
			</div>
		);
	};

	renderFooter = () => {
		const {
			transactionStatus: { isSplitCaptured },
		} = this.props;
		return (
			<div className="modal__footer">
				<button
					type="button"
					onClick={this.showHideTip}
					className="btn btn--med btn btn--link btn--link--underline align--h--left"
				>
					Show {this.state.showAdvanced ? 'less' : 'more'}
				</button>
				<button
					disabled={this.state.isLoading || this.state.fullAmount < 0.01}
					type="button"
					className="btn btn--med btn--primary"
					onClick={this.captureTransaction}
				>
					{isSplitCaptured && 'Split'} Capture
				</button>
			</div>
		);
	};

	render() {
		return (
			<React.Fragment>
				{this.renderHeader()}
				{this.renderBody()}
				{this.renderFooter()}
			</React.Fragment>
		);
	}
}

CaptureComponent.defaultProps = {
	transactionStatus: {
		isSplitCaptured: false,
		splitCapturedAmount: 0,
	},
};

CaptureComponent.propTypes = {
	autoPartialAuthReversal: PropTypes.bool.isRequired,
	row: PropTypes.object.isRequired,
	refreshGridData: PropTypes.func,
	closeModal: PropTypes.func,
	notificationRef: PropTypes.object.isRequired,
	amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	currency: PropTypes.string,
	makePendingRequest: PropTypes.func,
	transactionStatus: PropTypes.object,
	splitCaptureEnabled: bool,
	setLoading: PropTypes.func,
};

export default withLoader(withCancelable(CaptureComponent));
