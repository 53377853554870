import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { includes, toLower, toString } from 'lodash';
import { FilterAlert } from '../components';

const ExcelColumn = {
	name: PropTypes.node.isRequired,
	key: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	filterable: PropTypes.bool,
};

class StringFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.stringFilterRef = React.createRef();
		this.state = {
			filterTerm: '',
			displayAlert: false,
		};
	}

	filterValues = (row, columnFilter, columnKey) => {
		if (columnFilter.filterTerm == null) {
			return true;
		}
		this.setState({ hasData: columnFilter && columnFilter.hasMoreData });
		let result = false;

		let value = row[columnKey];
		for (let ruleKey in columnFilter.filterTerm) {
			if (!columnFilter.filterTerm.hasOwnProperty(ruleKey)) {
				continue;
			}

			let rule = columnFilter.filterTerm;

			if (includes(toString(toLower(value)), toString(toLower(rule)))) {
				result = true;
			}
		}
		return result;
	};

	clearFilters = () => {
		const e = {
			target: {
				value: undefined,
			},
		};
		this.handleChange(e);

		this.setState({
			filterTerm: '',
		});
	};

	handleChange = e => {
		let val = e.target.value;
		this.setState({ filterTerm: val, displayAlert: val ? true : false });
		this.props.onChange({ filterTerm: val, column: this.props.column, filterValues: this.filterValues });
	};

	handleFocusBlur = (e, isFocus) => {
		if (isFocus) {
			this.setState({ isFocus });
		} else {
			this.setState({ displayAlert: false, hasData: false, isFocus: false });
		}
	};

	renderInput = () => {
		const {
			column: { filterable, key },
		} = this.props;
		const { filterTerm } = this.state;

		if (filterable === false) {
			return <span />;
		}
		let inputKey = 'header-filter-' + key;
		return (
			<Fragment>
				<input
					id={inputKey}
					key={inputKey}
					name={inputKey}
					type="text"
					placeholder=""
					value={filterTerm}
					onChange={this.handleChange}
					onFocus={e => this.handleFocusBlur(e, true)}
					onBlur={() => this.handleFocusBlur()}
				/>
				{filterTerm && (
					<button className="btn btn--link clear-button" onClick={this.clearFilters}>
						<i className="icon icon--tny icon--close"></i>
					</button>
				)}
			</Fragment>
		);
	};

	renderDisplayAlert = () => {
		const { displayAlert, filterTerm, isFocus, hasData } = this.state;
		const display = displayAlert && filterTerm && isFocus && hasData;

		if (!display) {
			return null;
		}

		return <FilterAlert targetRef={this.stringFilterRef} />;
	};

	render() {
		return (
			<div className="react-grid-HeaderCell-filter" ref={this.stringFilterRef}>
				{this.renderInput()}
				{this.renderDisplayAlert()}
			</div>
		);
	}
}

StringFilterComponent.propTypes = {
	onChange: PropTypes.func.isRequired,
	column: PropTypes.shape(ExcelColumn),
};

export default StringFilterComponent;
