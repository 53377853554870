import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, each, find, get } from 'lodash';
import { Data } from 'react-data-grid-addons';
import moment from 'moment';

import { GridComponent } from 'common/components/grid';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { portalManagementFilter, compileFilter } from 'common/components/portal-management/filter';
import { portalManagementColumnsAll } from 'common/components/portal-management/column-filter/portalManagementColumnsAll';
import { ZebraRenderer } from 'common/components/row';
import { portalManagementService } from 'common/services';
import { PopoverGridTooltip } from 'common/components/tooltips';
import { exportService } from 'common/components/export/exportService';
import { withLoader } from 'common/components/loader';
import { Notification } from 'common/components/notifications';
import { ActionsModal } from 'common/components/transaction-actions';

const requestKeys = {
	FETCH: 'fetch',
};

class PortalManagementAll extends Component {
	state = {
		filters: cloneDeep(portalManagementFilter),
		activeFilters: cloneDeep(portalManagementFilter),
		columns: cloneDeep(portalManagementColumnsAll),
		defaultColumns: cloneDeep(portalManagementColumnsAll),
		inlineFilters: {},
		data: null,
		filteredRows: [],
		fetchData: false,
		fetchingAdditionalData: false,
		lastApiRefNum: null,
	};
	components = {
		rowRenderer: ZebraRenderer,
		tooltip: PopoverGridTooltip,
		modal: ActionsModal,
	};
	classes = {
		filterContainer: 'filter__header',
	};
	gridRef = createRef();
	notificationRef = createRef();

	getSnapshotBeforeUpdate = prevProps => prevProps.location.key !== this.props.location.key;

	componentDidUpdate = (_, __, snapshot) => {
		if (snapshot) {
			this.setState(this.initialState, () => {
				if (this.gridRef.current) {
					this.gridRef.current.clearFilters();
					this.gridRef.current.reset();
				}
			});
		}
	};

	fetchData = async filters => {
		const { handleError, makePendingRequest } = this.props;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});

		try {
			const filter = await makePendingRequest(compileFilter(filters), requestKeys.FETCH);
			const { refNum, users } = await makePendingRequest(
				portalManagementService.listAllUsers(filter),
				requestKeys.FETCH
			);

			const data = this.mapData(users);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			this.setState(
				{
					data,
					filteredRows,
					fetchingData: false,
					lastApiRefNum: refNum,
				},
				() => {
					const grid = this.gridRef.current;
					if (grid) {
						grid.handleInitialSort();
						grid.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	mapData = users => {
		const data = { xReportData: [] };

		each(users, ({ status, userCreateDate, attributes }, i) => {
			const email = find(attributes, ({ name }) => name === 'email');

			data.xReportData.push({
				username: get(email, 'value', ''),
				userCreateDate: moment(userCreateDate).format(ApplicationSettings.displayDateTimeFormat),
				status,
				openActions: this.openActions,
				gridRowNumber: i,
				index: i + 1,
				showLoader: this.props.showLoader,
				refreshGridData: this.gridRef && this.gridRef.current && this.gridRef.current.refreshGridData,
				notificationRef: this.notificationRef && this.notificationRef.current,
				openCloseModal: get(this.gridRef, 'current.openCloseModal'),
				onDelete: this.refetchData,
			});
		});

		return data;
	};

	openActions = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	refetchData = () => {
		const { activeFilters } = this.state;
		this.fetchData(activeFilters);
	};

	resolveColumnName = column => {
		switch (column) {
			case 'lastLoginDate':
				return 'lastLoginDateMoment';
			default:
				return column;
		}
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			defaultColumns,
			data,
			inlineFilters,
			filters,
			activeFilters,
			lastApiRefNum,
			tooltipProps,
		} = this.state;
		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<GridComponent
					emptyMessage="You should change your filter options"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					columns={columns}
					defaultColumns={defaultColumns}
					data={data}
					inlineFilters={inlineFilters}
					components={this.components}
					onChange={this.handleChange}
					title="Portal Management - All Users"
					filters={filters}
					printTitle="Portal Management Users"
					fetchExportData={{ current: exportService.mapPortalManagementUsersData }}
					type="portalManagement"
					activeFilters={activeFilters}
					fetchData={this.refetchData}
					lastApiRefNum={lastApiRefNum}
					ref={this.gridRef}
					initialFetch={true}
					useInlineFilters={true}
					showResults={true}
					resolveColumnName={this.resolveColumnName}
					fetchOnReset={false}
					classes={this.classes}
					tooltipProps={tooltipProps}
					showHeader={true}
					enablePrint={true}
					enableExport={true}
				/>
			</Fragment>
		);
	}
}

PortalManagementAll.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	location: PropTypes.object,
	showLoader: PropTypes.func,
};

export default withError(withLoader(withCancelable(PortalManagementAll)));
