import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { transactionService } from '../../services';
import { Spinner, withLoader } from '../loader';
import { UNEXPECTED_ERROR_MSG } from '../../../components/error';
import { includes, toLower } from 'lodash';
import { invokeIfFunction } from 'common/utilities';

class TransactionVoidComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	get transactionType() {
		const { type } = this.props;

		if (type.toLowerCase().indexOf('cc:') !== -1 || type.toLowerCase().indexOf('gift:') !== -1) {
			return 'cc';
		} else if (type.toLowerCase().indexOf('check:') !== -1) {
			return 'check';
		} else if (type.toLowerCase().indexOf('grant:') !== -1) {
			return 'grant';
		} else if (includes(toLower(type), 'ebtonline')) {
			return 'ebtonline';
		} else {
			throw new Error('Unknown type');
		}
	}

	showLoader = isLoading => {
		this.setState({ isLoading });
		invokeIfFunction(this.props.setLoading, isLoading);
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	closeModal = () => {
		this.props.closeModal();
	};

	voidTransaction = () => {
		const { refNum, xMerchantId, notificationRef } = this.props;

		this.showLoader(true);
		transactionService
			.transactionVoid(refNum, this.transactionType, xMerchantId)
			.then(rsp => {
				notificationRef.addNotification({
					ref: rsp.xRefNum,
					showViewTransaction: true,
					message: 'Transaction voided',
					success: true,
					onClose: this.refreshGridData,
				});
				this.showLoader(false);
				this.closeModal();
			})
			.catch(e => {
				if (!e || !e.isCanceled) {
					notificationRef.addNotification({
						ref: e && e.ref,
						message: (e && e.message) || UNEXPECTED_ERROR_MSG,
						success: false,
						onClose: this.refreshGridData,
					});
					this.showLoader(false);
					this.closeModal();
				}
			});
	};

	renderVoidBody = () => {
		return <div className="type--med">Are you sure you want to void the transaction?</div>;
	};

	renderVoidFooter = () => {
		return (
			<div className="modal__footer">
				<button type="button" className="btn btn--med btn--primary" onClick={this.voidTransaction}>
					Void transaction
				</button>
			</div>
		);
	};

	render() {
		if (this.state.isLoading) {
			return <Spinner />;
		}
		return (
			<React.Fragment>
				<div className="modal__header"></div>
				<div className="modal__body">{this.renderVoidBody()}</div>
				{this.renderVoidFooter()}
			</React.Fragment>
		);
	}
}

TransactionVoidComponent.propTypes = {
	iconButton: PropTypes.bool,
	iconStyle: PropTypes.string,
	refNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	type: PropTypes.string.isRequired,
	refreshGridData: PropTypes.func,
	closeModal: PropTypes.func,
	notificationRef: PropTypes.any.isRequired,
	xMerchantId: PropTypes.any,
	setLoading: PropTypes.func,
};

export default withLoader(TransactionVoidComponent);
