import React, { createRef } from 'react';
import { string, func } from 'prop-types';
import ReactQuill, { Quill } from 'react-quill'; // Import Quill separately
import 'react-quill/dist/quill.snow.css'; // Ensure this CSS is imported
import { get, uniqueId } from 'lodash';
import { OutsideClick } from 'common/utilities';

let Font = Quill.import('attributors/class/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia'];
Quill.register(Font, true);
class ToolbarEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value || '',
			quillClassName: 'quill',
			toolbarId: uniqueId('toolbar'),
		};
		this.reactQuillRef = createRef();
	}

	get isValid() {
		return get(this.props, 'isValid', true);
	}
	static getDerivedStateFromProps(props, state) {
		return {
			...state,
			modules: {
				toolbar: {
					container: `#${state.toolbarId}`,
				},
			},
		};
	}

	static formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'color',
	];

	CustomToolbar = () => (
		<div id={this.state.toolbarId} className="react-quill">
			<span className="ql-formats">
				<select className="ql-header">
					<option value="1">Heading 1</option>
					<option value="2">Heading 2</option>
					<option value="3">Heading 3</option>
					<option value="4">Heading 4</option>
					<option value="5">Heading 5</option>
					<option value="6">Heading 6</option>
					<option value="">Normal</option>
				</select>
				<select className="ql-font">
					<option value="arial">Arial</option>
					<option value="comic-sans">Comic Sans</option>
					<option value="courier-new">Courier New</option>
					<option value="georgia">Georgia</option>
				</select>
			</span>
			<span className="ql-formats">
				<button className="ql-bold"></button>
				<button className="ql-italic"></button>
				<button className="ql-underline"></button>
				<button className="ql-strike"></button>
				<button className="ql-blockquote"></button>
				<button className="ql-list" value="ordered"></button>
				<button className="ql-list" value="bullet"></button>
				<button className="ql-indent" value="-1"></button>
				<button className="ql-indent" value="+1"></button>
			</span>
			<span className="ql-formats">
				<button className="ql-link"></button>
				<select className="ql-color"></select>
				<button className="ql-clean"></button>
			</span>
		</div>
	);

	onChange = value => {
		this.setState({ value }, () =>
			this.props.onChange({
				target: { name: this.props.name, type: 'textarea', value: value },
			})
		);
	};

	getClassName = () => {
		let className = 'text-editor';
		if (!this.isValid) {
			return 'text-editor is-invalid';
		}
		return className;
	};

	setClassName = focused => {
		const quillClassName = focused ? 'is-focused' : '';
		this.setState({
			quillClassName,
		});
	};

	handleOutsideClick = () => {
		const { quillClassName } = this.state;
		if (quillClassName === 'is-focused') {
			this.setClassName(false);
		}
	};
	render() {
		const { value, quillClassName } = this.state;
		return (
			<OutsideClick action={this.handleOutsideClick}>
				<div className={this.getClassName()} onClick={() => this.setClassName(true)} id="quillContainer">
					<ReactQuill
						bounds={'#quillContainer'}
						className={quillClassName}
						theme="snow"
						value={value || ''}
						onChange={this.onChange}
						modules={this.state.modules} // Use state property
						formats={ToolbarEditor.formats}
						ref={el => {
							if (el != null) {
								this.reactQuillRef.current = el.getEditor();
							}
						}}
					/>
					<this.CustomToolbar />
				</div>
			</OutsideClick>
		);
	}
}

ToolbarEditor.propTypes = {
	value: string,
	name: string,
	onChange: func,
};

export default ToolbarEditor;
