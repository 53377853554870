import { cloneDeep, endsWith, get, replace } from 'lodash';
import moment from 'moment';

import { getDomainName, kvaasService } from 'common/services';
import { kvaasResources } from './kvaas-resources';
import { getPortalEndpoints } from 'common/utils/endpoint-utils';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

export const mapStateToRequest = (data, oldData) => {
	return {
		newData: {
			revision: 0,
			data,
		},
		oldData,
		...kvaasResources.userSettings,
	};
};

export function toggleShareFeedback(value) {
	localStorage.removeItem('hasPaymentSites');
	localStorage.removeItem('isProPay');

	if (!value) {
		return this.optInForNewPortal();
	} else {
		this.setState({ isShareFeedbackOpen: value });
	}
}

export async function optInForNewPortal() {
	const { updatedNewPortalEndpoint, updatedOldPortalEndpoint } = getPortalEndpoints();
	try {
		const { userSettings } = this.state;
		const redirectToNewPortal = get(userSettings, 'data.redirectToNewPortal');
		const shouldRedirectToNewPortal = !endsWith(window.location.host, updatedNewPortalEndpoint);
		const newUserSettings = cloneDeep(userSettings || {});
		if (!newUserSettings.data) {
			newUserSettings.data = {};
		}
		newUserSettings.data.redirectToNewPortal = shouldRedirectToNewPortal;
		const mappedState = mapStateToRequest(newUserSettings.data, userSettings);
		if (shouldRedirectToNewPortal !== redirectToNewPortal) {
			await this.props.makePendingRequest(kvaasService.save(mappedState), requestKeys.SAVE);
		}
		document.cookie = `${AppBuildEnvironment}RedirectToNewPortal=true;expires=${moment
			.utc()
			.add(1, 'minute')
			.format('ddd, DD MMM YYYY HH:mm:ss')};domain=${AppBuildEnvironment !== 'local' ? getDomainName() : LocalHost}`;
		const currentPortal = shouldRedirectToNewPortal ? updatedOldPortalEndpoint : updatedNewPortalEndpoint;
		const destinationPortal = shouldRedirectToNewPortal ? updatedNewPortalEndpoint : updatedOldPortalEndpoint;
		window.location.href = replace(window.location.href, currentPortal, destinationPortal);
	} catch (e) {
		this.props.handleError(e);
	}
}
