import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { map, get, find, camelCase, isEmpty, isEqual } from 'lodash';

import { CurrencyMap } from '../../Common/utilities';
import BatchPieChart from './batch-chart';
import BatchDataGrid from './batch-data-grid';
import BatchCard from './BatchCard';
import { SidebarContext } from 'common/contexts';

class BatchCards extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
			summaryToggled: false,
		};

		this.gridRef = createRef();
	}
	static contextType = SidebarContext;
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.data, this.props.data) && !isEmpty(this.props.data)) {
			this.handleInitialSort();
		}
	}

	componentWillUnmount() {
		if (this.state.summaryToggled) {
			this.context.toggleSidebarExpandedClass(false);
		}
	}

	get renderSummmaryToggled() {
		return this.state.summaryToggled && !isEmpty(this.props.data);
	}

	handleInitialSort = () => {
		const { onGridSort } = this.props;
		const columns = get(this.gridRef, 'current.columns');
		let selectedColumn = find(columns, column => column.sortDirection);
		if (!selectedColumn) {
			selectedColumn = find(columns, column => column.isDefaultSorter);
		}
		if (columns && selectedColumn) {
			if (selectedColumn.isDefaultSorter) {
				onGridSort(selectedColumn.key, selectedColumn.sortDirection || selectedColumn.defaultSortDirection);
			} else {
				onGridSort(selectedColumn.key, selectedColumn.sortDirection || 'ASC');
			}
		}
	};
	toggleSummary = () => {
		this.context.toggleSidebarExpandedClass(!this.state.summaryToggled);
		this.setState({ summaryToggled: !this.state.summaryToggled });
	};
	renderBatchCard = type => {
		const { data } = this.props;
		if (!data) return null;
		return (
			<BatchCard
				key={type}
				title={type}
				amount={data.totals[`${camelCase(type.replace(/ /g, ''))}Amount`]}
				count={data.totals[`${camelCase(type.replace(/ /g, ''))}Count`]}
				currency={this.state.currency}
			/>
		);
	};

	render() {
		const {
			data,
			selectedDateText,
			handleBreakdownOpen,
			transactionTypes,
			showVoid,
			showProcessingFee,
			isFundedReport,
			isFundedReportOpenBatches,
			activeTab,
			exportButtons,
			onGridSort,
			updateExpandedRows,
			filters,
			activeFilters,
			onFilterUpdate,
			switchTab,
			isLoading,
		} = this.props;

		let batches = [];
		if (data && data.xAdditionalData) {
			batches = data.xAdditionalData;
		} else if (data && data.openBatches) {
			batches = data.openBatches;
		}
		return (
			<React.Fragment>
				<BatchDataGrid
					ref={this.gridRef}
					rows={data && data.xReportData}
					onGridSort={onGridSort}
					showVoid={showVoid}
					showProcessingFee={showProcessingFee}
					isFundedReport={isFundedReport}
					isFundedReportOpenBatches={isFundedReportOpenBatches}
					batches={batches}
					handleBreakdownOpen={handleBreakdownOpen}
					activeTab={activeTab}
					exportButtons={exportButtons}
					updateExpandedRows={updateExpandedRows}
					filters={filters}
					activeFilters={activeFilters}
					onFilterUpdate={onFilterUpdate}
					toggleSummary={this.toggleSummary}
					switchTab={switchTab}
					isLoading={isLoading}
				/>
				{this.renderSummmaryToggled && (
					<div className="grid-sidebar">
						<div className="grid-sidebar__header">
							<div className="flex--tertiary spc--bottom--lrg">
								<h5>Batch Summary</h5>
								<button className="btn btn--action btn--action--secondary" onClick={this.toggleSummary}>
									<i className="icon icon--sml icon--close--text"></i>
								</button>
							</div>
							<div className="flex--tertiary spc--bottom--lrg">
								{this.renderBatchCard(transactionTypes[0])}
								<div className="chips chips--static spc--bottom--sml">
									<i className="icon icon--tny icon--calendar"></i>
									{selectedDateText}
								</div>
							</div>
							<div className="flex--primary">{map(transactionTypes.slice(1), this.renderBatchCard)}</div>
						</div>

						<div className="info-panel__heading__wrapper is-expandable">
							<h6 className="info-panel__heading">Sale Summary by Card Type</h6>
						</div>
						<div className="info-panel__section">
							<BatchPieChart data={data} type="sale" amount={data.totals.saleAmount} count={data.totals.saleCount} />
						</div>

						<div className="info-panel__heading__wrapper is-expandable">
							<h6 className="info-panel__heading">Credit Summary by Card Type</h6>
						</div>
						<div className="info-panel__section">
							<BatchPieChart
								data={data}
								type="credit"
								amount={data.totals.creditAmount}
								count={data.totals.creditCount}
							/>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

BatchCards.propTypes = {
	data: PropTypes.object,
	onGridSort: PropTypes.func.isRequired,
	selectedDateText: PropTypes.string,
	handleBreakdownOpen: PropTypes.func,
	transactionTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	showVoid: PropTypes.bool,
	showProcessingFee: PropTypes.bool,
	activeTab: PropTypes.string.isRequired,
	isFundedReport: PropTypes.bool,
	isFundedReportOpenBatches: PropTypes.bool,
	exportButtons: PropTypes.any,
	updateExpandedRows: PropTypes.func,
	filters: PropTypes.array.isRequired,
	activeFilters: PropTypes.array.isRequired,
	onFilterUpdate: PropTypes.func.isRequired,
	switchTab: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default BatchCards;
