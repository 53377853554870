import React, { Component, createRef, Fragment } from 'react';

import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { func, object, bool } from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import {
	replace,
	map,
	startCase,
	split,
	find,
	findIndex,
	each,
	some,
	times,
	padStart,
	head,
	endsWith,
	upperFirst,
	isEmpty,
	maxBy,
	clone,
	cloneDeep,
	first,
	toLower,
	filter,
	isArray,
	get,
	camelCase,
	includes,
	trim,
	startsWith,
	isObject,
	every,
	uniqBy,
	transform,
	has,
	sortBy,
	compact,
	isFunction,
	size,
	toUpper,
	nth,
	orderBy,
	toNumber,
	isNaN,
	isNull,
	defaults,
	uniqueId,
	slice,
	concat,
	flatMap,
	without,
} from 'lodash';
import { checkIfCanadian } from 'common/utilities/check-if-canadian';
import { withLoader } from '../../../../Common/components/loader';
import { withCancelable } from '../../../../Common/components/cancelable';
import { withError } from '../../../../Common/components/error';
import { Notification } from '../../../../Common/components/notifications';
import {
	incrementingId,
	invokeIfFunction,
	CurrencyMap,
	kvaasResources,
	OutsideClick,
} from '../../../../Common/utilities';
import { DraggableSections } from '../../../../Common/components/payment-site';
import { principalService, paymentSiteService, kvaasService } from '../../../../Common/services';
import {
	RoutingNumberTooltip,
	AccountNumberTooltip,
	GooglePayTooltip,
	ApplePayTooltip,
	ClickToPayTooltip,
} from '../../../../Common/components/tooltips';
import { paymentSiteFields } from 'common/utilities';
import NumberFormat from 'react-number-format';
import { Modal } from 'common/components/modal';
import PaymentSiteGeneralSettingsTab from './paymentSiteTabs/PaymentSiteGeneralSettings';
import PaymentSiteInternalSettings from './paymentSiteTabs/PaymentSiteInternalSettings';

import { modalNames, ActionsModal } from 'common/components/transaction-actions';
import GPayBadge from '../../../../img/gpay-badge.png';
import APayBadge from '../../../../img/apay-badge.png';
import C2PayBadge from '../../../../img/c2pay-badge.png';
import { setToCanadianVerbiage } from 'common/utilities/setToCanadianVerbiage';
import { withBlock } from 'common/components/block';
import { ToolbarEditor } from 'common/components/toolbar-editor';
import ColorPicker from 'common/components/color-picker/ColorPicker';
import sectionKeys from 'routing/sections';
import AddNewSection from './components/AddNewSection';
import PortalFieldsDropdown from './components/PortalFieldsDropdown';
import PaymentSiteAvailableFields from './components/PaymentSiteAvailableFields';
import PaymentSiteFieldOptions from './components/PaymentSiteFieldOptions';
import { SidebarContext } from 'common/contexts';
import { createPortal } from 'react-dom';

const { paymentSiteUrl, gpayEnabled, apayEnabled, c2payEnabled } = ApplicationSettings;

const maxSize = 5242880;
const recomendedWidth = 842;
const cardTypes = ['AcceptVisa', 'AcceptAmex', 'AcceptDiscover', 'AcceptMC'];

const required3dSecureFields = [
	'xBillFirstName',
	'xBillLastName',
	'xBillStreet',
	'xBillCity',
	'xBillState',
	'xBillZip',
	'xBillMobile',
	'xEmail',
];
const zipKeys = ['xBillCity', 'xBillState', 'xBillZip'];

const tabs = {
	manageLayout: 'manageLayout',
	generalSettings: 'generalSettings',
	internalSettings: 'internalSettings',
};

const customFieldOptions = [{ label: '-', name: '' }];

const replaceFields = {
	Percent: 'Amount',
	Amount: 'Percent',
};

times(19, idx => {
	const i = idx + 1;
	const label = `Custom${i > 9 ? i : `0${i}`}`;
	customFieldOptions.push({
		label,
		name: `x${label}`,
	});
});

const actionKeys = {
	SAVE: 'SAVE',
	LOAD: 'LOAD',
};

const reenterFields = [
	{
		key: 'ReenterAccountNumber',
		isRequired: true,
	},
	{
		key: 'ReenterRoutingNumber',
		isRequired: true,
	},
];

function createField(key, icon, internalSetting = false, hideDropdownOptionPreview = false, isProPay = false) {
	return {
		key,
		icon,
		internalSetting,
		hideDropdownOptionPreview,
		isProPay,
	};
}

const inlineFields = [
	'CC Percent',
	'ACH Percent',
	'CC Set Amount',
	'ACH Set Amount',
	'Service Fee CC Percent',
	'Service Fee ACH Percent',
	'Service Fee ACH Set Amount',
];

function getFieldClassName(fieldType, fieldName) {
	if (fieldType === 'checkbox') return 'checkbox-tree__indent';
	if (includes(inlineFields, fieldName)) return 'paymentsite__inline-check';
	return 'form__group';
}

class PaymentSiteEdit extends Component {
	currencyCode = CurrencyMap.resolveCurrency();
	isCanadian = checkIfCanadian();
	get merchantCurrencyCode() {
		const { isCanadianCurrency } = this.state;
		return isCanadianCurrency ? 'C$' : this.currencyCode;
	}
	static contextType = SidebarContext;
	constructor(props) {
		super(props);

		const creditCardSection = {
			key: 'creditCard',
			label: 'Credit Card',
			fields: map(
				[
					{
						key: 'xCardNum',
						isRequired: true,
					},
					{
						key: 'xExpDate',
						isRequired: true,
					},
					{
						key: 'xCVV',
					},
				],
				this.mapAvailableFields('creditCard')
			),
			expanded: false,
			toggleExpanded: () => this.toggleExpanded('creditCard'),
		};
		const achSection = {
			key: 'ach',
			label: 'ACH',
			fields: map(
				[
					{
						key: 'xAccountType',
						isRequired: true,
					},
					{
						key: 'xAccountName',
						isRequired: true,
					},
					{
						key: 'xRoutingNumber',
						isRequired: true,
						Tooltip: RoutingNumberTooltip,
					},
					{
						key: 'xAccountNumber',
						isRequired: true,
						Tooltip: AccountNumberTooltip,
					},
					{
						key: 'termsAndConditions',
						isRequired: true,
					},
				],
				this.mapAvailableFields('ach')
			),
			expanded: false,
			toggleExpanded: () => this.toggleExpanded('ach'),
		};
		const principal = principalService.get();
		const achEnabled = this.achEnabled;
		const isCanadianCurrency = principal && principal.idInfo && toLower(principal.idInfo.xMerchantCurrency) === 'cad';
		this.state = {
			sectionActionsRef: null,
			activeSectionIndex: null,
			newSectionActive: false,
			isAdmin: principal.isAdmin,
			isGoPlus: get(principal, 'idInfo.xIsGoPlusAccount', false),
			is3dSecureEnabled: this.getIs3dsSecureEnabled(principal),
			isExpandedOptionsModalView: false,
			errorMessages: [],
			entireEnableDigitalWallet: false,
			entireAcceptACH: false,
			entireBilling: false,
			entireShipping: false,
			entireTransactionDetails: false,
			entireTermsAndConditions: false,
			entireConvenienceFee: false,
			entireAllowedCommands: true,
			entireCardTypesAccepted: false,
			entireReceiptOptions: false,
			entireBackgroundColor: true,
			isExpanded: false,
			selectedTab: tabs.manageLayout,
			showRevert: false,
			undoSteps: [],
			data: null,
			originalData: null,
			isOpenRemoveSectionPopup: false,
			customDisplayLabels: null,
			isNameEdit: false,
			isLogoEdit: false,
			fieldWithOptionsMethods: null,
			isOpenOptionsModal: false,
			optionsKey: '',
			fieldOptionsBeforeSave: [],
			isFieldOptionsDirty: false,
			isCanadianCurrency,
			hasThemeAccess: principal.hasAccess[sectionKeys.users],
			availableFields: [
				{
					key: 'internalOptions',
					label: 'Internal Options',
					internalSection: true,
					actions: [],
					toggleExpanded: () => this.toggleExpandedSection('internalOptions'),
					fields: map(
						[
							createField('customers_schedules', 'recurring-payments', false, false, true),
							createField('recurring_simple', 'recurring-payments', true),
							createField('recurring_split_payment', 'recurring-payments', true),
							createField('recurring_split_payment_8_months', 'recurring-payments', true),
						],
						this.mapAvailableFields('internalOptions')
					),
				},
				{
					key: 'billing',
					label: 'Billing Information',
					actions: [
						{
							label: 'Add Entire Section',
							key: 'entireBilling',
							onChange: () => this.addEntireSection('billing'),
							className: 'spc--bottom--sml--alt',
							disabled: () => !!this.state.entireBilling,
							isAbove: true,
						},
					],
					toggleExpanded: () => this.toggleExpandedSection('billing'),
					fields: [...this.createAddressFields('billing', 'Bill'), ...this.createAvsFields()],
				},
				{
					key: 'shipping',
					label: 'Shipping Information',
					actions: [
						{
							label: 'Add Entire Section',
							key: 'entireShipping',
							onChange: () => this.addEntireSection('shipping'),
							className: 'spc--bottom--sml--alt',
							disabled: () => !!this.state.entireShipping,
							isAbove: true,
						},
					],
					toggleExpanded: () => this.toggleExpandedSection('shipping'),
					fields: this.createAddressFields('shipping', 'Ship'),
				},

				{
					key: 'transactionDetails',
					label: 'Transaction Details',
					actions: [
						{
							label: 'Add Entire Section',
							key: 'entireTransactionDetails',
							onChange: () => this.addEntireSection('transactionDetails'),
							className: 'spc--bottom--sml--alt',
							disabled: () => !!this.state.entireTransactionDetails,
							isAbove: true,
						},
					],
					toggleExpanded: () => this.toggleExpandedSection('transactionDetails'),
					fields: map(
						[
							createField('xAmount', 'amount'),
							createField('xInvoice', 'invoice'),
							createField('xPONum', '12'),
							createField('xDescription', 'description'),
						],
						this.mapAvailableFields('transactionDetails')
					),
				},
				{
					key: 'custom',
					label: 'Custom Fields',
					toggleExpanded: () => this.toggleExpandedSection('custom'),
					fields: map(times(19, this.createCustomField), this.mapAvailableFields('custom')),
					actions: [
						{
							label: 'Add Entire Section',
							key: 'entireCustom',
							onChange: () => this.addEntireSection('custom'),
							className: 'spc--bottom--sml--alt',
							disabled: () => !!this.state.entireCustom,
							isAbove: true,
						},
					],
				},
				{
					key: 'other',
					label: '',
					actions: [],
					toggleExpanded: () => this.toggleExpandedSection('other'),
					fields: map([createField('xCVV', '')], this.mapAvailableFields('other')),
				},
			],
			availableFormSettings: [],
			availableInternalFormSettings: [],

			paymentSections: achEnabled ? [creditCardSection, achSection] : [creditCardSection],
			formSettings: (() => {
				const settings = [
					this.paymentMethods,
					this.allowedCommands,
					this.backgroundColor,
					this.saveCustomerOnTransaction,
					this.termsAndConditions,
					this.convenienceFee,
					this.paymentSiteLogo,
					this.postbackUrl,
					this.postbackUrlOverride,
					this.hideCaptcha,
					this.receiptOptions,
					this.redirectOptions,
					this.serviceFee,
					...this.accountTabs,
				];

				settings.push(this.enableDigitalWallet);
				return settings;
			})(),
			modal: {},
		};
		this.state.availableInternalFormSettings = [
			{
				key: 'PostbackUrl',
				label: 'Enable Postback URL',
				disabled: false,
				internalSetting: true,
				onChange: () => {
					if (this.state.entirePostbackUrl) {
						return this.toggleRemoveSectionPopup(() => this.removeFormSetting('postbackUrl'), 'Postback URL');
					}
					return this.addEntireFormSetting('postbackUrl', true);
				},
			},
			{
				key: 'PostbackUrlOverride',
				label: 'Enable Postback URL Override',
				disabled: false,
				internalSetting: true,
				onChange: () => {
					if (this.state.entirePostbackUrlOverride) {
						return this.toggleRemoveSectionPopup(
							() => this.removeFormSetting('postbackUrlOverride'),
							'Postback URL Override'
						);
					}
					return this.addEntireFormSetting('postbackUrlOverride', true);
				},
			},
			{
				key: 'HideCaptcha',
				label: 'Enable Hide CAPTCHA',
				disabled: false,
				internalSetting: true,
				onChange: () => {
					if (this.state.entireHideCaptcha) {
						return this.toggleRemoveSectionPopup(() => this.removeFormSetting('hideCaptcha'), 'Hide Captcha');
					}
					return this.addEntireFormSetting('hideCaptcha', true);
				},
			},
			{
				key: 'SaveCustomerOnTransaction',
				label: 'Save Customer and Payment Method to Customer Database',
				disabled: false,
				isPrm: true,
				internalSetting: true,
				onChange: () => {
					if (this.state.entireSaveCustomerOnTransaction) {
						return this.toggleRemoveSectionPopup(
							() => this.removeFormSetting('saveCustomerOnTransaction'),
							'Save Customer and Payment Method to Customer Database'
						);
					}
					return this.addEntireFormSetting('saveCustomerOnTransaction', true);
				},
			},
		];
		this.state.availableFormSettings = (() => {
			const fields = [
				{
					key: 'TermsAndConditions',
					label: 'Display Terms And Conditions',
					disabled: false,
					onChange: () => {
						if (this.state.entireTermsAndConditions) {
							return this.toggleRemoveSectionPopup(
								() => this.removeFormSetting('termsAndConditions'),
								'Terms And Conditions'
							);
						}
						return this.addEntireFormSetting('termsAndConditions');
					},
				},
				{
					key: 'CardTypesAccepted',
					label: 'Display Card Types Accepted',
					disabled: () => !this.state.entireAcceptACH,
					onChange: () => {
						if (this.state.entireCardTypesAccepted) {
							return this.toggleRemoveSectionPopup(
								() => this.removeFormSetting('cardTypesAccepted'),
								'Accepted Card Types'
							);
						}
						return this.addEntireFormSetting('cardTypesAccepted');
					},
				},

				{
					key: 'AdditionalAccountTabs',
					label: 'Additional Tabs',
					order: 1,
					disabled: false,
					onChange: () => {
						if (this.state.entireTabA || this.state.entireTabB || this.state.entireTabC) {
							return this.toggleRemoveSectionPopup(() => this.removeAccountTabs(), 'Additional Account Tabs');
						}
						return this.addEntireFormSetting('tab_a', false, true);
					},
				},
				{
					key: 'RedirectOptions',
					label: 'Add Redirect Options',
					order: 2,
					disabled: false,
					onChange: () => {
						if (this.state.entireRedirectOptions) {
							return this.toggleRemoveSectionPopup(() => this.removeFormSetting('redirectOptions'), 'Redirect Options');
						}
						return this.addEntireFormSetting('redirectOptions');
					},
				},
				{
					key: 'ReceiptOptions',
					label: 'Receipt Options',
					disabled: false,
					onChange: () => {
						if (this.state.entireReceiptOptions) {
							return this.toggleRemoveSectionPopup(() => this.removeFormSetting('receiptOptions'), 'Receipt Options');
						}
						return this.addEntireFormSetting('receiptOptions');
					},
				},
				{
					key: 'ConvenienceFee',
					label: 'Display Electronic Transfer Fee',
					order: 4,
					tooltip: this.getDisplayConvenienceTooltip,
					disabled: this.isServiceFeeEnabled,
					onChange: () => {
						if (this.state.entireConvenienceFee) {
							return this.toggleRemoveSectionPopup(
								() => this.removeFormSetting('convenienceFee'),
								'Electronic Transfer Fee'
							);
						}
						return this.addEntireFormSetting('convenienceFee');
					},
				},
				{
					key: 'paymentSiteLogo',
					label: 'Display PaymentSITE logo',
					disabled: false,
					onChange: () => {
						if (this.state.entirePaymentSiteLogo) {
							return this.toggleRemoveSectionPopup(() => this.removeFormSetting('paymentSiteLogo'), 'PaymentSITE logo');
						}
						return this.addEntireFormSetting('paymentSiteLogo');
					},
				},
			];

			if (achEnabled) {
				fields.push({
					key: 'AcceptACH',
					label: 'Display ACH',
					disabled: () => !this.state.entireCardTypesAccepted,
					onChange: () => {
						if (this.state.entireAcceptACH) {
							return this.toggleRemoveSectionPopup(() => this.removeFormSetting('acceptACH'), 'Display ACH');
						}
						return this.addEntireFormSetting('acceptACH');
					},
				});
			}
			// if (this.gpEnabled || this.apEnabled) {
			// || this.state.apEnabled for future use
			fields.push({
				key: 'EnableDigitalWallet',
				label: 'Enable Digital Wallet',
				disabled: this.disableDigitalWallet,
				tooltip: 'Please reach out to Support to Enable Digital Wallet on your account',
				onChange: () => {
					if (this.state.entireEnableDigitalWallet) {
						return this.toggleRemoveSectionPopup(
							() => this.removeFormSetting('enableDigitalWallet'),
							'Enable Digital Wallet'
						);
					}
					return this.addEntireFormSetting('enableDigitalWallet');
				},
			});
			// }
			return orderBy(fields, 'label', 'asc');
		})();
		this.notificationRef = createRef();
		this.inputRef = createRef();
		this.inlineInputRef = createRef();
		this.sectionRef = null;
	}
	disableDigitalWallet = () => {
		return !this.gpEnabled && !this.apEnabled && !this.c2pEnabled;
	};

	get gpEnabled() {
		const principal = principalService.get();
		return gpayEnabled === '1' && principal && principal.idInfo && principal.idInfo.xGooglePayEnabled;
	}

	get apEnabled() {
		const principal = principalService.get();
		return apayEnabled === '1' && principal && principal.idInfo && principal.idInfo.xApplePayEnabled;
	}

	get c2pEnabled() {
		const principal = principalService.get();
		return c2payEnabled && principal && principal.idInfo && principal.idInfo.xClickToPayExternalClientId;
	}

	get allExpanded() {
		const {
			data: { sections },
			paymentSections,
			formSettings,
			selectedTab,
		} = this.state;
		const cards = [...sections, ...paymentSections, ...formSettings];

		return every(cards, ({ expanded }) => {
			if (isObject(expanded)) {
				return expanded[selectedTab];
			} else {
				return expanded;
			}
		});
	}

	get achEnabled() {
		return get(principalService.get(), 'idInfo.xACHEnabled', false);
	}

	get paymentFields() {
		const paymentFields = [...this.cardTypesAccepted.fields];
		if (this.achEnabled) {
			paymentFields.push(...this.acceptAch.fields);
		}
		return paymentFields;
	}

	get paymentMethods() {
		return {
			key: 'paymentMethods',
			expanded: this.getExpanded,
			noCheckbox: true,
			toggleExpanded: () => this.toggleExpandedFormSettings('paymentMethod'),
			label: 'Payment Methods',
			isEdit: false,
			isPaymentMethod: true,
			fields: this.paymentFields,
		};
	}

	get cardTypesAccepted() {
		return {
			key: 'cardTypesAccepted',
			label: 'Card Types Accepted',
			tooltip: 'You must select at least one Card Type for your PaymentSITE when ACH is not enabled.',
			isEdit: false,
			isPaymentMethod: true,
			isPrm: true,
			noCheckbox: true,
			fields: [
				this.createCheckboxField('AcceptVisa', 'Visa', null),
				this.createCheckboxField('AcceptMC', 'Mastercard', null),
				this.createCheckboxField('AcceptDiscover', 'Discover', null),
				this.createCheckboxField('AcceptAmex', 'Amex', null),
			],
			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('cardTypesAccepted'),
		};
	}

	get accountTabs() {
		const tabPresets = [
			{
				key: 'tab_a',
				label: 'Tab 1',
				title: 'Title 1',
			},
			{
				key: 'tab_b',
				label: 'Tab 2',
				title: 'Title 2',
			},
			{
				key: 'tab_c',
				label: 'Tab 3',
				title: 'Title 3',
			},
		];
		const allTabs = [];
		times(3, i => {
			allTabs.push({
				key: tabPresets[i].key,
				label: tabPresets[i].label,
				isEdit: false,
				fields: [
					{
						key: 'tab_title',
						name: tabPresets[i].title,
						component: 'input',
						type: 'text',
						value: '',
						isRequired: () => this.state[`entireTab${toUpper(nth(tabPresets[i].key, -1))}`],
					},
					{
						key: 'tab_body',
						name: 'Body',
						type: 'richtext',
						component: ToolbarEditor,
						value: '',
						isRequired: () => this.state[`entireTab${toUpper(nth(tabPresets[i].key, -1))}`],
					},
				],
				expanded: transform(tabs, (acc, tab) => {
					return (acc[tab] = false);
				}),
				toggleExpanded: () => this.toggleExpandedFormSettings(tabPresets[i].key),
			});
		});
		return allTabs;
	}

	get defaultTab() {
		return {
			fields: [
				{
					key: 'tab_title',
					name: 'Title 1',
					value: '',
				},
				{
					key: 'tab_body',
					name: 'Body',
					value: '',
				},
			],
		};
	}

	get receiptOptions() {
		return {
			key: 'receiptOptions',
			label: 'Receipt Options',
			isPrm: true,
			isEdit: false,
			fields: [
				this.createCheckboxField(
					'xCustReceipt',
					'Send customer email receipt',
					'Send receipt to the payee when an email is entered on  the PaymentSITE'
				),
				this.createCheckboxField(
					'SendSmsReceipt',
					'Send customer SMS receipt',
					'$0.02 SMS fee will apply for every text receipt sent.\nTo send SMS receipts, Billing Cell Phone or Billing Phone Number needs to be set as a required field.'
				),
				this.createCheckboxField('ResultPopup', 'Display popup instead of receipt for approved transactions'),
				this.createCheckboxField('ReceiptDefault', 'Default to printer receipt'),
				this.createCheckboxField('ShowLogoOnPrintedReceipt', 'Display logo on printed receipt'),
			],

			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('receiptOptions'),
		};
	}

	get termsAndConditions() {
		return {
			key: 'termsAndConditions',
			label: 'Terms And Conditions',
			isEdit: false,
			fields: [
				{
					key: 'TermsAndConditions',
					hideField: true,
					value: false,
					checked: false,
				},
				{
					key: 'tac_title',
					name: 'Title',
					component: 'input',
					type: 'text',
					value: '',
					isRequired: () => this.state.entireTermsAndConditions,
				},
				{
					key: 'tac_body',
					name: 'Body',
					type: 'richtext',
					component: ToolbarEditor,
					value: '',
					isRequired: () => this.state.entireTermsAndConditions,
				},
				{
					key: 'tac_chk',
					name: 'Display agree checkbox',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
				},
				{
					key: 'tac_chk_txt',
					name: 'Agree label',
					component: 'input',
					type: 'text',
					value: '',
					isRequired: () => {
						const termsAndConditions = find(
							this.state.formSettings,
							({ key }) => toLower(key) === 'termsandconditions'
						);
						const displayAgreeCheckbox = find(termsAndConditions.fields, { key: 'tac_chk' });
						return displayAgreeCheckbox.value;
					},
				},
			],

			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('termsAndConditions'),
		};
	}
	get backgroundColor() {
		return {
			key: 'backgroundColor',
			label: 'Background Color',
			isEdit: false,
			groupedSetting: true,
			fields: [
				{
					key: 'BackgroundColor',
					name: 'Select background color',
					component: ColorPicker,
					type: 'color',
					value: '#ffffff',
					hideField: false,
				},
			],
			toggleExpanded: () => this.toggleExpandedFormSettings('backgroundColor'),
			expanded: transform(tabs, (acc, tab) => {
				return (acc[tab] = false);
			}),
		};
	}
	get convenienceFee() {
		return {
			key: 'convenienceFee',
			label: 'Electronic Transfer Fee',
			inline: true,
			tooltip: "This field was previously referred to as 'Convenience Fee'",
			isEdit: false,
			fields: [
				{
					key: 'ConvenienceFee',
					name: 'Display Electronic Transfer Fee',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
					hideField: true,
				},
				{
					key: 'ConvFee_CCPercent',
					name: 'CC Percent',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: '0%',
					enableInput: false,
					value: '',
					checked: false,
				},
				{
					key: 'ConvFee_ACHPercent',
					name: 'ACH Percent',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: '0%',
					enableInput: false,
					value: '',
					checked: false,
				},
				{
					key: 'ConvFee_CCAmount',
					name: 'CC Set Amount',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: `${CurrencyMap.resolveCurrency()}0`,
					enableInput: false,
					value: '',
					checked: false,
				},
				{
					key: 'ConvFee_ACHAmount',
					name: 'ACH Set Amount',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: `${CurrencyMap.resolveCurrency()}0`,
					enableInput: false,
					value: '',
					checked: false,
				},
				{
					key: 'CustomFieldForDescription',
					name: 'Custom field for Electronic Transfer Fee amount',
					Tooltip: () => (
						<span data-tooltip="Required" className="form__group__required">
							*
						</span>
					),
					options: customFieldOptions,
					isRequired: true,
					type: 'select',
					component: Select,
					value: find(customFieldOptions, { name: '' }),
					disabled: this.shouldDisableConvFeeSelectDropdown,
				},
				{
					key: 'CustomFieldForOriginalAmount',
					name: 'Custom field for original amount',
					Tooltip: () => (
						<span data-tooltip="Required" className="form__group__required">
							*
						</span>
					),
					options: customFieldOptions,
					type: 'select',
					component: Select,
					isRequired: true,
					value: find(customFieldOptions, { name: '' }),
					disabled: this.shouldDisableConvFeeSelectDropdown,
				},
				{
					key: 'ExcludeConvFee',
					name: 'Allow customer option to include/exclude Electronic Transfer Fee?',
					component: 'input',
					type: 'radio',
					options: [{ label: 'No', value: 0 }, { label: 'Yes', value: 1 }],
					value: 'no',
					checked: false,
				},
				{
					key: 'ExcludeConvFeeNote',
					name: 'Note to include Electronic Transfer Fee',
					component: 'textarea',
					value: '',
					disabled: this.shouldDisableExcludeConvFeeNote,
				},
			],
			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('convenienceFee'),
		};
	}

	get paymentSiteLogo() {
		return {
			key: 'paymentSiteLogo',
			label: 'PaymentSITE logo',
			isEdit: false,
			fields: [],
			component: () => {
				const {
					isLogoEdit,
					data: { logo },
				} = this.state;
				const { isLoading } = this.props;

				return (
					<Fragment>
						{(isLogoEdit || !logo) && (
							<Dropzone
								multiple={false}
								onDrop={this.handleDrop}
								minSize={0}
								maxSize={maxSize}
								accept="image/png, image/jpg, image/jpeg"
							>
								{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
									const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > 5242880;
									return (
										<Fragment>
											<div className="upload" {...getRootProps()}>
												<input className="upload__input" {...getInputProps()} />
												{!isDragActive && (
													<Fragment>
														<div className="upload__icon"></div>
														<div className="upload__text">
															<p className="type--p3 type--p3--medium spc--bottom--tny">
																Upload Files or Drag and Drop
															</p>
														</div>
													</Fragment>
												)}
												{isDragActive && !isDragReject && (
													<div className="logo-management__main__drop">
														<strong>Drop file here</strong>
													</div>
												)}
												{isDragReject && (
													<div className="text-danger">
														<strong>File type not accepted, sorry!</strong>
													</div>
												)}
												{isFileTooLarge && (
													<div className="text-danger">
														<strong>File is too large.</strong>
													</div>
												)}
												<div className="upload__text">
													<p className="type--p4 type--color--text--light">
														Recommended image width is 842px.
														<br />
														Allowed formats are .jpg, .jpeg.
													</p>
												</div>
											</div>
										</Fragment>
									);
								}}
							</Dropzone>
						)}
						{logo && (
							<div className="upload__list__item">
								<div className="upload__list__item__thumbnail">
									<img src={logo.startsWith('http') ? logo : 'data:image/png;base64,' + logo} />
								</div>
								<button
									type="button"
									tabIndex="-1"
									className="btn upload__list__item__remove"
									disabled={isLoading}
									onClick={this.handleRemoveLogo}
								>
									<i className="icon icon--sml icon--close"></i>
								</button>
							</div>
						)}
						{isLogoEdit && (
							<button
								onClick={e => this.toggleEdit(e, undefined, 'Logo')}
								className="btn btn--med btn--primary spc--bottom--sml"
							>
								Done
							</button>
						)}
					</Fragment>
				);
			},
			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('paymentSiteLogo'),
		};
	}
	get saveCustomerOnTransaction() {
		return {
			key: 'saveCustomerOnTransaction',
			label: 'SaveCustomerOnTransaction',
			isEdit: false,
			internalSetting: true,
			indent: true,
			fields: [
				this.createCheckboxField('SaveCustomerOnTransaction', 'Save Customer and Payment Method to Customer Database'),
			],
			expanded: this.getExpanded(true),
			toggleExpanded: () => this.toggleExpandedFormSettings('saveCustomerOnTransaction'),
		};
	}

	get redirectOptions() {
		return {
			key: 'redirectOptions',
			label: 'Redirect Options',
			isEdit: false,
			isPrm: true,
			fields: [
				{
					key: 'RedirectUrl',
					name: 'Redirect on success',
					component: 'input',
					type: 'text',
					value: '',
				},
				{
					key: 'RedirectErrorUrl',
					name: 'Redirect on error',
					component: 'input',
					type: 'text',
					value: '',
				},
			],
			expanded: this.getExpanded(true),
			toggleExpanded: () => this.toggleExpandedFormSettings('redirectOptions'),
		};
	}

	get postbackUrl() {
		return {
			key: 'postbackUrl',
			label: 'Postback URL',
			isEdit: false,
			internalSetting: true,
			isPrm: true,
			className: 'spc--bottom--med',
			fields: [
				{
					key: 'HttpPostUrl',
					name: 'Postback URL',
					component: 'input',
					type: 'text',
					value: '',
				},
			],
			expanded: this.getExpanded(true),
			toggleExpanded: () => this.toggleExpandedFormSettings('postbackUrl'),
		};
	}
	get postbackUrlOverride() {
		return {
			key: 'postbackUrlOverride',
			label: 'Allow postback URL Override?',
			isEdit: false,
			internalSetting: true,
			indent: true,
			fields: [this.createCheckboxField('AllowPostUrlOverride', 'Allow postback URL Override?')],
			expanded: this.getExpanded(true),
			toggleExpanded: () => this.toggleExpandedFormSettings('postbackUrlOverride'),
		};
	}

	get serviceFee() {
		return {
			key: 'serviceFee',
			label: 'Service Fee',
			internalSetting: true,
			isEdit: false,
			fields: [
				{
					key: 'ServiceFeePercent',
					name: 'Service Fee CC Percent',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: '0%',
					enableInput: false,
					value: '',
					checked: false,
				},
				{
					key: 'ServiceFeeAchPercent',
					name: 'Service Fee ACH Percent',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: '0%',
					enableInput: false,
					value: '',
					checked: false,
				},
				{
					key: 'ServiceFeeAchAmount',
					name: 'Service Fee ACH Set Amount',
					component: 'input',
					type: ['checkbox', 'numberFormat'],
					placeholder: '0',
					enableInput: false,
					value: '',
					checked: false,
				},
			],
			expanded: transform(tabs, (acc, tab) => {
				return (acc[tab] = false);
			}),
			toggleExpanded: () => this.toggleExpandedFormSettings('serviceFee'),
		};
	}

	get hideCaptcha() {
		return {
			key: 'hideCaptcha',
			label: 'Hide CAPTCHA',
			isEdit: false,
			internalSetting: true,
			isPrm: true,
			tooltip:
				"Add IP Addresses that this form shouldn't show the CAPTCHA, separate IPs with a comma (i.e. 0.0.0.0,127.0.0.1)",
			fields: [
				{
					key: 'HideGoogleRecaptcha',
					name: 'IP Addresses to hide CAPTCHA',
					placeholder: '127.0.0.1',
					component: 'input',
					type: 'text',
					value: '',
					Tooltip: () => (
						<i
							className="icon icon--tny icon--regular--info"
							data-tooltip="Add IP Addresses that this form shouldn't show the CAPTCHA, separate IPs with a comma (i.e.
									0.0.0.0,127.0.0.1)"
						></i>
					),
				},
			],
			expanded: this.getExpanded(true),
			toggleExpanded: () => this.toggleExpandedFormSettings('hideCaptcha'),
		};
	}

	get acceptAch() {
		return {
			key: 'acceptACH',
			label: 'Accept ACH',
			isEdit: false,
			isPaymentMethod: true,
			noCheckbox: true,
			fields: [
				{
					key: 'AcceptACH',
					name: 'Display ACH',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
					hideField: true,
				},
				{
					key: 'RemoveReenterACHNumbers',
					name: 'Require users to re-enter the Account and Routing Number',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
				},
			],
			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('acceptACH'),
		};
	}

	get enableDigitalWallet() {
		const fields = [
			{
				key: 'EnableDigitalWallet',
				name: 'Enable Digital Wallet',
				component: 'input',
				type: 'checkbox',
				value: false,
				checked: false,
				hideField: true,
			},
		];
		if (this.apEnabled) {
			fields.push({
				key: 'EnableApplePay',
				name: 'Enable Apple Pay',
				component: 'input',
				type: 'checkbox',
				value: false,
				checked: false,
				icon: APayBadge,
				Tooltip: ApplePayTooltip,
			});
		}
		if (this.gpEnabled) {
			fields.push({
				key: 'EnableGooglePay',
				name: 'Enable Google Pay',
				component: 'input',
				type: 'checkbox',
				value: false,
				checked: false,
				icon: GPayBadge,
				Tooltip: GooglePayTooltip,
			});
		}
		if (this.c2pEnabled) {
			fields.push({
				key: 'EnableClickToPay',
				name: 'Enable Click-To-Pay',
				component: 'input',
				type: 'checkbox',
				value: false,
				checked: false,
				icon: C2PayBadge,
				Tooltip: ClickToPayTooltip,
			});
		}
		return {
			key: 'enableDigitalWallet',
			label: 'Enable Digital Wallet',
			isEdit: false,
			fields: fields,
			tooltip:
				'List of Digital Wallet options like Apple Pay and Google Pay, offered by Cardknox and available for your website as an alternative payment option.',
			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('enableDigitalWallet'),
		};
	}

	get allowedCommands() {
		return {
			key: 'allowedCommands',
			label: 'Transaction Types',
			tooltip: 'You must select at least one transaction type for your PaymentSITE.',
			isEdit: false,
			noCheckbox: true,
			fields: [
				{
					key: 'cash:sale',
					type: 'checkbox',
					hideField: true,
					value: false,
					checked: false,
				},
				{
					key: 'cc:sale',
					name: 'Sale',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
				},
				{
					key: 'cc:authonly',
					name: 'Auth Only',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
				},
				{
					key: 'cc:save',
					name: 'Save',
					component: 'input',
					type: 'checkbox',
					value: false,
					checked: false,
				},
			],
			expanded: this.getExpanded(),
			toggleExpanded: () => this.toggleExpandedFormSettings('allowedCommands'),
		};
	}

	get fieldOptions() {
		const { data, optionsKey } = this.state;
		const options = get(
			find(
				get(find(get(data, 'sections', []), ({ fields }) => some(fields, ({ key }) => key === optionsKey)), 'fields'),
				({ key }) => key === optionsKey
			),
			'values',
			[]
		);

		return isEmpty(options) ? [{ key: '', value: '' }] : sortBy(options, ['label']);
	}

	getDisplayConvenienceTooltip = () => {
		if (!this.isServiceFeeEnabled()) {
			return "This field was previously referred to as 'Convenience Fee'";
		}
		if (this.state.isAdmin) {
			return 'Electronic Transfer Fee and Service Fee settings cannot both be enabled on a PaymentSITE.';
		}
		return 'Please reach out to Customer Service to enable Electronic Transfer Fee on your PaymentSITE';
	};

	getExpanded = (expand = false) => {
		return transform(tabs, (acc, tab) => {
			acc[tab] = expand;
		});
	};

	getIs3dsSecureEnabled = principal => {
		if (!principal && !principal.idInfo) return false;
		const { x3DSEnabled, x3DSVersion } = principal.idInfo;
		return x3DSEnabled && x3DSVersion === '2';
	};

	async componentDidMount() {
		this.context.toggleExpand(false);
		const { makePendingRequest, handleError } = this.props;
		try {
			window.addEventListener('keydown', this.isUndoShortcut);
			const newState = {};
			const [transactionDisplayLabels, convenienceFee] = await makePendingRequest(
				kvaasService.get(kvaasResources.transactionDisplayLabels, kvaasResources.convenienceFees),
				actionKeys.LOAD
			);

			newState.customDisplayLabels = get(transactionDisplayLabels, 'data');
			newState.convenienceFees = get(convenienceFee, 'data');

			if (!isEmpty(newState.customDisplayLabels) || !isEmpty(newState.convenienceFees)) {
				this.mapCustomDisplayLabelsToCustomFields(newState, newState.customDisplayLabels, newState.convenienceFees);
			}

			this.setState(newState);
			await this.loadPaymentSite(newState.customDisplayLabels);
			this.addOrRemoveReenterFields();
		} catch (e) {
			handleError(e);
		}
	}

	componentWillUnmount() {
		this.context.toggleExpand(true);
		window.removeEventListener('keydown', this.isUndoShortcut);
	}

	createCheckboxField(key, name, tooltip, checked = false) {
		const newField = {
			key,
			name,
			component: 'input',
			type: 'checkbox',
			value: checked,
			checked,
		};
		if (tooltip) {
			newField.Tooltip = () => this.renderTooltip(tooltip);
		}
		return newField;
	}

	handleJsonParse = value => {
		if (!value) return '';
		let parsedValue;
		try {
			parsedValue = JSON.parse(value);
		} catch (e) {
			parsedValue = JSON.parse(replace(replace(value, /\n/g, '\\n'), /\t/g, '\\t'));
		}
		return parsedValue;
	};

	handleTabPress = sectionIndex => e => {
		if (e.key == 'Tab') {
			e.preventDefault();

			const value =
				e.target.value.substring(0, e.target.selectionStart) + '\t' + e.target.value.substring(e.target.selectionEnd);

			this.handleFormSettingsDataChange(sectionIndex, {
				target: {
					name: e.target.name,
					type: e.target.type,
					value,
					caretPositionBeforeTabPress: { start: e.target.selectionStart, end: e.target.selectionEnd },
				},
			});
		}
	};

	isServiceFeeEnabled = () => {
		const { formSettings } = this.state;
		const serviceFee = find(formSettings, setting => setting.key === 'serviceFee');
		let disabled = false;
		if (serviceFee) {
			disabled = some(serviceFee.fields, field => field.enableInput);
		}
		return disabled;
	};

	isUndoShortcut = ({ keyCode, ctrlKey }) => {
		if (ctrlKey && keyCode === 90 && !isEmpty(this.state.undoSteps)) {
			this.handleUndo();
		}
	};

	async setStateAsync(newState) {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	}

	mapCustomDisplayLabelsToCustomFields = (newState, customDisplayLabels, convenienceFees) => {
		const availableFields = [...this.state.availableFields];
		const customSectionIndex = findIndex(availableFields, { key: 'custom' });

		if (!isEmpty(convenienceFees)) {
			const convFeeKey = get(convenienceFees, 'convenienceCustomKey');
			const originalAmountKey = get(convenienceFees, 'originalCustomKey');

			if (convFeeKey) {
				customDisplayLabels[`custom${parseInt(split(toLower(convFeeKey), 'custom')[1])}`] = 'Electronic Transfer Fee';
			}
			if (originalAmountKey) {
				customDisplayLabels[`custom${parseInt(split(toLower(originalAmountKey), 'custom')[1])}`] = 'Original Amount';
			}
		}

		if (customSectionIndex > -1) {
			each(availableFields[customSectionIndex].fields, field => {
				field.customLabel = customDisplayLabels[`custom${parseInt(split(toLower(field.key), 'custom')[1])}`] || null;
			});
			newState.availableFields = availableFields;
		}
	};

	handleSelectedTabChange = (selectedTab, expandSectionKey = '') => {
		if (this.state.selectedTab !== this.selectedTab) {
			this.setState({ selectedTab }, () => {
				if (expandSectionKey) {
					this.expandSelectedSectionOnGeneralSettings(expandSectionKey);
				}
			});
		}
	};

	expandSelectedSectionOnGeneralSettings = expandSectionKey => {
		const formSettings = [...this.state.formSettings];
		each(formSettings, ({ expanded, key }, index) => {
			if (isObject(expanded)) {
				formSettings[index].expanded[tabs.generalSettings] = key === expandSectionKey;
			}
		});
		this.setState({ formSettings });
	};

	handleExpandCollapseAll = () => {
		const { data, paymentSections, formSettings, selectedTab } = this.state;
		const expand = !this.allExpanded;
		const newState = {
			data: cloneDeep(data),
			paymentSections: cloneDeep(paymentSections),
			formSettings: cloneDeep(formSettings),
		};
		each(newState.data.sections, (_, index) => {
			newState.data.sections[index].expanded = expand;
		});

		each(newState.paymentSections, (_, index) => {
			newState.paymentSections[index].expanded = expand;
		});

		each(newState.formSettings, (_, index) => {
			if (isObject(newState.formSettings[index].expanded)) {
				newState.formSettings[index].expanded[selectedTab] = expand;
			} else {
				newState.formSettings[index].expanded = expand;
			}
		});

		this.setState(newState);
	};
	parseToKiosk = (inputData, newSectionId) => {
		const {
			sections: [...sections],
		} = inputData;
		const newSections = [];
		let hasRecurring = false;
		let isAmount = null;
		let amountSectionLabel = 'Amounts';
		this.xAmountField = null;
		let cvvField;
		for (const section of sections) {
			const { label, fields } = section;
			if (label === 'Recurring' && head(fields)) {
				const fieldKey = head(fields).key;
				if (includes(['xRecurring', 'customers_schedules'], fieldKey)) {
					section.isHidden = !this.state.isAdmin && !this.state.isGoPlus;
				}
			}
			isAmount = null;
			const filteredFields = [];
			for (const field of fields) {
				if (field.key === 'xAmount') {
					if (field.inputType !== 'dropdown' && field.inputType !== 'radio') {
						field.inputType = 'dropdown';
						field.values = [{ key: '0', value: '0' }];
					}
					this.xAmountField = field;
					isAmount = true;
				} else if (field.key === 'xCVV') {
					cvvField = field;
				} else if (
					!startsWith(field.key, 'xShip') &&
					!startsWith(field.key, 'ship') &&
					!startsWith(field.key, 'xAmount') &&
					field.key !== 'xCVV'
				) {
					if (toLower(field.key) === 'xrecurring' || toLower(field.key) === 'customers_schedules') {
						hasRecurring = true;
					}
					filteredFields.push(field);
				}
			}
			if (isEmpty(filteredFields) && isAmount) {
				amountSectionLabel = label;
			} else {
				newSections.push({ ...section, label, fields: filteredFields });
			}
		}

		if (this.xAmountField) {
			newSections.unshift(this.setKioskThemeAmounts(newSectionId, this.xAmountField, amountSectionLabel, cvvField));
		}
		if (hasRecurring) {
			newSections.push(this.addKioskThemeRecurring());
		}
		return newSections;
	};

	loadPaymentSite = async (customDisplayLabels, setOriginalData = true) => {
		const {
			match: {
				params: { id },
			},
			handleError,
			makePendingRequest,
			showLoader,
		} = this.props;
		let data = null;
		showLoader(true);
		try {
			data = await makePendingRequest(paymentSiteService.get(parseInt(id)), actionKeys.LOAD);
			const isKioskTheme = get(data, 'theme') === 'kiosk';
			await this.setStateAsync({ isKioskTheme });
			const sectionWithMaxKey = maxBy(data.sections, 'key');
			const maxKey = parseInt(sectionWithMaxKey && sectionWithMaxKey.key) || 0;
			this.incrementer = incrementingId(maxKey + 1);
			if (isKioskTheme) {
				data.sections = this.parseToKiosk(data, this.incrementer().toString());
			}

			const mappedFormSettings = this.mapFormSettingsToState(data);
			let sections = map(data.sections, section => this.mapSection({ ...section, customDisplayLabels }));
			const mappedData = {
				...data,
				sections: [...sections],
				isEdit: false,
			};
			const newState = {
				data: mappedData,
				isKioskTheme: isKioskTheme,
				newSectionId: this.incrementer().toString(),
				formSettings: mappedFormSettings,
				availableFields: this.disableCustomFields(this.mapConvenienceFeesToAvailableFields(mappedData), mappedData),
			};
			if (!this.state.isAdmin) {
				delete newState.data.extraHtml;
			}

			if (setOriginalData) {
				newState.originalData = cloneDeep(mappedData);
				newState.originalData.logo = await this.imageURLtoBase64(newState.originalData.logo);
			}

			await this.setStateAsync(newState);
			await this.updateFieldSelection();
			this.addOrRemoveReenterFields();
			this.handleExpandCollapseAll();
			showLoader(false);
		} catch (e) {
			if (handleError(e)) {
				showLoader(false);
				this.redirectToPaymentSiteList(e);
			}
		}
	};

	redirectToPaymentSiteList = e => {
		const status = get(e, 'response.Status');
		if (status === 'Unauthorized') {
			this.props.history.push({ pathname: '/portal-settings/payment-site' });
		}
	};

	disableCustomFields = (availableFields, { sections }) => {
		const { convenienceFees } = this.state;
		const convFeeKey = get(convenienceFees, 'convenienceCustomKey');
		const originalAmountKey = get(convenienceFees, 'originalCustomKey');

		const newAvailableFields = [...availableFields];
		const paymentSiteHasRecurring = some(sections, ({ fields }) =>
			some(fields, ({ key }) => toLower(key) === 'xrecurring' || toLower(key) === 'customers_schedules')
		);
		const customSectionIndex = findIndex(availableFields, { key: 'custom' });

		if (customSectionIndex === -1) {
			return;
		}
		const convFeeCustomFieldIndex = findIndex(availableFields[customSectionIndex].fields, { key: `x${convFeeKey}` });
		const originalAmountCustomFieldIndex = findIndex(availableFields[customSectionIndex].fields, {
			key: `x${originalAmountKey}`,
		});

		const custom01FieldIndex = findIndex(availableFields[customSectionIndex].fields, { key: 'xCustom01' });

		if (custom01FieldIndex === -1) {
			return;
		}

		if (convFeeCustomFieldIndex !== -1) {
			newAvailableFields[customSectionIndex].fields[convFeeCustomFieldIndex].convFeeCustom = true;
		}
		if (originalAmountCustomFieldIndex !== -1) {
			newAvailableFields[customSectionIndex].fields[originalAmountCustomFieldIndex].originalCustom = true;
		}

		newAvailableFields[customSectionIndex].fields[custom01FieldIndex] = {
			...newAvailableFields[customSectionIndex].fields[custom01FieldIndex],
			disable: paymentSiteHasRecurring || newAvailableFields[customSectionIndex].fields[custom01FieldIndex].disabled,
		};

		return newAvailableFields;
	};

	mapConvenienceFeesToAvailableFields = ({ formSettings }) => {
		const { availableFields } = this.state;
		const newAvailableFields = [...availableFields];
		const customSectionIndex = findIndex(availableFields, { key: 'custom' });
		const customFieldsForConvenienceFields = transform(
			filter(formSettings, item =>
				find(item, (_, key) =>
					some(['customfieldfororiginalamount', 'customfieldfordescription'], item => item === toLower(key))
				)
			),
			(acc, item) => {
				each(item, (value, key) => {
					return (acc[key] = value);
				});
			},
			{}
		);
		each(customFieldsForConvenienceFields, (value, key) => {
			each(newAvailableFields[customSectionIndex].fields, (field, index) => {
				if (field.key === value) {
					newAvailableFields[customSectionIndex].fields[index] = {
						...field,
						originalName: field.name,
						name: this.getConvenienceFeeCustomLabel(key),
						isConvFeeCustomField: true,
					};
				}
			});
		});
		return newAvailableFields;
	};

	findFormSetting = (formSettings, key) => {
		return find(formSettings, ({ Key }) => toLower(Key) === toLower(key));
	};

	mapFormSettingsToState = ({ formSettings }) => {
		const stateFormSettings = [...this.state.formSettings];
		if (
			!this.findFormSetting(formSettings, 'RemoveReenterACHNumbers') &&
			this.findFormSetting(formSettings, 'AcceptACH') &&
			this.findFormSetting(formSettings, 'AcceptACH').Val == 1
		) {
			formSettings.push({ Key: 'RemoveReenterACHNumbers', Val: '0' });
		}
		if (!this.findFormSetting(formSettings, 'ExcludeConvFeeNote')) {
			formSettings.push({ Key: 'ExcludeConvFeeNote', Val: '' });
		}

		if (!this.findFormSetting(formSettings, 'AllowedCommands')) {
			formSettings.push({ Key: 'AllowedCommands', Val: 'cc:sale' });
		}
		each(formSettings, ({ Key, Val }) => {
			if (
				includes(
					[
						'convfee_achpercent',
						'convfee_ccpercent',
						'convfee_achamount',
						'convfee_ccamount',
						'conveniencefee',
						'customfieldfordescription',
						'customfieldfororiginalamount',
						'excludeconvfee',
						'excludeconvfeenote',
					],
					toLower(Key)
				)
			) {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'conveniencefee'),
					Val,
					Key
				);
			} else if (includes(toLower(Key), 'redirect')) {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'redirectoptions'),
					Val,
					Key
				);
			} else if (toLower(Key) === 'httpposturl') {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'postbackurl'),
					Val,
					Key
				);
			} else if (toLower(Key) === 'allowposturloverride') {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'postbackurloverride'),
					Val,
					Key
				);
			} else if (toLower(Key) === 'savecustomerontransaction') {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'savecustomerontransaction'),
					Val,
					Key
				);
			} else if (includes(toLower(Key), 'captcha')) {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'hidecaptcha'),
					Val,
					Key
				);
			} else if (camelCase(Key) === 'termsAndConditions') {
				this.mapTermsAndConditionsToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'termsandconditions'),
					this.handleJsonParse(Val)
				);
			} else if (includes(['servicefeeachpercent', 'servicefeepercent', 'servicefeeachamount'], toLower(Key))) {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key: formKey }) => toLower(formKey) === 'servicefee'),
					Val,
					Key
				);
			} else if (includes(['acceptvisa', 'acceptdiscover', 'acceptmc', 'acceptamex'], toLower(Key))) {
				this.mapCheckboxTypesToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'paymentmethods'),
					Key,
					Val,
					true
				);
			} else if (
				includes(
					['receiptdefault', 'xcustreceipt', 'sendsmsreceipt', 'showlogoonprintedreceipt', 'resultpopup'],
					toLower(Key)
				)
			) {
				this.mapCheckboxTypesToState(find(stateFormSettings, ({ key }) => toLower(key) === 'receiptoptions'), Key, Val);
			} else if (includes(['tab_a', 'tab_b', 'tab_c'], toLower(Key))) {
				this.mapTabToState(
					find(stateFormSettings, ({ key }) => toLower(key) === toLower(Key)),
					this.handleJsonParse(Val)
				);
			} else if (toLower(Key) === 'allowedcommands') {
				this.mapAllowedCommandsToState(find(stateFormSettings, ({ key }) => toLower(key) === 'allowedcommands'), Val);
			} else if (includes(['removereenterachnumbers', 'acceptach'], toLower(Key))) {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, ({ key }) => toLower(key) === 'paymentmethods'),
					Val,
					Key,
					true
				);
			} else if (
				includes(['enabledigitalwallet', 'enablegooglepay', 'enableapplepay', 'enableclicktopay'], toLower(Key)) &&
				(this.gpEnabled || this.apEnabled || this.c2pEnabled)
			) {
				this.mapStandaloneSettingToState(
					find(stateFormSettings, item => toLower(item.key) === 'enabledigitalwallet'),
					Val,
					Key
				);
			} else if (toLower(Key) === 'backgroundcolor') {
				this.mapBackgroundColorToState(find(stateFormSettings, ({ key }) => toLower(key) === 'backgroundcolor'), Val);
			}
		});

		return stateFormSettings;
	};
	shouldDisableExcludeConvFeeNote = () => {
		const { formSettings } = this.state;
		const convFee = find(formSettings, { key: 'convenienceFee' });
		const excludeConvenience = find(convFee.fields, { key: 'ExcludeConvFee' });

		return excludeConvenience.value != '1';
	};

	shouldDisableConvFeeSelectDropdown = () => {
		const { formSettings } = this.state;
		const convenienceFee = find(formSettings, { key: 'convenienceFee' });
		return every(filter(convenienceFee.fields, item => has(item, 'enableInput')), ({ enableInput }) => !enableInput);
	};

	checkIfShouldDisplayFormSettings = (formSettings, newState) => {
		each(formSettings, formSetting => {
			let selected = get(
				find(formSetting.fields, ({ key }) =>
					includes(['conveniencefee', 'acceptach', 'enabledigitalwallet'], toLower(key))
				),
				'value',
				false
			);
			if (!selected) {
				selected = some(
					formSetting.fields,
					({ value, checked }) => checked || (isObject(value) ? value.name : value && value !== 'no')
				);
			}

			if (
				formSetting.key === 'paymentSiteLogo' ||
				(formSetting.key === 'backgroundColor' && this.state.data.theme === 'grey')
			) {
				selected = true;
			}

			newState[`entire${replace(startCase(formSetting.key), /\s/g, '')}`] = selected;
		});
	};

	mapBackgroundColorToState = (stateFormSetting, value) => {
		stateFormSetting.fields[0].value = value;
	};
	mapTabToState = (stateFormSetting, parsedFormSetting) => {
		each(stateFormSetting.fields, ({ key }, index) => {
			const defaultField = find(this.defaultTab.fields, { key });
			let defaultValue = '';
			if (defaultField) {
				defaultValue = defaultField.value;
			}
			stateFormSetting.label = parsedFormSetting.tab_title;
			stateFormSetting.fields[index].value = parsedFormSetting[key] || defaultValue;
		});
	};

	mapTermsAndConditionsToState = (stateFormSetting, parsedFormSetting) => {
		each(stateFormSetting.fields, ({ key }, index) => {
			const defaultField = find(this.termsAndConditions.fields, { key });
			let defaultValue = '';
			if (defaultField) {
				defaultValue = defaultField.value;
			}
			stateFormSetting.fields[index].value = (parsedFormSetting && parsedFormSetting[key]) || defaultValue;
		});
	};

	mapAllowedCommandsToState = (stateFormSetting, value) => {
		const commands = split(value, ',');
		const ccSaveField = find(stateFormSetting.fields, { key: 'cc:save' });
		if (ccSaveField) {
			ccSaveField.hideField = this.state.isKioskTheme;
		}

		if (value !== 'cash:sale') {
			each(commands, key => {
				const field = find(stateFormSetting.fields, { key });
				if (field) {
					field.value = 'Yes';
					field.checked = true;
				}
			});
		}
	};

	mapCheckboxTypesToState = (stateFormSetting, field, value, isCardTypes) => {
		const index = findIndex(stateFormSetting.fields, { key: field });
		if (value == 1 || value === 'printer') {
			stateFormSetting.fields[index].value = 1;
			stateFormSetting.fields[index].checked = true;
		} else {
			stateFormSetting.fields[index].value = 0;
			stateFormSetting.fields[index].checked = false;
		}
		if (isCardTypes && value == 1) {
			this.setState({
				entireCardTypesAccepted: true,
			});
		}
	};

	mapStandaloneSettingToState = (stateFormSetting, value, key, isAch) => {
		const lowerKey = toLower(key);
		const fieldIndex = findIndex(stateFormSetting.fields, ({ key: fieldKey }) => toLower(fieldKey) === lowerKey);
		if (fieldIndex < 0) return;
		let { fields } = stateFormSetting;
		fields[fieldIndex].value = value;

		// Define arrays for keys to be used in conditions
		const feeKeys = ['convfee_achpercent', 'convfee_ccpercent', 'convfee_achamount', 'convfee_ccamount'];
		const serviceKeys = ['servicefeepercent', 'servicefeeachpercent', 'servicefeeachamount'];
		const otherKeys = [
			'excludeconvfee',
			'conveniencefee',
			'acceptach',
			'removereenterachnumbers',
			'enabledigitalwallet',
			'enablegooglepay',
			'enableapplepay',
			'enableclicktopay',
		];

		if (includes(feeKeys, lowerKey)) {
			const index = findIndex(fields, {
				key: endsWith(lowerKey, 'percent') ? `${split(key, 'Percent')[0]}Amount` : `${split(key, 'Amount')[0]}Percent`,
			});
			fields[index].enableInput = false;
			fields[index].value = '';
			fields[fieldIndex].enableInput = !!value;
			fields[fieldIndex].checked = toString(value) !== '0';
		} else if (includes(serviceKeys, lowerKey) && value != '-1') {
			fields[fieldIndex].enableInput = true;
			fields[fieldIndex].checked = true;
		} else if (includes(otherKeys, lowerKey)) {
			const parsedValue = parseInt(value);
			if (lowerKey === 'removereenterachnumbers') {
				fields[fieldIndex].value = parsedValue ? 0 : 1;
				fields[fieldIndex].checked = !parsedValue;
			} else {
				fields[fieldIndex].value = parsedValue ? 1 : 0;
				fields[fieldIndex].checked = !!parsedValue;
			}

			if (isAch && this.achEnabled && value == 1 && lowerKey !== 'removereenterachnumbers') {
				this.setState({ entireAcceptACH: true });
			}
		} else if (startsWith(lowerKey, 'customfield')) {
			fields[fieldIndex].value = { name: value, label: replace(value, 'x', '') };
		}

		if (value) {
			if (startsWith(lowerKey, 'service')) {
				fields[fieldIndex].value = value;
			} else if (endsWith(lowerKey, 'percent')) {
				fields[fieldIndex].value = value * 100;
			}
		}
		if (isAch && value == 1 && this.achEnabled) {
			this.setState({
				entireAcceptACH: true,
			});
		}
	};

	createCustomField = i => {
		return {
			key: `xCustom${padStart(i + 1, 2, 0)}`,
			isSelected: false,
			icon: '',
		};
	};

	hoverSection = async (sourceId, targetId, addField, requestNewFrame = false) => {
		const { newSectionId } = this.state;
		if (targetId === newSectionId) {
			return;
		}
		const targetSection = find(this.state.data.sections, { key: targetId });
		if (!parseInt(sourceId) && targetId === 'empty') {
			await this.hoverSection(newSectionId, 'empty', () => this.hoverField(sourceId, `${newSectionId}.empty`));
			return;
		}
		if (!parseInt(sourceId) && targetSection && !targetSection.expanded) {
			if (this.previousTargetId !== targetId && this.previousTargetId) {
				this.toggleExpandCollapseSection(this.previousTargetId);
			}
			this.toggleExpandCollapseSection(targetId);
			this.previousTargetId = targetId;
		} else if (parseInt(sourceId)) {
			this.pendingUpdateFunction = ({ data, newSectionId }) => {
				const sections = [...data.sections];
				const sourceItem =
					sourceId === newSectionId
						? this.mapSection({
								key: newSectionId,
								label: 'New Section',
								fields: [],
								params: { version: '1.1', box_width: 0, box_column: '1' },
						  })
						: find(sections, { key: sourceId });
				const sourceIndex = findIndex(sections, { key: sourceId });
				const targetIndex = targetId === 'empty' ? sections.length : findIndex(sections, { key: targetId });
				if (sections[targetIndex]) {
					sections[targetIndex] = { ...sections[targetIndex] };
				}
				if (sourceIndex > -1) {
					sections.splice(sourceIndex, 1);
				}
				sections.splice(targetIndex, 0, { ...sourceItem });
				const modifiedState = {
					data: {
						...data,
						sections,
					},
				};
				if (sourceId === newSectionId) {
					modifiedState.newSectionId = this.incrementer().toString();
				}
				modifiedState.undoSteps = this.cloneCurrentStateAsUndoStep();
				return modifiedState;
			};
		}
		if (requestNewFrame) {
			await this.drawFrame(addField);
		} else if (!this.requestedFrame) {
			this.requestedFrame = requestAnimationFrame(() => {
				(async () => {
					await this.drawFrame(addField);
				})();
			});
		}
	};

	hoverField = async (sourceId, targetId, additionalData = {}, requestNewFrame = false) => {
		if (endsWith(sourceId, '.empty')) {
			return;
		}
		if (startsWith(targetId, 'undefined') || startsWith(targetId, 'null')) {
			each(this.state.data.sections, section => {
				if (find(section.fields, field => field.key === 'xAmount')) {
					targetId = section.id + '.empty';
				}
			});
		}
		if (startsWith(sourceId, 'bill city, state, zip') || startsWith(sourceId, 'ship city, state, zip')) {
			const removeKey = endsWith(sourceId, 'zip required')
				? replace(sourceId, ' zip required', '')
				: `${sourceId} zip required`;
			const sectionWithField = find(this.state.data.sections, ({ fields }) => some(fields, { key: removeKey }));
			if (sectionWithField) {
				if (removeKey === targetId) {
					return;
				}
				await this.removeEditField(sectionWithField.key, removeKey);
			}
		}
		this.pendingUpdateFunction = ({ data, availableFields }) => {
			const sections = [...data.sections];
			const modifiedState = {
				data: {
					...data,
					sections,
				},
			};

			const sourceSectionIndex = findIndex(sections, ({ fields }) => some(fields, { key: sourceId }));
			let sourceSection;
			let sourceField;
			if (sourceSectionIndex !== -1) {
				sourceSection = { ...sections[sourceSectionIndex], fields: [...sections[sourceSectionIndex].fields] };
				const sourceFieldIndex = findIndex(sourceSection.fields, { key: sourceId });
				sourceField = { ...sourceSection.fields[sourceFieldIndex], ...additionalData };
				sections[sourceSectionIndex] = sourceSection;
				sourceSection.fields.splice(sourceFieldIndex, 1);
			} else {
				modifiedState.availableFields = [...availableFields];
				const sourceFieldSectionIndex = findIndex(modifiedState.availableFields, ({ fields }) =>
					some(fields, { key: sourceId })
				);
				if (sourceFieldSectionIndex === -1) {
					return;
				}
				const sourceFieldSection = {
					...modifiedState.availableFields[sourceFieldSectionIndex],
					fields: [...modifiedState.availableFields[sourceFieldSectionIndex].fields],
				};
				modifiedState.availableFields[sourceFieldSectionIndex] = sourceFieldSection;
				const sourceFieldIndex = findIndex(sourceFieldSection.fields, { key: sourceId });
				sourceField = {
					key: sourceFieldSection.fields[sourceFieldIndex].key,
					label: sourceFieldSection.fields[sourceFieldIndex].name,
					inputType: sourceFieldSection.fields[sourceFieldIndex].inputType,
					showInReceipt: sourceFieldSection.fields[sourceFieldIndex].showInReceipt,
					showInPrintReceipt: sourceFieldSection.fields[sourceFieldIndex].showInPrintReceipt,
					isRequired: isFunction(sourceFieldSection.fields[sourceFieldIndex].isRequired)
						? false
						: sourceFieldSection.fields[sourceFieldIndex].isRequired,
					customLabel: sourceFieldSection.fields[sourceFieldIndex].customLabel,
					...additionalData,
				};
			}

			let targetSectionIndex = findIndex(sections, ({ fields }) => some(fields, { key: targetId }));
			if (targetSectionIndex === -1) {
				if (!endsWith(targetId, '.empty')) {
					return;
				}
				const [targetSectionKey] = split(targetId, '.empty');
				targetSectionIndex = findIndex(sections, { key: targetSectionKey });
				if (targetSectionIndex === -1) {
					targetSectionIndex = 0;
				}
				const targetSection = { ...sections[targetSectionIndex], fields: [...sections[targetSectionIndex].fields] };
				targetSection.fields.push(this.mapField(targetSectionKey, sourceField));
				targetSection.expanded = true;
				sections[targetSectionIndex] = targetSection;
			} else {
				const targetSection =
					targetSectionIndex === sourceSectionIndex
						? sourceSection
						: { ...sections[targetSectionIndex], fields: [...sections[targetSectionIndex].fields] };
				const targetFieldIndex = findIndex(data.sections[targetSectionIndex].fields, { key: targetId });
				sections[targetSectionIndex] = targetSection;

				targetSection.fields.splice(targetFieldIndex, 0, this.mapField(targetSection.key, sourceField));
			}

			modifiedState.undoSteps = this.cloneCurrentStateAsUndoStep();

			return modifiedState;
		};
		if (requestNewFrame) {
			await this.drawFrame();
		} else if (!this.requestedFrame) {
			this.requestedFrame = requestAnimationFrame(() => {
				(async () => {
					await this.drawFrame();
				})();
			});
		}
	};

	drawFrame = async addField => {
		this.setState(this.pendingUpdateFunction, this.updateFieldSelection);
		this.pendingUpdateFunction = null;
		this.requestedFrame = null;
		await invokeIfFunction(addField);
	};

	setRef = (sectionKey, fieldKey) => ref => {
		const section = find(this.state.data.sections, { key: sectionKey });
		if (!section) {
			return;
		}
		if (!fieldKey) {
			section.currentRef = ref;
			return;
		}

		const field = find(section.fields, {
			key: fieldKey,
		});
		if (!field) {
			return;
		}
		field.currentRef = ref;
	};

	createActionRef = (newRef, fieldExpanded) => {
		if (fieldExpanded && newRef && this.state.sectionActionsRef !== newRef) {
			this.setState({ sectionActionsRef: newRef });
		}
	};

	createAvsFields = () => {
		return map([createField('xStreet', 'address'), createField('xZip', 'city')], this.mapAvailableFields('avs'));
	};

	isPhoneRequired = (section, dependentPhoneField) => () => {
		if (section !== 'billing') return false;
		const receiptSettings = find(this.state.formSettings, { key: 'receiptOptions' });
		if (!receiptSettings) return false;
		const sendSmsReceipt = find(receiptSettings.fields, { key: 'SendSmsReceipt' });
		if (!sendSmsReceipt || !sendSmsReceipt.value) return false;
		const billingFields = find(this.state.availableFields, { key: 'billing' }).fields;
		return !find(billingFields, { key: dependentPhoneField }).isSelected;
	};

	createAddressFields = (section, fieldName) => {
		const newFields = [
			createField(`x${fieldName}FirstName`, 'name'),
			createField(`x${fieldName}LastName`, 'name'),
			createField(`x${fieldName}Company`, 'company'),
			{
				...createField(`x${fieldName}Phone`, `phone-number`),
				isRequired: section === 'shipping' ? false : this.isPhoneRequired(section, 'xBillMobile'),
				customErrorMessage: 'Either Billing Phone Number or Billing Cell Phone is required',
			},
			createField(`x${fieldName}Mobile`, `phone-number`),
			createField(`x${fieldName === 'Bill' ? '' : fieldName}Email`, `email-address`),
			createField(`x${fieldName}Street`, `address`),
			createField(`x${fieldName}Street2`, `address`),
			createField(`${toLower(fieldName)} city, state, zip`, `city`),
			createField(`${toLower(fieldName)} city, state, zip zip required`, `city`),
			createField(`x${fieldName}Country`, `country`),
		];
		if (section === 'billing') {
			newFields.unshift(createField('xBillCity', 'city'));
			newFields.unshift(createField('xBillState', 'state', false, true));
			newFields.unshift(createField('xBillZip', 'zip'));
		}
		if (section === 'shipping') {
			newFields.unshift(createField('same_as_billing', 'copy-outline'));
		}
		return map(newFields, this.mapAvailableFields(section));
	};
	shouldDisableLabelSave = sectionLabel => {
		return size(sectionLabel) > 50;
	};

	mapAvailableFields = sectionKey => field => ({
		setRef: ref => {
			const availableSection = find(this.state.availableFields, { key: sectionKey });
			if (!availableSection) {
				return;
			}
			const availableField = find(availableSection.fields, { key: field.key });
			if (!availableField) {
				return;
			}
			availableField.currentRef = ref;
		},
		hideDropdownOptionPreview: true,
		inputType: 'text',
		showInReceipt: false,
		showInPrintReceipt: false,
		isRequired: false,
		isSelected: false,
		value: '',
		name: setToCanadianVerbiage(paymentSiteFields[toLower(field.key)]),
		toggleSelect: () => this.toggleSelect(field.key),
		shouldValidateAmount: this.shouldValidateAmount,
		...field,
	});

	mapSection = ({ fields, key, label, customDisplayLabels, expanded = false, addFieldExpanded = false, ...rest }) => ({
		disableLabelSave: this.shouldDisableLabelSave,
		label,
		key,
		fields: map(fields, field =>
			this.mapField(key, {
				...field,
				customLabel: customDisplayLabels[`custom${parseInt(split(toLower(field.key), 'custom')[1])}`] || null,
				disableRemove: this.shouldDisableField,
			})
		),
		isEdit: false,
		expanded,
		addFieldExpanded,
		setRef: this.setRef(key),
		inputRef: createRef(),
		toggleEdit: e => this.toggleEdit(e, key),
		actions: [
			{
				isAdd: true,
				icon: 'add--light',
				activeClass: 'is-active',
				action: e => this.toggleAddFieldPopup(e, key),
				tooltip: 'Add Additional Fields',
			},
			{
				icon: 'delete--light',
				action: () => {
					this.toggleRemoveSectionPopup(() => this.removeSection(key), label, null, key);
				},
				tooltip: () => this.getDeleteButtonTooltip(key),
				disabled: () => this.checkIfHasConvFeeCustomFields(key) || this.shouldDisableSection(key),
			},
			{
				separator: true,
				isExpandCollapse: true,
				icon: () => {
					let isExpanded = true;
					const sections = get(this.state, 'data.sections');
					if (sections) {
						const section = find(sections, { key });
						if (section) {
							isExpanded = !!section.expanded;
						}
					}
					return `chevron--${isExpanded ? 'down' : 'top'}--primary`;
				},
				action: () => this.toggleExpandCollapseSection(key),
				tooltip: () => {
					let isExpanded = true;
					const sections = get(this.state, 'data.sections');
					if (sections) {
						const section = find(sections, { key });
						if (section) {
							isExpanded = !!section.expanded;
						}
					}
					return isExpanded ? 'Collapse' : 'Expand';
				},
			},
		],
		...rest,
	});

	getDeleteButtonTooltip = key => {
		let tooltip = 'Delete Section';
		if (this.checkIfHasConvFeeCustomFields(key) && this.shouldDisableSection(key)) {
			tooltip =
				'You cannot remove this section because it contains the Amount field and is used by Electronic Transfer Fees.';
		} else if (this.checkIfHasConvFeeCustomFields(key)) {
			tooltip = 'You cannot remove this section because it is used by Electronic Transfer Fees.';
		} else if (this.shouldDisableSection(key)) {
			tooltip = 'You cannot remove this section because it contains the Amount field.';
		}
		return tooltip;
	};

	shouldDisableField = field => {
		return (
			(field.key === 'xAmount' && this.shouldValidateAmount()) || (field.key === 'Other' && this.state.isKioskTheme)
		);
	};

	shouldDisableSection = key => {
		const {
			data: { sections },
		} = this.state;
		const section = find(sections, section => section.key === key);
		if (section) return some(section.fields, this.shouldDisableField);
		else return false;
	};

	mapField = (sectionKey, field) => {
		const { formSettings } = this.state;
		const convenienceFeeCustomFields = filter(
			get(find(formSettings, { key: 'convenienceFee' }), 'fields', []),
			({ key }) => includes(['customfieldfordescription', 'customfieldfororiginalamount'], toLower(key))
		);
		let customConvFeeKey = '';
		const isConvFeeCustomField = some(convenienceFeeCustomFields, ({ value, key }) => {
			if (value.name === field.key) {
				customConvFeeKey = key;
				return true;
			}
		});

		return {
			...field,
			isFieldPreview: true,
			isConvFeeCustomField,
			label: isConvFeeCustomField
				? this.getConvenienceFeeCustomLabel(customConvFeeKey)
				: setToCanadianVerbiage(field.label),
			removeField: () => this.onBeforeRemoveFieldOption(sectionKey, field.key),
			setEditField: e =>
				this.removeEditField(sectionKey, field.key, true, e.target.name || get(e.target, 'dataset.name', '')),
			setRef: this.setRef(sectionKey, field.key),
		};
	};

	getConvenienceFeeCustomLabel = fieldKey => {
		if (toLower(fieldKey) === 'customfieldfororiginalamount') {
			return 'Original Amount';
		} else if (toLower(fieldKey) === 'customfieldfordescription') {
			return 'Electronic Transfer Fee';
		}
	};

	checkIfHasConvFeeCustomFields = key => {
		const {
			data: { sections },
		} = this.state;
		const sectionIndex = findIndex(sections, { key });
		if (sectionIndex > -1) {
			return find(sections[sectionIndex].fields, { isConvFeeCustomField: true });
		}
	};

	removeEditField = async (sectionKey, fieldKey, isEdit, name) => {
		const newState = {
			data: {
				...this.state.data,
			},
		};
		const sections = [...newState.data.sections];
		newState.data.sections = sections;
		const sectionIndex = findIndex(sections, ({ key }) => key === sectionKey);
		if (sectionIndex === -1) {
			return;
		}
		const section = { ...sections[sectionIndex] };
		sections[sectionIndex] = section;
		const fields = [...section.fields];
		const fieldIndex = findIndex(fields, ({ key }) => key === fieldKey);
		if (fieldKey === -1) {
			return;
		}
		if (isEdit) {
			if (fields[fieldIndex].isFieldPreview) {
				fields[fieldIndex].oldLabel = fields[fieldIndex].label;
			} else {
				if (toLower(name) === 'confirm') {
					section.disableDrag = false;
					fields[fieldIndex].disableDrag = false;
					fields[fieldIndex].oldLabel = null;
					fields[fieldIndex].isNewField = false;
				} else {
					fields[fieldIndex].label = fields[fieldIndex].oldLabel;
					fields[fieldIndex].oldLabel = null;
				}
			}
			fields[fieldIndex].isFieldPreview = !fields[fieldIndex].isFieldPreview;
			fields[fieldIndex].disableDrag = !fields[fieldIndex].isFieldPreview;
			section.disableDrag = !fields[fieldIndex].isFieldPreview;
		} else {
			fields.splice(fieldIndex, 1);
		}
		section.fields = fields;
		newState.undoSteps = this.cloneCurrentStateAsUndoStep();

		await this.setStateAsync(newState);
		await this.updateFieldSelection();
	};
	moveOption = (index, moveUp = false) => () => {
		const { data, optionsKey } = this.state;
		const sectionId = this.getOptionsSectionId(data, optionsKey);
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);

		const cannotMove =
			(moveUp && index === 0) ||
			(!moveUp && index === data.sections[sectionIndex].fields[fieldIndex].values.length - 1);

		if (cannotMove) {
			return;
		}

		const optionArray = cloneDeep(data.sections[sectionIndex].fields[fieldIndex].values);
		const option = optionArray[index];

		optionArray[index] = optionArray[index - (moveUp ? 1 : -1)];
		optionArray[index - (moveUp ? 1 : -1)] = option;

		const newState = {
			data: {
				...data,
				sections: data.sections.map((section, sIndex) => {
					if (sIndex === sectionIndex) {
						return {
							...section,
							fields: section.fields.map((field, fIndex) => {
								if (fIndex === fieldIndex) {
									return {
										...field,
										values: optionArray,
									};
								}
								return field;
							}),
						};
					}
					return section;
				}),
			},
			isFieldOptionsDirty: true,
		};

		this.setState(newState);
	};

	addRemoveCustomConvenienceFieldsFromSections = async ({
		fieldKey = '',
		name = '',
		previousConvenienceFeeCustomFields = [],
	}) => {
		const {
			formSettings,
			availableFields,
			data: { sections },
		} = this.state;
		const index = findIndex(formSettings, { key: 'convenienceFee' });
		const customFieldsForConvenienceFields = filter(formSettings[index].fields, ({ key }) =>
			includes(['CustomFieldForOriginalAmount', 'CustomFieldForDescription'], key)
		);
		const newAvailableFields = [...availableFields];
		const customSectionIndex = findIndex(newAvailableFields, { key: 'custom' });
		const fields = newAvailableFields[customSectionIndex].fields;
		const hasPreviousCustomFields = !isEmpty(previousConvenienceFeeCustomFields);

		if (fieldKey) {
			const fieldIndex = findIndex(formSettings[index].fields, { key: fieldKey });
			const previousFieldInSection = formSettings[index].fields[fieldIndex];
			const fieldInSection = find(sections, ({ fields }) => find(fields, { key: name }));

			if (previousFieldInSection) {
				const name = get(previousFieldInSection, 'value.name', '');

				await this.toggleSelect(name);

				const customFieldIndex = findIndex(fields, { key: name });

				if (customFieldIndex > -1) {
					newAvailableFields[customSectionIndex].fields[customFieldIndex] = {
						...fields[customFieldIndex],
						isConvFeeCustomField: false,
						name: fields[customFieldIndex].originalName,
						isSelected: false,
					};
				}
			}

			const customFieldIndex = findIndex(fields, { key: name });

			if (customFieldIndex > -1) {
				const isConvFeeCustomField = hasPreviousCustomFields ? false : fields[customFieldIndex].key === name;

				newAvailableFields[customSectionIndex].fields[customFieldIndex] = {
					...fields[customFieldIndex],
					isConvFeeCustomField,
					originalName: fields[customFieldIndex].name,
					name: isConvFeeCustomField ? this.getConvenienceFeeCustomLabel(fieldKey) : fields[customFieldIndex].label,
					isSelected: isConvFeeCustomField,
				};
			}

			const newState = { availableFields: newAvailableFields };

			if (!fieldInSection) {
				if (!first(sections)) {
					await this.toggleSelect(name, false, true);
					const fieldIndex = findIndex(this.state.data.sections[0].fields, { key: name });

					if (customFieldIndex > -1) {
						const isConvFeeCustomField = hasPreviousCustomFields ? false : fields[customFieldIndex].key === name;

						newState.data = { ...this.state.data };
						newState.data.sections = [...this.state.data.sections];
						newState.data.sections[0].fields[fieldIndex] = {
							...this.state.data.sections[0].fields[fieldIndex],
							label: isConvFeeCustomField
								? this.getConvenienceFeeCustomLabel(fieldKey)
								: fields[customFieldIndex].label,
							isConvFeeCustomField,
						};
					}
				} else {
					await this.toggleSelect(name);
				}
			} else {
				const sectionIndex = findIndex(this.state.data.sections, { key: fieldInSection.key });
				const fieldIndex = findIndex(this.state.data.sections[sectionIndex].fields, { key: name });
				const isConvFeeCustomField = hasPreviousCustomFields ? false : fields[customFieldIndex].key === name;

				if (customFieldIndex > -1) {
					newState.data = { ...this.state.data };
					newState.data.sections = [...this.state.data.sections];
					newState.data.sections[sectionIndex].fields[fieldIndex] = {
						...this.state.data.sections[sectionIndex].fields[fieldIndex],
						label: isConvFeeCustomField ? this.getConvenienceFeeCustomLabel(fieldKey) : fields[customFieldIndex].label,
						isConvFeeCustomField,
					};
				}
			}

			this.setState(newState);
		} else {
			const customFields = hasPreviousCustomFields
				? previousConvenienceFeeCustomFields
				: customFieldsForConvenienceFields;
			for (var item of customFields) {
				const {
					value: { name },
				} = item;
				if (name) {
					const customFieldIndex = findIndex(fields, { key: name });
					const isConvFeeCustomField = hasPreviousCustomFields ? false : fields[customFieldIndex].key === name;
					newAvailableFields[customSectionIndex].fields[customFieldIndex] = {
						...fields[customFieldIndex],
						isConvFeeCustomField,
						isSelected: isConvFeeCustomField,
						originalName: fields[customFieldIndex].name,
						name: isConvFeeCustomField
							? this.getConvenienceFeeCustomLabel(get(find(customFields, ({ value }) => name === value.name), 'key'))
							: fields[customFieldIndex].originalName || fields[customFieldIndex].name,
					};
					await this.toggleSelect(name, false, true);
				}
			}
			this.setState({ availableFields: newAvailableFields });
		}
	};

	removeAccountTabs = () => {
		const { formSettings } = this.state;
		const newFormSettings = filter(formSettings, ({ key }) => !includes(key, 'tab'));
		const availableTabs = this.accountTabs;
		each(availableTabs, tab => (tab.isEdit = true));
		newFormSettings.push(...availableTabs);

		this.setState({
			formSettings: newFormSettings,
			entireTabA: false,
			entireTabB: false,
			entireTabC: false,
			undoSteps: this.cloneCurrentStateAsUndoStep(),
		});
	};

	addRemovePaymentMethods = (sectionIndex, newValue, isAch) => {
		const { formSettings } = this.state;

		const newState = { formSettings: [...formSettings], undoSteps: this.cloneCurrentStateAsUndoStep() };
		const formSetting = newState.formSettings[sectionIndex];
		formSetting.isEdit = true;
		const formFields = formSetting.fields;
		if (!isAch) {
			each(formFields, field => {
				if (includes(cardTypes, field.key)) {
					field.value = newValue;
					field.checked = !!newValue;
				}
			});
			newState.entireCardTypesAccepted = newValue;
		} else {
			const achIndex = findIndex(formSetting.fields, field => field.key === 'AcceptACH');
			const reEnterIndex = findIndex(formSetting.fields, field => field.key === 'RemoveReenterACHNumbers');
			formSettings[sectionIndex].fields[reEnterIndex].checked = true;
			formSettings[sectionIndex].fields[reEnterIndex].value = '0';

			formSettings[sectionIndex].fields[achIndex].value = newValue;
			formSettings[sectionIndex].isEdit = true;

			newState.entireAcceptACH = newValue;
		}
		formSetting.fields = formFields;
		newState.formSettings[sectionIndex] = formSetting;
		this.setState(newState, () => {
			this.addOrRemoveReenterFields();
			this.validateFields();
		});
	};

	renderAddRemovePaymentMethods = (isAcceptVisa, sectionIndex, isAch, title, id, labelName) => {
		const isEntireSection =
			(isAcceptVisa && this.state.entireCardTypesAccepted) || (isAch && this.state.entireAcceptACH);
		return (
			<div className="checkbox-tree__parent">
				<input
					onChange={() =>
						isEntireSection
							? this.toggleRemoveSectionPopup(() => this.addRemovePaymentMethods(sectionIndex, 0, isAch), title)
							: this.addRemovePaymentMethods(sectionIndex, 1, isAch)
					}
					type="checkbox"
					className="input--check"
					id={id}
					name={id}
					value={isEntireSection}
					checked={isEntireSection}
					disabled={isAch ? !this.achEnabled : !this.state.entireCardTypesAccepted}
				/>
				<label htmlFor={id}>{labelName}</label>
			</div>
		);
	};

	removeFormSetting = async key => {
		const { formSettings } = this.state;
		const index = findIndex(formSettings, { key });
		const newState = { formSettings: [...formSettings] };
		const isTab = includes(toLower(key), 'tab');
		if (index > -1) {
			const oldExpanded = newState.formSettings[index].expanded;
			const availableTabs = this.accountTabs;
			let formSetting;
			if (isTab) {
				formSetting = newState.formSettings[index] = find(availableTabs, tab => tab.key === toLower(key));
			} else {
				formSetting = newState.formSettings[index] = clone(this[camelCase(key)]);
			}
			if (formSetting) {
				formSetting.isEdit = true;
			}
			formSetting.expanded = oldExpanded;

			newState[`entire${replace(startCase(key), /\s/g, '')}`] = false;
			newState.undoSteps = this.cloneCurrentStateAsUndoStep();

			if (key === 'convenienceFee') {
				const previousConvenienceFeeCustomFields = filter(
					get(find(formSettings, { key: 'convenienceFee' }), 'fields', []),
					({ key }) => includes(['customfieldfordescription', 'customfieldfororiginalamount'], toLower(key))
				);
				await this.addRemoveCustomConvenienceFieldsFromSections({ previousConvenienceFeeCustomFields });
			}
			if (key === 'paymentSiteLogo') {
				await this.removeLogo();
			}
			this.setState(newState, () => {
				this.addOrRemoveReenterFields();
				this.validateFields();
			});
		}
	};
	handleRemoveKioskRecurring = async sectionKey => {
		if (startsWith(sectionKey, 'recurring') && this.state.isKioskTheme) {
			const sectionIndex = findIndex(this.state.data.sections, section =>
				some(section.fields, field => field.key === 'customers_schedules')
			);
			await this.removeEditField(this.state.data.sections[sectionIndex].key, 'customers_schedules');

			const sections = [...this.state.data.sections];

			if (isEmpty(sections[sectionIndex].fields)) {
				await this.setStateAsync({
					data: { ...this.state.data, sections: without(sections, sections[sectionIndex]) },
				});
			}
		}
	};
	removeSection = async sectionKey => {
		await this.handleRemoveKioskRecurring(sectionKey);
		const newState = {
			data: {
				...this.state.data,
			},
		};

		const sections = [...newState.data.sections];
		newState.data.sections = sections;
		newState.undoSteps = this.cloneCurrentStateAsUndoStep();
		const sectionIndex = findIndex(sections, ({ key }) => key === sectionKey);
		let cvvField = find(sections[sectionIndex].fields, { key: 'xCVV' });
		if (sectionIndex === -1) {
			return;
		}
		if (sections[sectionIndex - 1]) {
			sections[sectionIndex - 1] = { ...sections[sectionIndex - 1] };
			if (cvvField) {
				sections[sectionIndex - 1].fields.push(this.mapField(sections[sectionIndex - 1].key, cvvField));
				cvvField = null;
			}
		}
		if (sections[sectionIndex + 1]) {
			sections[sectionIndex + 1] = { ...sections[sectionIndex + 1] };
			if (cvvField) {
				sections[sectionIndex + 1].fields.push(this.mapField(sections[sectionIndex + 1].key, cvvField));
				cvvField = null;
			}
		}
		sections.splice(sectionIndex, 1);
		await this.setStateAsync(newState);
		await this.updateFieldSelection();
	};

	addTab = () => {
		const { entireTabA, entireTabB } = this.state;
		let addKey = '';
		if (!entireTabA) {
			addKey = 'tab_a';
		} else if (!entireTabB) {
			addKey = 'tab_b';
		} else {
			addKey = 'tab_c';
		}
		this.addEntireFormSetting(addKey, false, true);
	};

	addOrRemoveReenterFields = () => {
		const { formSettings, paymentSections } = this.state;
		const paymentMethodsIndex = findIndex(formSettings, setting => setting.key === 'paymentMethods');
		const achSectionIndex = findIndex(paymentSections, { key: 'ach' });
		if (achSectionIndex === -1) {
			return;
		}
		const removeReenter = find(formSettings[paymentMethodsIndex].fields, { key: 'RemoveReenterACHNumbers' }).checked;

		const achSection = { ...paymentSections[achSectionIndex] };
		const fields = removeReenter
			? [...achSection.fields, ...map(reenterFields, this.mapAvailableFields('ach'))]
			: filter(achSection.fields, ({ key }) => key !== 'ReenterAccountNumber' && key !== 'ReenterRoutingNumber');

		achSection.fields = uniqBy(fields, 'key');

		this.setState(prevState => ({
			paymentSections: map(prevState.paymentSections, (section, index) =>
				index !== achSectionIndex ? section : achSection
			),
		}));
	};

	toggleFormInlineEdit = async (e, sectionKey) => {
		e.preventDefault();
		const { formSettings } = this.state;
		const formSetting = find(formSettings, ({ key }) => key === sectionKey);
		formSetting.inlineEdit = !formSetting.inlineEdit;
		this.setState(
			{
				formSettings,
			},
			() => {
				this.validateFields();
			}
		);
	};

	toggleEdit = async (e, sectionKey, editSection) => {
		e.preventDefault();

		if (editSection) {
			this.setState({
				[`is${editSection}Edit`]: !this.state[`is${editSection}Edit`],
			});
		} else {
			const section = find(this.state.data.sections, ({ key }) => key === sectionKey);
			const name = section ? `${section.key}.isEdit` : 'isEdit';
			const isEdit = section ? section.isEdit : this.state.data.isEdit;
			await this.handleDataChange({ target: { name, type: 'checkbox', checked: !isEdit } }, false);
			const updatedSection = find(this.state.data.sections, ({ key }) => key === sectionKey);
			const inputRef = updatedSection ? updatedSection.inputRef : this.inputRef;
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	};

	toggleExpanded = key => {
		const paymentSections = [...this.state.paymentSections];
		const sectionIndex = findIndex(paymentSections, { key });
		if (sectionIndex === -1) {
			return;
		}
		paymentSections[sectionIndex] = {
			...paymentSections[sectionIndex],
			expanded: !paymentSections[sectionIndex].expanded,
		};
		this.setState({
			paymentSections,
		});
	};

	toggleAddFieldPopup = (e, key, fromOutsideClick) => {
		e.preventDefault();
		e.stopPropagation();
		if (
			this.state.isPendingToggle ||
			(fromOutsideClick && (e.target.className.includes('add') || e.target.id === 'addButton'))
		)
			return;

		this.setState({ isPendingToggle: true });

		const { sections } = this.state.data;
		const sectionIndex = findIndex(sections, { key });
		if (!key) {
			if (isNull(this.state.activeSectionIndex)) {
				this.setState({ isPendingToggle: false });
				return;
			}
			const activeSection = sections[this.state.activeSectionIndex];
			const updatedSection = { ...activeSection, addFieldExpanded: !activeSection.addFieldExpanded };
			const updatedSections = concat(
				slice(sections, 0, this.state.activeSectionIndex),
				updatedSection,
				slice(sections, this.state.activeSectionIndex + 1)
			);
			this.setState({
				data: { ...this.state.data, sections: updatedSections },
				addFieldKey: null,
				activeSectionIndex: null,
				isPendingToggle: false,
			});
		} else if (sectionIndex === -1) {
			this.setState({ isPendingToggle: false });
			return;
		} else {
			each(sections, section => {
				if (section.key !== sections[sectionIndex].key) {
					section.addFieldExpanded = false;
				}
			});
			const updatedSection = { ...sections[sectionIndex], addFieldExpanded: !sections[sectionIndex].addFieldExpanded };
			const updatedSections = concat(
				slice(sections, 0, sectionIndex),
				updatedSection,
				slice(sections, sectionIndex + 1)
			);

			this.setState({
				data: { ...this.state.data, sections: updatedSections },
				addFieldKey: key,
				activeSectionIndex: sectionIndex,
				isPendingToggle: false,
				sectionActionsRef: updatedSection.addFieldExpanded ? this.state.sectionActionsRef : null,
			});
		}
	};
	toggleExpandCollapseSection = key => {
		this.previousTargetId = null;
		const sections = [...this.state.data.sections];
		const sectionIndex = findIndex(sections, { key });
		if (sectionIndex === -1) {
			return;
		}
		sections[sectionIndex] = {
			...sections[sectionIndex],
			expanded: !sections[sectionIndex].expanded,
		};
		this.setState({
			data: {
				...this.state.data,
				sections,
			},
		});
	};

	toggleExpandedSection = key => {
		const availableFields = [...this.state.availableFields];
		const sectionIndex = findIndex(availableFields, { key });
		if (sectionIndex === -1) {
			return;
		}
		availableFields[sectionIndex] = {
			...availableFields[sectionIndex],
			expanded: !availableFields[sectionIndex].expanded,
		};
		this.setState({
			availableFields,
		});
	};

	toggleExpandedFormSettings = (key, tabSetting = 'manageLayout', collapseOtherSections = false) => {
		const formSettings = [...this.state.formSettings];
		const sectionIndex = findIndex(formSettings, { key });
		if (sectionIndex === -1) {
			return;
		}
		formSettings[sectionIndex] = {
			...formSettings[sectionIndex],
		};

		if (isObject(formSettings[sectionIndex].expanded)) {
			formSettings[sectionIndex].expanded[tabSetting] = collapseOtherSections
				? true
				: !formSettings[sectionIndex].expanded[tabSetting];
		} else {
			formSettings[sectionIndex].expanded = !formSettings[sectionIndex].expanded;
		}

		this.setState({ formSettings });
	};

	renderTooltip = tTip => {
		return tTip && <i className="icon icon--tny icon--regular--info spc--top--tny" data-tooltip={tTip}></i>;
	};

	renderInvalidFileTypePopup = () => {
		const { invalidFileType } = this.state;

		return (
			<Modal
				isOpen={invalidFileType}
				onClose={() => this.setState({ invalidFileType: false })}
				shouldCloseOnOverlayClick={false}
				className="modal__content"
			>
				<div className="modal">
					<div className="modal__body">
						<div className="message message--warning fullwidth">File type is invalid.</div>
					</div>
				</div>
			</Modal>
		);
	};

	imageURLtoBase64 = async url => {
		try {
			const response = await fetch(url);
			const blob = await response.blob();

			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64String = reader.result.substring(reader.result.indexOf('64') + 3);
					resolve(base64String);
				};
				reader.onerror = reject;
				reader.readAsDataURL(blob);
			});
		} catch (err) {
			this.props.handleError(err);
		}
	};

	resizeImage = async file => {
		if (!file) return this.setState({ invalidFileType: true });
		let reader = new FileReader();
		const img = new Image();
		const canvas = document.createElement('canvas');

		return new Promise(resolve => {
			img.onload = () => {
				let width = img.width;
				let height = img.height;

				if (width > recomendedWidth) {
					const ratio = recomendedWidth / width;
					width = width * ratio;
					height = height * ratio;
				}

				canvas.width = width;
				canvas.height = height;
				canvas.getContext('2d').drawImage(img, 0, 0, width, height);
				const dataURL = canvas.toDataURL('image/jpeg');
				const blobBin = atob(dataURL.split(',')[1]);
				const array = [];

				for (let i = 0; i < blobBin.length; i++) {
					array.push(blobBin.charCodeAt(i));
				}

				const file = new Blob([new Uint8Array(array)], { type: 'image/png' });

				resolve([file]);
			};

			reader.onloadend = e => {
				img.src = e.target.result;
			};
			reader.readAsDataURL(file);
		});
	};

	removeLogo = async () => {
		await this.handleDataChange({ target: { name: 'logo', type: 'file', checked: false, value: '', files: [] } });
	};

	handleDrop = async acceptedFiles => {
		await this.handleDataChange({
			target: {
				name: 'logo',
				type: 'file',
				checked: false,
				value: '',
				files: await this.resizeImage(first(acceptedFiles)),
			},
		});
	};

	encodeImageFileAsURL = files => {
		var file = files[0];
		return new Promise(resolve => {
			let reader = new FileReader();
			// result will start with a string such as data:image/jpeg;base64, -- we want to strip that and return
			// only the base64 encoding portion
			reader.onloadend = event => resolve(event.target.result.substring(event.target.result.indexOf('base64,') + 7));

			reader.readAsDataURL(file);
		});
	};

	handleDataChange = async ({ target: { name, type, checked, value, files } }, saveUndoStep = true, hideTypes) => {
		let invalidName = false;
		let nameError = null;
		const [section, field, key] = split(name, '.');
		let newValue =
			type === 'checkbox' || type === 'button'
				? checked
				: type === 'file'
				? files && files.length
					? await this.encodeImageFileAsURL(files)
					: ''
				: value;

		if (includes(name, 'label')) {
			invalidName = size(value) > 50;
			if (invalidName) {
				nameError = 'Field labels cannot be longer than 50 characters';
			}
		}
		if (section === 'name') {
			invalidName = size(value) > 50;
			if (invalidName) {
				nameError = 'Payment site name cannot be longer than 50 characters';
			}
		}
		if (section === 'xCVV') {
			const firstSection = first(this.state.data.sections);
			if (firstSection) {
				await this.hoverField('xCVV', `${firstSection.key}.empty`, { [field]: newValue });
			}
			return;
		}
		const newState = {
			data: {
				...this.state.data,
			},
			nameError,
			invalidName,
			hasNameError: invalidName,
		};
		if (field) {
			const sections = [...newState.data.sections];
			newState.data.sections = sections;
			const sectionIndex = findIndex(sections, ({ key }) => key === section);
			if (sectionIndex === -1) {
				return;
			}
			const updatedSection = {
				...sections[sectionIndex],
			};
			sections[sectionIndex] = updatedSection;
			if (key) {
				const updatedFields = [...updatedSection.fields];
				updatedSection.fields = updatedFields;
				const fieldIndex = findIndex(updatedFields, ({ key }) => key === field);
				if (fieldIndex === -1) {
					return;
				}
				if (
					updatedFields[fieldIndex].isKioskAmountValue &&
					(!startsWith(newValue, '$') || newValue === '$' || newValue === '$0')
				) {
					newValue = '$';
					updatedFields[fieldIndex].invalidValue = true;
				} else {
					updatedFields[fieldIndex].invalidValue = invalidName;
				}
				updatedFields[fieldIndex] = {
					...updatedFields[fieldIndex],
					[key]: newValue,
				};
			} else {
				updatedSection[field] = newValue;
			}
		} else {
			newState.data[section] = newValue;
		}
		if (saveUndoStep) {
			newState.undoSteps = this.cloneCurrentStateAsUndoStep();
		}
		await this.setStateAsync(newState, () => {});
		await this.updateFieldSelection();

		if (key === 'inputType' && some(['radio', 'dropdown'], item => item === newValue)) {
			this.openOptionsModal(field, hideTypes, true)();
		}
	};
	handleAdditionalSettingsClear = (key, label) => {
		this.toggleRemoveSectionPopup(() => this.clearAdditionalSettings(key), label);
	};

	updateDataState = clonedData => {
		this.setState({ data: clonedData, undoSteps: this.cloneCurrentStateAsUndoStep() });
	};
	clearAdditionalSettings = key => {
		const clonedData = cloneDeep(this.state.data);
		clonedData[key] = '';
		this.updateDataState(clonedData);
	};

	handleAdditionalSettingsDataChange = (e, key) => {
		const clonedData = cloneDeep(this.state.data);
		clonedData[key] = e.target.value;
		this.updateDataState(clonedData);
	};

	handleFormSettingsDataChange = (
		sectionIndex,
		{ target: { name, type, checked, value, caretPositionBeforeTabPress } },
		isEnableInputCheckbox = false
	) => {
		const [key] = split(name, '.');
		const newValue = type === 'checkbox' || type === 'radio' ? checked : value;
		const formSettings = [...this.state.formSettings];
		const fields = formSettings[sectionIndex].fields;
		const [checkboxFieldKey, valueKey] = split(key, '-');
		let fieldKey = 'value';
		const isCardTypes = includes(cardTypes, key);
		const newState = {
			entireCardTypesAccepted: this.state.entireCardTypesAccepted,
		};

		const fieldIndex = findIndex(fields, {
			key: isEnableInputCheckbox ? checkboxFieldKey : key,
		});

		if (isEnableInputCheckbox) {
			fieldKey = valueKey;

			some(replaceFields, value => {
				if (endsWith(checkboxFieldKey, value)) {
					const fieldIndex = findIndex(formSettings[sectionIndex].fields, {
						key: replace(checkboxFieldKey, value, replaceFields[value]),
					});
					formSettings[sectionIndex].fields = [...fields];
					formSettings[sectionIndex].fields[fieldIndex] = {
						...fields[fieldIndex],
						value: '',
						checked: false,
						enableInput: false,
					};
				}
			});
		}

		if (fieldIndex === -1) {
			return;
		}

		formSettings[sectionIndex] = {
			...formSettings[sectionIndex],
			isEdit: true,
		};

		if (formSettings[sectionIndex].key === 'allowedCommands') {
			const hasAtLeastOneChecked =
				filter(
					formSettings[sectionIndex].fields,
					({ checked, key }) => checked && fields[fieldIndex].key !== key && key !== 'cash:sale'
				).length >= 1;

			if (!hasAtLeastOneChecked && !newValue && type === 'checkbox') {
				return;
			} else {
				formSettings[sectionIndex].fields[fieldIndex] = {
					...fields[fieldIndex],
					[fieldKey]: newValue,
				};
			}
		} else {
			formSettings[sectionIndex].fields[fieldIndex] = {
				...fields[fieldIndex],
				[fieldKey]: newValue,
			};
		}

		if (type === 'checkbox') {
			formSettings[sectionIndex].fields[fieldIndex].checked = newValue;
		}

		this.setState({ formSettings, undoSteps: this.cloneCurrentStateAsUndoStep() }, async () => {
			const hasEnableInputChecked = find(formSettings[sectionIndex].fields, { enableInput: true });
			const hadEnableInputChecked = find(fields, { enableInput: true });
			if (
				isEnableInputCheckbox &&
				(!hasEnableInputChecked || !hadEnableInputChecked) &&
				this.state.entireConvenienceFee
			) {
				this.defaultConvFeesToCustomFields(formSettings, sectionIndex);
			}
			if (startsWith(toLower(name), 'servicefee') && type === 'checkbox' && value === 'false') {
				await this.removeFormSetting('convenienceFee');
			}
			if (isCardTypes) {
				newState.entireCardTypesAccepted = some(
					formSettings[sectionIndex].fields,
					field => includes(cardTypes, field.key) && field.value
				);
				this.setState({ ...newState });
			}
			this.addOrRemoveReenterFields();
			this.validateFields();

			if (!isEmpty(caretPositionBeforeTabPress)) {
				const element = document.querySelector(`textarea[name="${name}"]`);

				if (element) {
					const { start, end } = caretPositionBeforeTabPress;
					element.setSelectionRange(start + 1, end + 1);
				}
			}
		});
	};

	defaultConvFeesToCustomFields = (formSettings, sectionIndex) => {
		let hasChanges = false;
		const previousConvenienceFeeCustomFields = [];
		const convenienceFees = formSettings[sectionIndex];
		if (convenienceFees) {
			const convFeeKeyIndex = findIndex(convenienceFees.fields, { key: 'CustomFieldForDescription' });
			const originalAmountKeyIndex = findIndex(convenienceFees.fields, { key: 'CustomFieldForOriginalAmount' });

			let convFeeKey = convenienceFees.fields[convFeeKeyIndex];
			let originalAmountKey = convenienceFees.fields[originalAmountKeyIndex];

			const isSomeInputEnabled = find(convenienceFees.fields, { enableInput: true });

			if (!isSomeInputEnabled) {
				const emptyOption = { label: '-', name: '' };
				previousConvenienceFeeCustomFields.push(clone(convFeeKey), clone(originalAmountKey));
				convFeeKey.value = emptyOption;
				originalAmountKey.value = emptyOption;
				hasChanges = true;
			} else {
				if (convFeeKey && !convFeeKey.value.name) {
					convFeeKey.value = {
						label: 'Custom09',
						name: 'xCustom09',
					};
					hasChanges = true;
				}
				if (originalAmountKey && !originalAmountKey.value.name) {
					originalAmountKey.value = {
						label: 'Custom10',
						name: 'xCustom10',
					};
					hasChanges = true;
				}
			}
		}
		this.setState({ formSettings }, async () => {
			if (hasChanges) {
				await this.addRemoveCustomConvenienceFieldsFromSections({ previousConvenienceFeeCustomFields });
			}
		});
	};

	handleFormSettingsSelectDataChange = async (sectionIndex, { name, label, key }) => {
		const formSettings = [...this.state.formSettings];
		const fields = formSettings[sectionIndex].fields;

		const fieldIndex = findIndex(fields, {
			key,
		});

		if (fieldIndex === -1) {
			return;
		}

		const newState = { formSettings, undoSteps: this.cloneCurrentStateAsUndoStep() };

		if (includes(['CustomFieldForOriginalAmount', 'CustomFieldForDescription'], key)) {
			await this.addRemoveCustomConvenienceFieldsFromSections({ fieldKey: key, name });
		}

		newState.formSettings[sectionIndex] = {
			...newState.formSettings[sectionIndex],
			fields: [...fields],
			isEdit: true,
		};
		newState.formSettings[sectionIndex].fields[fieldIndex] = {
			...fields[fieldIndex],
			value: { name, label },
			isConvFeeCustomField: includes(['CustomFieldForOriginalAmount', 'CustomFieldForDescription'], key),
		};

		this.setState(newState, () => {
			this.addOrRemoveReenterFields();
			this.validateFields();
		});
	};

	handleFormSettingsRadioChange = (sectionIndex, { target: { name, checked } }) => {
		const [key] = split(name, '.');
		const [radioFieldKey, value] = split(key, '-');
		const formSettings = [...this.state.formSettings];
		const fields = formSettings[sectionIndex].fields;

		const fieldIndex = findIndex(fields, {
			key: radioFieldKey,
		});

		if (fieldIndex === -1) {
			return;
		}

		formSettings[sectionIndex] = {
			...formSettings[sectionIndex],
			fields: [...fields],
			isEdit: true,
		};
		formSettings[sectionIndex].fields[fieldIndex] = {
			...fields[fieldIndex],
			value,
			checked,
		};

		this.setState({ formSettings, undoSteps: this.cloneCurrentStateAsUndoStep() }, () => {
			this.addOrRemoveReenterFields();
			this.validateFields();
		});
	};

	checkIfAllowedValue = (key, { value }) => {
		return endsWith(toLower(key), 'percent') ? value <= 100 : true;
	};

	toggleSelect = async (key, unselectOnly = false, requestNewFrame = false) => {
		const additionalData = {};
		const {
			data: { sections },
			newSectionId,
			formSettings,
		} = this.state;
		const sectionWithField = find(sections, ({ fields }) => some(fields, { key }));
		let shouldRemoveField = sectionWithField;
		const convenienceFeeCustomFields = filter(
			get(find(formSettings, { key: 'convenienceFee' }), 'fields', []),
			({ key }) => includes(['customfieldfordescription', 'customfieldfororiginalamount'], toLower(key))
		);
		const isCurrentConvFeeCustomField = some(convenienceFeeCustomFields, ({ value }) => value.name === key);
		const isMappedConvFeeCustomField = get(
			find(get(sectionWithField, 'fields'), { key }),
			'isConvFeeCustomField',
			false
		);

		if (!isMappedConvFeeCustomField && isCurrentConvFeeCustomField) {
			shouldRemoveField = false;
		}

		if (shouldRemoveField) {
			await this.removeEditField(sectionWithField.key, key);
		} else if (!unselectOnly) {
			if ((key === 'xRecurring' || key === 'customers_schedules') && !this.state.isGoPlus) {
				const newSection = cloneDeep(await this.addNewSection('Recurring', !this.state.isAdmin, false));
				if (newSection) {
					const newSectionKey = newSection.key;
					await this.hoverSection(
						newSectionKey,
						'empty',
						() => this.hoverField(key, `${newSectionKey}.empty`, {}, requestNewFrame),
						requestNewFrame
					);
				}
			} else {
				const firstSection = first(sections);
				if (!firstSection) {
					await this.hoverSection(
						newSectionId,
						'empty',
						() => this.hoverField(key, `${newSectionId}.empty`, {}, requestNewFrame),
						requestNewFrame
					);
					return;
				}
			}
			const isZipKey = includes(zipKeys, key);
			if (startsWith(key, 'bill city, state, zip') || isZipKey || startsWith(key, 'ship city, state, zip')) {
				let removeKey;
				if (isZipKey) {
					if (key === 'xBillState') {
						additionalData.inputType = 'dropdown';
					}
					additionalData.hideDropdownOptionPreview = true;
					additionalData.values = null;
					// If the key is one of the zipKeys, remove 'bill city, state, zip' or 'bill, city, state, zip required' if they exist
					const keysToRemove = ['bill city, state, zip', 'bill city, state, zip zip required'];
					for (const keyToRemove of keysToRemove) {
						const sectionWithField = find(sections, ({ fields }) => some(fields, { key: keyToRemove }));
						if (sectionWithField) {
							removeKey = keyToRemove;
							await this.removeEditField(sectionWithField.key, removeKey);
						}
					}
				} else {
					// If the key isn't one of the zipKeys, find and remove any fields in the sections that match the 'zipKeys'
					for (const zipKey of zipKeys) {
						const sectionWithField = find(sections, ({ fields }) => some(fields, { key: zipKey }));
						if (sectionWithField) {
							await this.removeEditField(sectionWithField.key, zipKey);
						}
					}
					removeKey = endsWith(key, 'zip required') ? replace(key, ' zip required', '') : `${key} zip required`;
					const sectionWithField = find(sections, ({ fields }) => some(fields, { key: removeKey }));
					if (sectionWithField) {
						await this.hoverField(key, `${sectionWithField.key}.empty`);
						return;
					}
				}
			}

			await this.hoverField(key, `${this.state.addFieldKey}.empty`, additionalData, requestNewFrame);
		}
	};

	shouldValidateAmount = () => {
		const { formSettings } = this.state;
		const allowedCommands = find(formSettings, setting => setting.key === 'allowedCommands');
		if (allowedCommands) {
			const trxTypesExceptSave = filter(
				allowedCommands.fields,
				field => field.key !== 'cc:save' && (field.value || field.value === 'true' || field.value === 'Yes')
			);
			return !isEmpty(trxTypesExceptSave);
		}
		return false;
	};
	isInvalidBody = (type, isRequired, value) => {
		return (type === 'richtext' || type === 'tac_body') && isRequired && (!value || value === '<p><br></p>');
	};

	validateFields = () => {
		const { formSettings, availableFields, entireAcceptACH } = this.state;
		const errorMessages = [];
		let hasOnlyGeneralSettingsErrors = true;
		let mappedFormSettings = [];
		let hasInternalSettingsError = false;

		each(availableFields, ({ fields, label }) => {
			each(fields, ({ key, name, isRequired, isSelected, customErrorMessage }) => {
				if (invokeIfFunction(isRequired) && !isSelected) {
					if (key === 'xAmount') {
						if (this.shouldValidateAmount()) {
							errorMessages.push('Your PaymentSITE is missing an amount field!');
							hasOnlyGeneralSettingsErrors = false;
						}
					} else {
						errorMessages.push(customErrorMessage || `${label} ${name} is required`);
						hasOnlyGeneralSettingsErrors = false;
					}
				}
			});
		});

		each(formSettings, ({ fields, label }, sectionIndex) => {
			if (toLower(label) == 'payment methods' && !entireAcceptACH) {
				const hasOptionSelected = some(fields, ({ value }) => value == 1);
				!hasOptionSelected && errorMessages.push(`At least one card type needs to be selected`);
			}
			each(fields, ({ value, name, isRequired, checked }) => {
				if (toLower(name) === 'body' && size(value) > 4000) {
					errorMessages.push(
						`${label ||
							'Tab'} body exceeds the maximum allowed length of 3500 characters, please contact customer support in order to add the text manually.`
					);
				}
				const isInvalidConvenienceCutom =
					isObject(value) && !get(value, 'name', false) && this.conveniencFieldsChecked(formSettings);
				const lowerCasedName = toLower(name);
				if (invokeIfFunction(isRequired) && (!value || value === '<p><br></p>' || isInvalidConvenienceCutom)) {
					errorMessages.push(`${name} is required`);
					if (!value && name === 'tab_title') {
						hasOnlyGeneralSettingsErrors = false;
					}
					mappedFormSettings = [...formSettings];
					mappedFormSettings[sectionIndex] = {
						...formSettings[sectionIndex],
						expanded: {
							...formSettings[sectionIndex].expanded,
							[tabs.generalSettings]: true,
						},
					};
				}

				if (
					checked &&
					includes(
						[
							'cc percent',
							'ach percent',
							'cc set amount',
							'ach set amount',
							'service fee cc percent',
							'service fee ach percent',
							'service fee ach set amount',
						],
						lowerCasedName
					) &&
					(this.state.entireConvenienceFee || this.isServiceFeeEnabled()) &&
					(!value || value <= 0)
				) {
					if (toLower(label) === 'service fee') {
						errorMessages.push(`${name} must be greater than 0`);
						hasOnlyGeneralSettingsErrors = false;
						hasInternalSettingsError = true;
					} else {
						errorMessages.push(`${label} ${name} must be greater than 0`);
					}
				}
			});
		});
		const hasErrors = !isEmpty(errorMessages);
		const newState = { errorMessages };

		if (!isEmpty(mappedFormSettings)) {
			newState.formSettings = mappedFormSettings;
		}

		if (hasErrors && hasOnlyGeneralSettingsErrors && !this.inlineInputRef.current) {
			newState.selectedTab = tabs.generalSettings;
		}
		if (hasErrors && hasInternalSettingsError) {
			newState.selectedTab = tabs.internalSettings;
		}

		this.setState(newState);
		return hasErrors;
	};

	handleSort = values => {
		const numericalValues = [];
		const stringValues = [];
		each(values, option => {
			const isNumber = !isNaN(toNumber(replace(toLower(option.value), /[^A-Z0-9]+/gi, '')));
			if (isNumber) {
				numericalValues.push(option);
			} else {
				stringValues.push(option);
			}
		});
		return [
			...orderBy(numericalValues, numOption => toNumber(replace(toLower(numOption.value), /[^A-Z0-9]+/gi, '')), 'asc'),
			...orderBy(stringValues, stringOption => replace(toLower(stringOption.value), /[^A-Z0-9]+/gi, ''), 'asc'),
		];
	};
	disableDragKioskAmount = ({ sourceId, sectionKey }) => {
		if (!sectionKey) {
			return false;
		}

		const {
			data: { sections },
		} = this.state;

		const section = find(sections, { key: sectionKey });
		const draggedItem = find(flatMap(sections, 'fields'), { key: sourceId });

		const isSectionAmount = get(section, 'isAmountSection', false);
		const isDraggedItemDisableOutsideDrag = get(draggedItem, 'disableOutsideDrag', false);

		const shouldDisableDrag =
			(isSectionAmount && !isDraggedItemDisableOutsideDrag) || (isDraggedItemDisableOutsideDrag && !isSectionAmount);

		return shouldDisableDrag;
	};

	handleSave = async (revert = false) => {
		await this.checkConvenienceEnabled();
		const { handleError, showLoader, makePendingRequest, isLoading } = this.props;
		const { formSettings, undoSteps, customDisplayLabels } = this.state;
		const data = { ...this.state.data };
		const sections = filter(cloneDeep(data.sections), section => !section.isKioskPlaceholder);

		if (this.xAmountField) {
			const sectionIndex = findIndex(sections, { isAmountSection: true });
			let cvvField; // the CVV field is extracted from the amounts section
			let sectionFields = cloneDeep(sections[sectionIndex].fields);
			let recurringFields = [];
			this.xAmountField.values = [];
			sectionFields.forEach(field => {
				const { key } = field;
				if (key === 'xCVV') {
					cvvField = { ...field };
				} else if (toLower(key) === 'xrecurring' || toLower(key) === 'customers_schedules') {
					recurringFields.unshift(field);
				} else {
					this.xAmountField.values = field.values;
				}
			});
			sectionFields = [this.xAmountField, ...recurringFields];
			if (cvvField) {
				sectionFields.unshift(cvvField);
			}
			sections[sectionIndex].fields = sectionFields;
			data.sections = sections;
		}
		const changedFormSettings = filter(formSettings, ({ isEdit }) => isEdit);
		try {
			if (isLoading || this.validateFields()) {
				return;
			}
			this.xAmountField = null;
			showLoader(false);
			const response = await makePendingRequest(paymentSiteService.save(data, changedFormSettings), actionKeys.SAVE);
			const newState = { showRevert: !revert };

			if (newState.showRevert) {
				newState.undoSteps = clone(undoSteps);
				each(newState.undoSteps, item => (item.showRevert = true));
			}

			this.checkIfShouldDisplayFormSettings(this.state.formSettings, newState);
			this.setState(newState);
			await this.loadPaymentSite(customDisplayLabels, false);
			if (this.notificationRef.current) {
				this.notificationRef.current.addNotification({
					message: (
						<Fragment>
							<h4 className="spc--bottom--lrg">PaymentSITE {revert ? 'reverted' : 'published'}</h4>
							<p className="flex--primary flex--gap--tny type--p2">
								<a
									className="btn btn--link"
									target="_blank"
									rel="noopener noreferrer"
									href={`${paymentSiteUrl}${data.path}`}
								>
									Click here
								</a>{' '}
								to view your changes.
							</p>
						</Fragment>
					),
					success: true,
					ref: response.ref,
				});
			}
			this.handleRemoveTempId(this.state.data);
		} catch (e) {
			showLoader(false);

			const notification = handleError(e, { delayMessage: true });
			if (e.response && e.response.Msg && toLower(e.response.Msg) === 'refresh required') {
				notification.message = 'Data is out of date. Please reload and try again.';
			}

			notification.show();
		}
	};
	handleRemoveTempId = data => {
		const sections = { ...data.sections };
		each(sections, s => {
			s.expanded = true;
			if (s.tempId) {
				s.tempId = null;
				s.id = null;
			}
		});

		this.setState({
			data: { ...data, ...sections },
		});
	};
	handleRemoveLogo = () => {
		const state = {
			modal: {
				name: modalNames.confirmAction,
				data: {
					question: 'Are you sure you want to remove your logo?',
					onConfirm: this.removeLogo,
				},
			},
		};
		this.setState(state);
	};

	checkConvenienceEnabled = async () => {
		const { formSettings, entireConvenienceFee } = this.state;
		if (entireConvenienceFee) {
			if (!this.conveniencFieldsChecked(formSettings)) {
				await this.removeFormSetting('convenienceFee');
			}
		}
	};
	conveniencFieldsChecked = formSettings =>
		some(
			filter(get(find(formSettings, ({ key }) => key === 'convenienceFee'), 'fields', {}), field =>
				includes(['ConvFee_ACHAmount', 'ConvFee_CCAmount', 'ConvFee_ACHPercent', 'ConvFee_CCPercent'], field.key)
			),
			f => f.checked
		);

	handlePublish = async () => {
		this.props.handleBlockChange(false);
		const state = {
			modal: {
				name: modalNames.confirmAction,
				data: {
					question: `Are you sure you want to publish these changes?`,
					onConfirm: async () => await this.handleSave(false),
				},
			},
		};
		this.setState(state);
	};

	handleRevert = async () => {
		const { originalData } = this.state;
		const newState = {
			data: cloneDeep(originalData),
			formSettings: this.mapFormSettingsToState(originalData),
			undoSteps: [],
		};

		this.revertFormSettings(newState);

		await this.setStateAsync(newState);
		await this.handleSave(true);
	};

	handleUndo = () => {
		const { undoSteps } = this.state;
		this.setState({ ...undoSteps[undoSteps.length - 1], undoSteps: this.state.undoSteps.slice(0, -1) });
	};

	shouldBlockExit(steps = this.state.undoSteps) {
		if (isEmpty(steps)) {
			this.props.handleBlockChange(false);
		}
	}

	cloneCurrentStateAsUndoStep = () => {
		this.props.handleBlockChange(true);
		const {
			undoSteps,
			originalData,
			customDisplayLabels,
			removeSectionAction,
			removeSectionName,
			...rest
		} = this.state;
		const clonedState = cloneDeep(rest);
		clonedState.isOpenRemoveSectionPopup = false;
		clonedState.isOpenRemove3dSecureFieldPopup = false;
		clonedState.data.isEdit = false;
		each(clonedState.data.sections, (_, index) => {
			clonedState.data.sections[index].isEdit = false;
		});
		this.shouldBlockExit([...undoSteps, clonedState]);
		return [...undoSteps, clonedState];
	};

	revertFormSettings = newState => {
		each(newState.formSettings, item => {
			item.isEdit = false;
		});
		this.checkIfShouldDisplayFormSettings(newState.formSettings, newState);
	};

	handleChange = async ({ target: { name, type, value, checked } }) => {
		const newValue = type === 'checkbox' ? checked : value;
		await this.setStateAsync({
			[name]: newValue,
		});
	};

	getCustomSelectedKey = newAvailableFields => {
		let allSelected = false;
		const customFields = find(newAvailableFields, field => field.key === 'custom').fields;
		const allFields = {
			xCustom03: true,
			xCustom04: true,
			xCustom05: true,
		};
		each(customFields, field => {
			if (allFields[field.key] && field.isSelected) {
				allSelected = true;
			}
		});
		return allSelected;
	};
	updateFieldSelection = async (customFieldIndex, entireCustom) => {
		const {
			availableFields,
			formSettings,
			data: { sections },
		} = this.state;
		const newAvailableFields = [...availableFields];
		const newState = {
			availableFields: newAvailableFields,
		};
		let anySectionChanged = false;
		each(newAvailableFields, ({ fields, key }, sectionIndex) => {
			let areAllSelected = true;
			each(fields, (field, fieldIndex) => {
				const isSelected = some(sections, section =>
					some(section.fields, ({ key }) => {
						if ((field.key === 'xAmount' && this.state.isKioskTheme) || startsWith(field.key, 'city')) {
							return true;
						}
						return key === field.key;
					})
				);
				if (!isSelected) {
					areAllSelected = false;
				}
				if (isSelected !== field.isSelected) {
					anySectionChanged = true;
					newAvailableFields[sectionIndex] = {
						...newAvailableFields[sectionIndex],
						fields: [...newAvailableFields[sectionIndex].fields],
					};
					newAvailableFields[sectionIndex].fields[fieldIndex] = {
						...newAvailableFields[sectionIndex].fields[fieldIndex],
						isSelected,
					};
				}
			});
			const entireSectionKey = `entire${upperFirst(key)}`;
			if (this.state[entireSectionKey] !== areAllSelected) {
				newState[entireSectionKey] = areAllSelected;
			}

			if (customFieldIndex === sectionIndex && entireCustom !== null) {
				newState[entireSectionKey] = entireCustom;
			}

			if (includes(['billing', 'shipping'], key)) {
				const section = find(newAvailableFields, { key });
				if (section) {
					if (
						every(
							filter(
								section.fields,
								({ key }) =>
									!endsWith(key, 'zip required') && !endsWith(key, 'city, state, zip') && key !== 'same_as_billing'
							),
							({ isSelected }) => isSelected
						) &&
						some(
							filter(
								section.fields,
								({ key }) =>
									endsWith(key, 'zip required') || endsWith(key, 'city, state, zip') || key === 'same_as_billing'
							),
							({ isSelected }) => isSelected
						)
					) {
						newState[entireSectionKey] = true;
					}
				}
			}
			if (key === 'custom') {
				const customSelectedKeys = this.getCustomSelectedKey(newAvailableFields);
				newState[entireSectionKey] = customSelectedKeys;
			}
		});
		if (anySectionChanged) {
			this.checkIfShouldDisplayFormSettings(formSettings, newState);
			await this.setStateAsync(newState);
			this.validateFields();
		}
	};

	setDefaultCheckboxValue = (index, item) => {
		item.fields = [...item.fields];
		item.fields[index] = {
			...item.fields[index],
			value: '1',
			checked: true,
		};
	};

	addEntireFormSetting = (key, isInternalFormSetting, validateFields) => {
		const newState = {
			[`entire${replace(startCase(key), /\s/g, '')}`]: true,
			formSettings: [...this.state.formSettings],
		};
		const item = find(newState.formSettings, ({ key: settingKey }) => toLower(settingKey) === toLower(key));
		item.isEdit = true;
		if (!isInternalFormSetting) {
			item.expanded = transform(tabs, (acc, tab) => {
				return (acc[tab] = false);
			});
		}
		const displayFieldIndex = findIndex(item.fields, ({ key: fieldKey }) => toLower(fieldKey) === toLower(key));
		if (displayFieldIndex > -1) {
			this.setDefaultCheckboxValue(displayFieldIndex, item);
		}
		if (toLower(key) === 'cardtypesaccepted') {
			each(this.cardTypesAccepted.fields, (_card, i) => {
				this.setDefaultCheckboxValue(i, item);
			});
		}
		if (toLower(key) === 'allowedcommands') {
			const ccSaleIndex = findIndex(item.fields, { key: 'cc:sale' });
			this.setDefaultCheckboxValue(ccSaleIndex, item);
		}
		if (toLower(key) === 'receiptoptions') {
			const custReceiptIndex = findIndex(item.fields, { key: 'xCustReceipt' });
			this.setDefaultCheckboxValue(custReceiptIndex, item);
		}

		newState.undoSteps = this.cloneCurrentStateAsUndoStep();
		this.setState(newState, () => {
			this.toggleExpandedFormSettings(key, 'generalSettings');
			this.addOrRemoveReenterFields();
			if (validateFields) {
				this.validateFields();
			}
		});
	};

	addEntireSection = async sectionName => {
		const {
			availableFields,
			data: { sections: oldSections },
		} = this.state;
		let entireCustom = null;
		const availableSectionIndex = findIndex(availableFields, ({ key }) => key === sectionName);
		const availableSection = availableFields[availableSectionIndex];
		const section = this.mapSection({
			key: this.incrementer().toString(),
			tempId: true,
			id: this.incrementer().toString(),
			label: invokeIfFunction(availableSection.label),
			fields: [],
			params: { version: '1.1', box_width: '0', box_column: '1' },
			expanded: true,
			focusSection: true,
		});

		const newSections = [...oldSections, section];
		if (newSections.length > 1) {
			newSections[newSections.length - 2] = {
				...newSections[newSections.length - 2],
			};
		}
		each(
			availableSection.fields,
			({
				isSelected,
				key,
				name: label,
				inputType,
				showInReceipt,
				showInPrintReceipt,
				isRequired,
				value,
				customLabel,
				internalSetting,
				hideDropdownOptionPreview,
			}) => {
				if (key === 'xAmount' && some(oldSections, ({ isAmountSection }) => isAmountSection)) return;
				const fieldToAdd = this.mapField(section.key, {
					key,
					label,
					isRequired,
					inputType,
					showInPrintReceipt,
					showInReceipt,
					value,
					customLabel,
					internalSetting,
					hideDropdownOptionPreview,
				});
				const sectionIndex = findIndex(newSections, ({ fields }) =>
					some(fields, ({ key: fieldKey }) => fieldKey === key)
				);
				if (isSelected && sectionIndex !== -1) {
					const newSection = {
						...newSections[sectionIndex],
						fields: [...newSections[sectionIndex].fields],
					};
					newSections[sectionIndex] = newSection;
					const fieldIndex = findIndex(newSection.fields, ({ key: fieldKey }) => fieldKey === key);
					fieldToAdd.isRequired = isFunction(newSection.fields[fieldIndex].isRequired)
						? false
						: newSection.fields[fieldIndex].isRequired;
					fieldToAdd.label = newSection.fields[fieldIndex].label;
					fieldToAdd.inputType = newSection.fields[fieldIndex].inputType;
					fieldToAdd.values = newSection.fields[fieldIndex].values;
					fieldToAdd.value = newSection.fields[fieldIndex].value;
					newSection.fields.splice(fieldIndex, 1);
				}

				if (
					endsWith(key, 'city, state, zip zip required') ||
					key === 'same_as_billing' ||
					key === 'xBillCity' ||
					key === 'xBillState' ||
					key === 'xBillZip'
				) {
					return;
				}

				if (sectionName === 'custom') {
					if (
						!isSelected &&
						(fieldToAdd.key === 'xCustom03' || fieldToAdd.key === 'xCustom04' || fieldToAdd.key === 'xCustom05')
					) {
						entireCustom = true;
						section.fields.push(fieldToAdd);
					}
				} else {
					section.fields.push(fieldToAdd);
				}
			}
		);
		await this.setStateAsync({
			data: {
				...this.state.data,
				sections: newSections,
			},
			undoSteps: this.cloneCurrentStateAsUndoStep(),
		});
		await this.updateFieldSelection(availableSectionIndex, entireCustom);
		if (this.sectionRef) this.sectionRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
	};

	sectionSelector = ({ focusSection }, elem) => {
		if (focusSection) this.sectionRef = elem;
	};

	openOptionsModal = (optionsKey, hideTypes, isTypeSwitch) => () => {
		// isTypeSwitch will be true when an input is changed from text to radio/dropdown
		if (includes(zipKeys, optionsKey)) return;
		const { data } = this.state;
		const sectionId = this.getOptionsSectionId(data, optionsKey);
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId, optionsKey);
		const newData = { ...data };

		if (sectionIndex > -1 && fieldIndex > -1) {
			if (isEmpty(newData.sections[sectionIndex].fields[fieldIndex].values)) {
				newData.sections[sectionIndex].fields[fieldIndex].values = [{ key: '', value: '' }];
			}
			// disable dragging of expanded fields
			newData.sections[sectionIndex].fields[fieldIndex].disableDrag = optionsKey !== this.state.isOpenOptionsModal;
			newData.sections[sectionIndex].disableDrag = optionsKey !== this.state.isOpenOptionsModal;
		}

		this.setState({
			data: newData,
			isOpenOptionsModal: optionsKey === this.state.isOpenOptionsModal ? '' : optionsKey,
			isTypeSwitch,
			optionsKey,
			fieldOptionsBeforeSave: cloneDeep(data.sections[sectionIndex].fields[fieldIndex].values),
			fieldWithOptionsMethods: { hideTypes },
		});
	};

	handleSortOptionsAlphabetically = (sectionId, optionsKey, values) => {
		const sections = [...this.state.data.sections];
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId, optionsKey);
		const fields = [...sections[sectionIndex].fields];
		const newState = {
			data: { ...this.state.data },
			sortOptionsAlphabetically: !this.state.sortOptionsAlphabetically,
		};
		let newValues = compact(
			map(values, ({ value, key }) => {
				if (trim(value) || trim(key)) {
					return { value: trim(value), key: trim(key) };
				}
			})
		);
		if (!this.state.sortOptionsAlphabetically) {
			newValues = this.handleSort(newValues);
		}
		newState.data.sections = [...sections];
		newState.data.sections[sectionIndex].fields = [...fields];
		newState.data.sections[sectionIndex].fields[fieldIndex].values = newValues;

		this.setState(newState);
	};

	getSectionAndFieldIndexes = (sectionId, optionsKey = this.state.optionsKey) => {
		const { data } = this.state;
		const sectionIndex = findIndex(data.sections, ({ id, key }) => {
			if (sectionId === '') {
				return id === undefined;
			} else {
				return id === sectionId || key === sectionId;
			}
		});

		if (sectionIndex < 0) {
			return;
		}

		const fieldIndex = findIndex(data.sections[sectionIndex].fields, ({ key }) => key === optionsKey);

		if (fieldIndex < 0) {
			return;
		}

		return { sectionIndex, fieldIndex };
	};

	closeOptionsModal = sectionId => () => {
		const { data, fieldOptionsBeforeSave } = this.state;
		const newState = {
			data,
			fieldOptionsBeforeSave: [{ value: '', key: '' }],
			fieldWithOptionsMethods: null,
			isExpandedOptionsModalView: false,
		};
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);

		newState.data.sections[sectionIndex].fields[fieldIndex].values = fieldOptionsBeforeSave;

		this.setState(newState);
		this.closeModal();
	};
	closeModal = () =>
		this.setState({ isOpenOptionsModal: false, isFieldOptionsDirty: false, isExpandedOptionsModalView: false });

	onCloseOptionsModal = sectionId => () => {
		const { isTypeSwitch } = this.state;
		const { emptyValues, isSelect } = this.getRenderOptionsProperties();
		if (this.state.isFieldOptionsDirty && (!emptyValues.reportingValue && !emptyValues.displayValue) && !isSelect) {
			this.openConfirmationPopup(
				'Are you sure you want to leave without saving?',
				isTypeSwitch ? this.handleUndo : this.closeOptionsModal(sectionId)
			);
		} else {
			this.handleUndo();
			this.closeModal();
		}
	};

	openConfirmationPopup = (question, onConfirm) =>
		this.setState({
			modal: {
				name: modalNames.confirmAction,
				data: { question, onConfirm },
			},
		});

	addFieldOption = (sectionId, isOther) => {
		const { data } = this.state;
		const newState = { data: { ...data, sections: [...data.sections] }, isFieldOptionsDirty: true };
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);
		const keyVal = isOther ? { key: 'Other', value: 'Other' } : { key: '', value: '' };
		if (!newState.data.sections[sectionIndex].fields[fieldIndex].values) {
			newState.data.sections[sectionIndex].fields[fieldIndex].values = [];
		}

		newState.data.sections[sectionIndex].fields[fieldIndex].values = [
			...newState.data.sections[sectionIndex].fields[fieldIndex].values,
			keyVal,
		];

		this.setState(newState);
	};
	closeRemove3dSecureFieldPopup = () => {
		this.setState({ isOpenRemove3dSecureFieldPopup: false });
	};
	getComponentCheckboxOnChange = (sectionIndex, e, key, checked) => {
		const clonedEvent = cloneDeep(e);
		if (includes(toLower(key), 'servicefee')) {
			return this.onBeforeServiceFeeChange(sectionIndex, clonedEvent, checked);
		}

		return this.handleFormSettingsDataChange(sectionIndex, e, true);
	};

	onBeforeServiceFeeChange = (sectionIndex, e) => {
		const {
			target: { name, type, caretPositionBeforeTabPress, value, checked },
		} = e;
		if (this.state.entireConvenienceFee) {
			this.toggleRemoveSectionPopup(
				() =>
					this.handleFormSettingsDataChange(
						sectionIndex,
						{
							target: {
								type,
								caretPositionBeforeTabPress,
								name,
								value,
								checked,
							},
						},
						true
					),
				'Electronic Transfer Fee',
				'Please note, Electronic Transfer Fee will be removed when enabling Service Fee.'
			);
		} else {
			this.handleFormSettingsDataChange(sectionIndex, e, true);
		}
	};

	onBeforeRemoveFieldOption = (sectionKey, fieldKey) => {
		if (this.state.isKioskTheme && fieldKey === 'customers_schedules')
			return this.removeSection(find(this.state.data.sections, section => section.label === 'Recurring').key);
		if (this.state.isKioskTheme && fieldKey === 'Other') return;
		const { is3dSecureEnabled } = this.state;
		const keys = split(fieldKey, ',');
		if ((includes(required3dSecureFields, fieldKey) || head(keys) == 'bill city') && is3dSecureEnabled) {
			return this.setState({
				isOpenRemove3dSecureFieldPopup: true,
				keyToRemove: fieldKey,
				sectionKeyToRemove: sectionKey,
			});
		}
		(async () => await this.removeEditField(sectionKey, fieldKey))();
	};

	removeFieldOption = (sectionId, index) => {
		const { data } = this.state;
		const newState = { data: { ...data }, isFieldOptionsDirty: true };
		const sections = [...data.sections];
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);
		const fields = [...sections[sectionIndex].fields];

		if (fields[fieldIndex].values.length === 1) {
			fields[fieldIndex].values = [{ key: '', value: '' }];
		} else {
			fields[fieldIndex].values = filter(data.sections[sectionIndex].fields[fieldIndex].values, (_, i) => {
				if (index !== undefined) {
					return i !== index;
				} else {
					return _.key !== 'Other';
				}
			});
		}
		newState.data.sections = [...sections];
		newState.data.sections[sectionIndex].fields = [...fields];

		this.setState(newState);
	};

	handleExistingOptionChange = ({ target: { name, value } }) => {
		const { data, isExpandedOptionsModalView, optionsKey } = this.state;
		const newState = { data: { ...data }, isFieldOptionsDirty: true };
		const sections = [...data.sections];
		const [sectionId, fieldKey, index, key] = split(name, '.');
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);
		const isOther = sections[sectionIndex].fields[fieldIndex][fieldKey][index].key === 'Other';
		const fields = [...sections[sectionIndex].fields];
		fields[fieldIndex][fieldKey][index][key] = value;
		fields[fieldIndex][fieldKey][index].isDirty = true;

		if (!isExpandedOptionsModalView && toLower(optionsKey) !== 'xamount' && !isOther) {
			fields[fieldIndex][fieldKey][index].key = value;
		}
		sections[sectionIndex].fields = [...fields];
		newState.data.sections = [...sections];
		this.setState(newState);
	};
	handleOtherChange = ({ target: { name, value } }) => {
		const { data } = this.state;
		const newState = { data, isFieldOptionsDirty: true };
		const [sectionId, fieldKey, index, key] = split(name, '.');
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);

		const option = newState.data.sections[sectionIndex].fields[fieldIndex][fieldKey][index];

		option[key] = value;
		option.isDirty = true;

		this.setState(newState);
	};

	handleAmountChange = ({ formattedValue, floatValue }, { target: { name } }) => {
		const { data } = this.state;
		const newState = { data: { ...data }, isFieldOptionsDirty: true };
		const sections = [...data.sections];
		const [sectionId, fieldKey, index, key] = split(name, '.');
		const { sectionIndex, fieldIndex } = this.getSectionAndFieldIndexes(sectionId);
		const fields = [...sections[sectionIndex].fields];
		const option = fields[fieldIndex][fieldKey][index];

		option[key] = floatValue;
		option.isDirty = true;
		option.value = formattedValue;
		sections[sectionIndex].fields = [...fields];
		newState.data.sections = [...sections];
		this.setState(newState);
	};

	getInvalidClassName = isInvalid => {
		return isInvalid ? 'is-invalid' : '';
	};

	addOtherOption = sectionId => {
		const isAllOther = every(this.fieldOptions, option => option.key === 'Other');

		if (isAllOther) {
			this.removeFieldOption(sectionId);
			this.addFieldOption(sectionId);
		} else {
			const isOther = some(this.fieldOptions, option => option.key === 'Other');
			if (!isOther) {
				this.addFieldOption(sectionId, true);
			} else {
				this.removeFieldOption(sectionId);
			}
		}
	};

	getOptionsSectionId = (data, optionsKey) => {
		const sectionWithOption = find(data.sections, ({ fields }) => some(fields, ({ key }) => key === optionsKey));
		const sectionId = get(sectionWithOption, 'id', '') || get(sectionWithOption, 'key', '');
		return sectionId;
	};
	getRenderOptionsProperties = (_, isPublish) => {
		const state = this.state;
		const data = state ? state.data : null;
		const optionsKey = state ? state.optionsKey : null;

		if (!isPublish && (!data || !optionsKey)) {
			return null; // Handle the case where data or optionsKey is null
		}

		const sectionId = this.getOptionsSectionId(data, optionsKey);
		const options = this.fieldOptions;
		const isAmount = toLower(optionsKey) === 'xamount';
		const inputType = this.getInputType(data, optionsKey);
		const isSelect = inputType === 'radio' || inputType === 'select';
		const emptyValues = this.getEmptyValues(options, sectionId);
		const allValuesEmpty = sectionId && this.areAllValuesEmpty(options);
		const isOther = this.hasOtherOption(options);
		const hasDuplicateValues = sectionId && this.hasDuplicateValues(options);
		const invalidOther = this.getIsInvalidOther(options, sectionId);
		const invalidAmount = this.getIsInvalidAmount(options, isAmount);

		if (isPublish) {
			return (
				allValuesEmpty ||
				emptyValues.reportingValue ||
				emptyValues.displayValue ||
				hasDuplicateValues ||
				invalidOther.isInvalid ||
				invalidAmount.isInvalid
			);
		}

		return {
			sectionId,
			emptyValues,
			isOther,
			isAmount,
			hasDuplicateValues,
			inputType,
			isSelect,
			allValuesEmpty,
			invalidOther,
			invalidAmount,
		};
	};

	getInputType = (data, optionsKey) => {
		if (!data || !optionsKey) {
			return null;
		}
		const section = find(get(data, 'sections', []), ({ fields }) => some(fields, ({ key }) => key === optionsKey));
		if (!section) {
			return null;
		}
		const field = find(section.fields, ({ key }) => key === optionsKey);
		if (!field) {
			return null;
		}

		return field.inputType;
	};

	getEmptyValues = (options, sectionId) => {
		let emptyValues = {
			reportingValue: false,
			displayValue: false,
			isAmount: this.state.optionsKey === 'xAmount',
		};
		if (!sectionId) return emptyValues;
		some(options, option => {
			emptyValues.reportingValue = emptyValues.reportingValue || (!option.key && typeof option.key !== 'number');
			emptyValues.displayValue = emptyValues.displayValue || (!option.value && typeof option.value !== 'number');
		});

		return emptyValues;
	};

	areAllValuesEmpty = options => {
		return every(options, option => option.value === '');
	};

	hasOtherOption = options => {
		return !!find(options, option => option.key === 'Other');
	};

	hasDuplicateValues = options => {
		return some(options, ({ value, key }, index) => {
			const existingOptions = options.filter(
				(item, idx) => trim(item.value) === trim(value) || (trim(item.key) === trim(key) && idx !== index)
			);
			return existingOptions.length > 1;
		});
	};
	getIsInvalidAmount = (options, isAmount) => {
		if (!isAmount) return { isInvalid: false, isAmount };
		const isInvalid = some(options, ({ value }) => {
			return some(['$', '0', '$0'], v => v === value);
		});

		return { isInvalid, isAmount };
	};
	getIsInvalidOther = (options, sectionId) => {
		if (!sectionId) return { isInvalid: false };

		const isInvalid = some(options, ({ value, key }) => {
			const otherIndexValue = findIndex(options, option => option.value === 'Other');
			const otherIndexKey = findIndex(options, option => option.key === 'Other');

			return (
				(toLower(value) === 'other' &&
					otherIndexValue !== findIndex(options, option => toLower(option.value) === 'other')) ||
				(toLower(key) === 'other' && otherIndexKey !== findIndex(options, option => toLower(option.key) === 'other'))
			);
		});

		return { isInvalid };
	};

	renderOptions = () => {
		const { data, optionsKey, sortOptionsAlphabetically } = this.state;
		const {
			sectionId,
			emptyValues,
			isOther,
			hasDuplicateValues,
			invalidOther,
			allValuesEmpty,
			invalidAmount,
		} = this.getRenderOptionsProperties();
		return (
			<PaymentSiteFieldOptions
				options={this.fieldOptions}
				data={data}
				optionsKey={optionsKey}
				sectionId={sectionId}
				hasDuplicateValues={hasDuplicateValues}
				invalidOther={invalidOther}
				invalidAmount={invalidAmount}
				emptyValues={emptyValues}
				isOther={isOther}
				fieldOptions={this.fieldOptions}
				onCloseOptionsModal={this.onCloseOptionsModal}
				addFieldOption={this.addFieldOption}
				addOtherOption={this.addOtherOption}
				merchantCurrencyCode={this.merchantCurrencyCode}
				handleAmountChange={this.handleAmountChange}
				handleOtherChange={this.handleOtherChange}
				handleExistingOptionChange={this.handleExistingOptionChange}
				handleSortOptionsAlphabetically={this.handleSortOptionsAlphabetically}
				removeFieldOption={this.removeFieldOption}
				moveOption={this.moveOption}
				getInvalidClassName={this.getInvalidClassName}
				validateFields={this.validateFields}
				sortOptionsAlphabetically={sortOptionsAlphabetically}
				allValuesEmpty={allValuesEmpty}
			/>
		);
	};

	toggleRemoveSectionPopup = (removeSectionAction, removeSectionName, customRemoveSectionMessage, removeSectionKey) => {
		this.setState({
			isOpenRemoveSectionPopup: !this.state.isOpenRemoveSectionPopup,
			removeSectionAction,
			removeSectionName,
			customRemoveSectionMessage,
			removeSectionKey,
		});
	};

	closeRemoveSectionPopup = () => {
		this.setState({ isOpenRemoveSectionPopup: false });
	};

	closeActionsPopup = () => {
		this.setState({ modal: {} });
	};

	renderPaymentSections() {
		const { paymentSections, data, entireAcceptACH } = this.state;
		let cvvField;
		const cvvSection = find(data && data.sections, ({ fields }) => some(fields, { key: 'xCVV' }));
		if (cvvSection) {
			cvvField = find(cvvSection.fields, { key: 'xCVV' });
		}
		const cvvKey = cvvSection ? `${cvvSection.key}.xCVV` : 'xCVV';

		return map(paymentSections, ({ key, label, fields, expanded, toggleExpanded }) => {
			if (key === 'ach' && !entireAcceptACH) {
				return null;
			}

			return (
				<div key={key} className="card spc--bottom--med">
					<button className="card__header card__header--expandable" onClick={toggleExpanded}>
						<h5>{label}</h5>
						<i
							className={`icon icon--sml icon--chevron--${expanded ? 'top' : 'down'}--primary`}
							data-tooltip={expanded ? 'Collapse' : 'Expand'}
						></i>
					</button>
					{expanded && (
						<div className="card__body">
							{map(fields, ({ key: fieldKey, name, isRequired, Tooltip }) => (
								<div className="paymentsite__card__row" key={fieldKey}>
									<div data-tooltip="Required" className="flex--primary datatooltip--auto spc--right--med">
										<input
											id={`${fieldKey === 'xCVV' ? cvvKey : fieldKey}.isRequired`}
											name={`${fieldKey === 'xCVV' ? cvvKey : fieldKey}.isRequired`}
											className="input--check input--check--icon"
											type="checkbox"
											disabled={fieldKey !== 'xCVV' || isEmpty(data.sections)}
											readOnly={fieldKey !== 'xCVV'}
											onChange={this.handleDataChange}
											checked={fieldKey === 'xCVV' && cvvField ? cvvField.isRequired : invokeIfFunction(isRequired)}
										/>
										<label htmlFor={`${fieldKey === 'xCVV' ? cvvKey : fieldKey}.isRequired`}>
											<i
												className={`icon icon--sml icon--favorite${
													fieldKey === 'xCVV' && cvvField ? '-fill--primary' : '--light'
												}`}
											></i>
											<span>Required</span>
										</label>
									</div>
									<label className="type--p2 type--color--text">
										{name} {Tooltip && <Tooltip />}
									</label>
								</div>
							))}
						</div>
					)}
				</div>
			);
		});
	}

	renderComponentReadOnly = ({ key, name, options, type, fieldIndex, sectionIndex, hideField }) => {
		if (hideField) {
			return null;
		}
		const { formSettings } = this.state;
		let value = formSettings[sectionIndex].fields[fieldIndex].value;
		if (type === 'checkbox' || type === 'radio') {
			if (isNaN(parseInt(value))) {
				if (value === true || value === false) {
					value = value ? 1 : 0;
				} else {
					value = toLower(value) === 'yes' ? 1 : 0;
				}
			}
		}
		if (!value || (type === 'select' && !value.name)) {
			return null;
		}

		return (
			<div key={key} className="col col-sml-12">
				<div className="spc--bottom--med">
					<div className="paymentsite__form__label cursor--default">{name}</div>
					{this.renderReadOnlyValue(type, name, key, value, options, sectionIndex, fieldIndex)}
				</div>
			</div>
		);
	};

	renderReadOnlyValue = (type, name, key, value, options, sectionIndex, fieldIndex) => {
		const { formSettings } = this.state;
		if (isArray(type)) {
			return map(type, value => {
				const itemValue = formSettings[sectionIndex].fields[fieldIndex].value;
				let suffix = null;
				let prefix = null;
				if (value === 'numberFormat' && itemValue) {
					if (endsWith(toLower(key), 'percent') && value === 'numberFormat') {
						suffix = '%';
					} else if (endsWith(toLower(key), 'amount') && value === 'numberFormat') {
						prefix = CurrencyMap.resolveCurrency();
					}
					return (
						<div key={name}>
							{prefix}
							{itemValue}
							{suffix}
						</div>
					);
				}
			});
		}
		if (type === 'radio') {
			return map(options, ({ label, value }) => {
				const itemValue = formSettings[sectionIndex].fields[fieldIndex].value == value;
				if (itemValue) {
					return <div key={label}>{startCase(label)}</div>;
				}
			});
		}
		if (type === 'select') return <div>{value.label || value}</div>;
		if (type === 'checkbox') return null;
		if (type === 'richtext') return <div dangerouslySetInnerHTML={{ __html: value }} />;
		if (!value) return null;
		return <div>{value}</div>;
	};

	renderComponent = ({
		key,
		name,
		component: Component,
		options,
		type,
		Tooltip,
		placeholder,
		suffix,
		fieldIndex,
		sectionIndex,
		isRequired,
		hideField,
		disabled,
		icon,
	}) => {
		const { formSettings } = this.state;
		const uniqueKey = `${key}.${suffix}`;
		const value = formSettings[sectionIndex].fields[fieldIndex].value;

		if (hideField) {
			return;
		}
		const props = {
			type: type,
			id: uniqueKey,
			name: uniqueKey,
			onChange: e => this.handleFormSettingsDataChange(sectionIndex, e),
			value,
			className: `${Component === 'textarea' ? 'input input--textarea input--textarea--vertical' : 'input input--med'}${
				invokeIfFunction(isRequired) && !value ? ' is-invalid' : ''
			}`,
			getOptionValue: option => option.name,
		};

		if (type === 'richtext') {
			props.isValid = invokeIfFunction(isRequired) && !!value && value != '<p><br></p>';
		}

		if (Component === 'textarea') {
			props.onKeyDown = this.handleTabPress(sectionIndex);
		}

		if (!isArray(type) && (type === 'checkbox' || type === 'radio')) {
			props.checked = value;
			props.className = `input--${type === 'checkbox' ? 'check' : 'radio'}`;
		}

		if (placeholder) {
			props.placeholder = placeholder;
		}

		if (options) {
			const filterOutOption = get(
				find(formSettings[sectionIndex].fields, {
					key: `CustomFieldFor${key === 'CustomFieldForDescription' ? 'OriginalAmount' : 'Description'}`,
				}),
				'value.name',
				false
			);

			props.options = filterOutOption ? filter(options, item => item.name !== filterOutOption) : options;
			if (type === 'select') {
				props.className = 'react-select-container';
				props.classNamePrefix = 'react-select';
				props.onChange = ({ name, label }) => {
					(async () => {
						await this.handleFormSettingsSelectDataChange(sectionIndex, { name, label, key });
					})();
				};
				props.value = value || customFieldOptions[0];
				props.isDisabled = invokeIfFunction(disabled);
			}
		}
		if (toLower(key) === 'removereenterachnumbers') {
			props.disabled = !this.state.entireAcceptACH;
		}
		if (toLower(key) === 'excludeconvfeenote') {
			props.disabled = invokeIfFunction(disabled);
			if (props.disabled) {
				props.value = '';
			}
		}
		const isAcceptVisa = toLower(key) === 'acceptvisa';
		const isAcceptAmex = toLower(key) === 'acceptamex';
		const isReEnter = toLower(key) === 'removereenterachnumbers';

		return (
			<Fragment>
				{isAcceptVisa && (
					<Fragment>
						{this.renderAddRemovePaymentMethods(
							isAcceptVisa,
							sectionIndex,
							false,
							'Credit Cards',
							'cardTypesAccepted',
							'Credit Cards'
						)}
					</Fragment>
				)}

				{(!includes(['checkbox', 'radio'], type) && !isArray(type)) || (options && type === 'radio') ? (
					<div className="form__group__header">
						<label className="form__group__label">{name}</label>
						{Tooltip && <Tooltip />}
					</div>
				) : null}

				<div className={getFieldClassName(type, name)}>
					{isArray(type) ? (
						map(type, value => {
							const enableInput = formSettings[sectionIndex].fields[fieldIndex].enableInput;
							const itemProps = {
								...props,
							};

							if (value === 'checkbox') {
								itemProps.value = enableInput;
								itemProps.checked = enableInput;
								itemProps.id = `${key}-enableInput.${suffix}`;
								itemProps.name = `${key}-enableInput.${suffix}`;
								itemProps.onChange = e =>
									this.getComponentCheckboxOnChange(sectionIndex, cloneDeep(e), key, enableInput);
								itemProps.type = value;
								itemProps.className = 'input--check';
							} else {
								if (value === 'numberFormat') {
									if (endsWith(toLower(key), 'percent') && value === 'numberFormat') {
										itemProps.suffix = '%';
									} else if (endsWith(toLower(key), 'amount') && value === 'numberFormat') {
										itemProps.prefix = CurrencyMap.resolveCurrency();
									}

									itemProps.className = 'input input--med';
									itemProps.decimalScale = 2;
									itemProps.thousandSeparator = ',';
									itemProps.decimalSeparator = '.';
									itemProps.allowNegative = false;
									itemProps.enableInput = e => this.checkIfAllowedValue(key, e);
									itemProps.onValueChange = ({ value }) =>
										this.handleFormSettingsDataChange(sectionIndex, { target: { value, name: itemProps.name } });
									Component = NumberFormat;
									delete itemProps.onChange;
									delete itemProps.type;
									delete itemProps.enableInput;
								}
								itemProps.disabled = !enableInput || (isReEnter && !this.state.entireAcceptACH);
								delete itemProps.checked;
							}

							return (
								<div key={value}>
									<Component {...itemProps} />
									{value === 'checkbox' && (
										<label htmlFor={`${key}-enableInput.${suffix}`}>
											{name} {Tooltip && <Tooltip />}
										</label>
									)}
								</div>
							);
						})
					) : type === 'radio' ? (
						map(options, ({ label, value }) => {
							const uniqueKey = `${key}-${value}.${suffix}`;
							const itemProps = {
								...props,
								id: uniqueKey,
								name: uniqueKey,
								checked: formSettings[sectionIndex].fields[fieldIndex].value == value,
								onChange: e => this.handleFormSettingsRadioChange(sectionIndex, e),
							};

							return (
								<div className="spc--bottom--med" key={value}>
									<Component {...itemProps} />
									<label className="spc--right--nano" htmlFor={uniqueKey}>
										{label}
									</label>
								</div>
							);
						})
					) : (
						<Component {...props} />
					)}
					{includes(['checkbox', 'radio'], type) && !options && (
						<label htmlFor={`${key}.${suffix}`}>
							{icon ? <img className="spc--right--nano spc--bottom--nano" src={icon}></img> : ''}
							{name} {Tooltip && <Tooltip />}
						</label>
					)}
				</div>

				{isAcceptAmex && (
					<Fragment>
						{this.renderAddRemovePaymentMethods(isAcceptVisa, sectionIndex, true, 'ACH', 'entireAcceptACH', 'ACH')}
					</Fragment>
				)}
			</Fragment>
		);
	};

	getFormSettingLabel = (key, settingFields) => {
		let displayLabel = '';
		if (includes(key, 'tab')) {
			displayLabel = find(settingFields, field => field.key === 'tab_title').value;
		}
		return displayLabel;
	};
	renderFormSettingInlineEdit = (key, settingFields, sectionIndex) => {
		const value = find(settingFields, field => field.key === 'tab_title').value;
		const inlineInputClassName = `input input--med ${this.getInvalidClassName(!value)}`;

		return (
			<div className="inputgroup">
				<div className="inputgroup--main padd--right--sml">
					<input
						ref={this.inlineInputRef}
						name="tab_title"
						id="tab_title"
						disabled={this.props.isLoading}
						onChange={e => this.handleFormSettingsDataChange(sectionIndex, e)}
						value={value}
						className={inlineInputClassName}
						autoComplete="off"
					/>
				</div>
				<div className="inputgroup--aside">
					<button
						onClick={e => this.toggleFormInlineEdit(e, key)}
						className="btn btn--med btn--primary"
						disabled={!value}
					>
						Done
					</button>
				</div>
			</div>
		);
	};
	renderFormSettings(isLogoOnly) {
		const {
			formSettings,
			selectedTab,
			data: { theme },
		} = this.state;
		// internal settings should only be visible on the internal section
		return map(
			formSettings,
			({ key, label, fields, expanded, toggleExpanded, internalSetting, inlineEdit }, sectionIndex) => {
				if (toLower(key) !== 'paymentsitelogo') return;
				if (toLower(key) === 'backgroundcolor' && theme === 'green') return null;
				if ((isLogoOnly && key !== 'paymentSiteLogo') || (!isLogoOnly && key === 'paymentSiteLogo')) return null;
				if (key === 'acceptACH' || !this.state[`entire${replace(startCase(key), /\s/g, '')}`] || internalSetting) {
					return null;
				}
				const displayLabel = this.getFormSettingLabel(key, get(formSettings[sectionIndex], 'fields', [{}])) || label;
				return (
					<div key={key} className="card spc--bottom--med">
						<button className="card__header card__header--expandable" onClick={toggleExpanded}>
							<div className="flex--primary flex--gap--sml">
								<h5>{inlineEdit ? this.renderFormSettingInlineEdit(key, fields, sectionIndex) : displayLabel}</h5>
								{includes(toLower(key), 'tab') && !inlineEdit && (
									<button
										className="btn btn--icon btn--icon--lrg datatooltip--auto"
										data-tooltip="Rename"
										onClick={e => this.toggleFormInlineEdit(e, key)}
									>
										<i className="icon icon--sml icon--edit"></i>
									</button>
								)}
							</div>
							<i
								className={`icon icon--sml icon--chevron--${expanded[selectedTab] ? 'top' : 'down'}--primary`}
								data-tooltip={expanded[selectedTab] ? 'Collapse' : 'Expand'}
							></i>
						</button>
						{expanded[selectedTab] && (
							<div className="card__body">
								{map(fields, ({ key, name, component, type, Tooltip, options, placeholder, hideField }, fieldIndex) => {
									return this.renderComponentReadOnly({
										key,
										name,
										component,
										type,
										Tooltip,
										options,
										placeholder,
										fieldIndex,
										sectionIndex,
										hideField,
									});
								})}
								{key === 'paymentSiteLogo' && <Fragment>{this.paymentSiteLogo.component()}</Fragment>}
							</div>
						)}
					</div>
				);
			}
		);
	}

	renderRemoveSection = () => {
		const {
			is3dSecureEnabled,
			removeSectionAction,
			removeSectionName,
			removeSectionKey,
			data: { sections },
			customRemoveSectionMessage,
		} = this.state;

		const currentSection = find(sections, section => section.key === removeSectionKey);
		let show3dSecureWarning = false;
		currentSection &&
			each(currentSection.fields, ({ key }) => {
				if ((includes(required3dSecureFields, key) || head(split(key, ',')) == 'bill city') && is3dSecureEnabled) {
					show3dSecureWarning = true;
				}
			});

		return (
			<Fragment>
				<div className="modal__body type--med popup">
					{show3dSecureWarning && (
						<Fragment>
							<p className="spc--bottom--sml">
								You are attempting to delete a field that is set to <span className="type--wgt--bold">"required"</span>{' '}
								on the PaymentSITE form.
							</p>
							<p>
								Deleting a required field can cause transactions to be processed without 3D Secure. It can also cause
								failed or declined payments.
							</p>
						</Fragment>
					)}
					{customRemoveSectionMessage && <p>{customRemoveSectionMessage}</p>}
					<p>Are you sure you want to remove {get(currentSection, 'label') || removeSectionName || ''}?</p>
				</div>
				<div className="modal__footer">
					<button
						type="button"
						tabIndex="-1"
						className="btn btn--med btn--primary"
						onClick={() => {
							removeSectionAction();
							this.closeRemoveSectionPopup();
						}}
					>
						Remove
					</button>
				</div>
			</Fragment>
		);
	};

	renderRemove3dSecureFieldModal = () => (
		<Modal
			isOpen={this.state.isOpenRemove3dSecureFieldPopup}
			onClose={this.closeRemove3dSecureFieldPopup}
			shouldCloseOnOverlayClick={false}
			overlayClassName="modal__overlay"
			className="modal__content modal--sml"
		>
			{this.renderRemove3dSecureFieldPopupSection()}
		</Modal>
	);

	renderRemove3dSecureFieldPopupSection = () => {
		const { keyToRemove, sectionKeyToRemove } = this.state;
		return (
			<Fragment>
				<div className="modal__body type--med">
					<p className="spc--bottom--sml">
						You are attempting to delete a field that is set to <span className="type--wgt--bold">"required"</span> on
						the PaymentSITE form.
					</p>
					<p>
						Deleting a required field can cause transactions to be processed without 3D Secure. It can also cause failed
						or declined payments.
					</p>
				</div>
				<div className="modal__footer">
					<button
						type="button"
						tabIndex="-1"
						className="btn btn--med btn--primary"
						onClick={async () => {
							await this.removeEditField(sectionKeyToRemove, keyToRemove);
							this.closeRemove3dSecureFieldPopup();
						}}
					>
						Yes
					</button>
				</div>
			</Fragment>
		);
	};
	handleAddNewSection = () => {
		if (this.state.isPendingAddSectionToggle) return;
		this.setState({ isPendingAddSectionToggle: true });
		this.setState({ newSectionActive: !this.state.newSectionActive }, () =>
			setTimeout(() => this.setState({ isPendingAddSectionToggle: false }), 100)
		);
	};

	setKioskThemeAmounts = (newSectionId, amountField, xAmountSectionLabel, cvvField) => {
		const newSection = this.mapSection({
			key: newSectionId,
			label: xAmountSectionLabel,
			fields: [], // fields need to be empty when creating a new section
			params: defaults({ version: '1.1', box_width: 0, box_column: '1' }),
			disableDrag: true,
			isAmountSection: true,
			hideDelete: true,
			hideAdd: true,
			hideEdit: true,
			hideActions: true,
			hideRename: false,
			hideFieldLabel: true,
			hideOptionsPreview: false,
			name: 'amounts',
		});
		amountField.type = 'dropdown';

		newSection.fields = [amountField];
		if (cvvField) {
			newSection.fields.unshift(cvvField);
		}

		return newSection;
	};
	addKioskThemeRecurring = () => {
		const newSection = this.mapSection({
			key: uniqueId('recurring'),
			label: 'Recurring',
			fields: [],
			params: { version: '1.1', box_width: 0, box_column: '1' },
			disableDrag: true,
			hideAdd: true,
			hideRename: true,
			hideEdit: true,
			isKioskPlaceholder: true,
			hideActions: true,
		});
		newSection.fields = [
			{ ...createField('weekly', 'Weekly'), label: 'Weekly', disableDrag: true, hideRequired: true },
			{ ...createField('monthly', 'Monthly'), label: 'Monthly', disableDrag: true, hideRequired: true },
			{ ...createField('yearly', 'Yearly'), label: 'Yearly', disableDrag: true, hideRequired: true },
		];
		return newSection;
	};

	addNewSection = async (customLabel, isHiddenSection, isFirstSection = true) => {
		const { newSectionId, data } = this.state;
		const sections = [...data.sections];
		const newSection = this.mapSection({
			key: newSectionId,
			label: typeof customLabel === 'string' ? customLabel : 'New Section',
			fields: [],
			params: { version: '1.1', box_width: 0, box_column: '1' },
			isHidden: isHiddenSection, //  internal users should see the section
		});
		if (isFirstSection) {
			sections.unshift(newSection);
		} else {
			sections.push(newSection);
		}
		const modifiedState = {
			data: {
				...data,
				sections,
			},
			newSectionId: this.incrementer().toString(),
			undoSteps: this.cloneCurrentStateAsUndoStep(),
		};

		await this.setStateAsync(modifiedState);
		return newSection;
	};
	hasEntireSectionFilter = (sectionLabel, stateLabel) => {
		const hasLabel = some(
			this.state.data.sections,
			section => toLower(replace(section.label, /\s/g, '')) === sectionLabel
		);
		if (this.state[stateLabel] !== hasLabel) {
			this.setState({
				[stateLabel]: hasLabel,
			});
		}
		return hasLabel;
	};
	hasEntireSection = sectionName => {
		switch (true) {
			case sectionName === 'entireBilling':
				return this.hasEntireSectionFilter('billinginformation', 'entireBilling');
			case sectionName === 'entireShipping':
				return this.hasEntireSectionFilter('shippinginformation', 'entireShipping');
			case sectionName === 'entireTransactionDetails':
				return this.hasEntireSectionFilter('transactiondetails', 'entireTransactionDetails');
		}
	};

	renderAvailableFieldsOutsideClick = availableFields => {
		const { isKioskTheme, entireBilling, entireShipping, isAdmin, isGoPlus } = this.state;
		return (
			<OutsideClick action={e => this.toggleAddFieldPopup(e, null, true)} className="popover popover--down">
				<PaymentSiteAvailableFields
					availableFields={availableFields}
					isKioskTheme={isKioskTheme}
					entireBilling={entireBilling}
					entireShipping={entireShipping}
					isAdmin={isAdmin}
					isGoPlus={isGoPlus}
				/>
			</OutsideClick>
		);
	};

	hasFieldPreview = () => {
		return some(flatMap(this.state.data.sections, 'fields'), { isFieldPreview: false });
	};

	render() {
		const {
			data,
			showRevert,
			isOpenRemoveSectionPopup,
			selectedTab,
			errorMessages,
			modal,
			undoSteps,
			isNameEdit,
			invalidName,
			nameError,
			hasNameError,
			isAdmin,
			newSectionActive,
			availableFields,
			isKioskTheme,
			newSectionId,
			entireBilling,
			entireShipping,
			entireTransactionDetails,
			activeSectionIndex,
			sectionActionsRef,
			isOpenOptionsModal,
			isGoPlus,
		} = this.state;
		const { isLoading, breadcrumbRef } = this.props;
		if (!data) {
			return null;
		}
		const { name, sections } = data;
		const paymentSiteNameClass = `input input--med ${this.getInvalidClassName(hasNameError)}`;
		const enableUndo = !isEmpty(undoSteps);
		return (
			<Fragment>
				{breadcrumbRef &&
					createPortal(
						<NavLink to="/settings/gateway-settings/payment-site">&gt; Payment Sites</NavLink>,
						breadcrumbRef.current
					)}
				<PortalFieldsDropdown
					sections={sections}
					renderAvailableFields={this.renderAvailableFieldsOutsideClick}
					activeSectionIndex={activeSectionIndex}
					availableFields={availableFields}
					sectionActionsRef={sectionActionsRef}
					toggledSections={{
						entireBilling,
						entireShipping,
						entireTransactionDetails,
					}}
					isKioskTheme={isKioskTheme}
					isAdmin={isAdmin}
					isGoPlus={isGoPlus}
				/>
				{this.renderInvalidFileTypePopup()}
				<Notification ref={this.notificationRef} />
				<Modal
					isOpen={isOpenRemoveSectionPopup}
					onClose={this.closeRemoveSectionPopup}
					shouldCloseOnOverlayClick={false}
					overlayClassName="modal__overlay"
					className="modal__content modal--sml"
					hideHeaderCloseButton={true}
				>
					{this.renderRemoveSection()}
				</Modal>
				{this.renderRemove3dSecureFieldModal()}
				<ActionsModal
					modal={modal}
					onModalClose={this.closeActionsPopup}
					isLoading={isLoading}
					hideHeaderCloseButton={true}
				/>
				<div className="paymentsite">
					<form className="paymentsite__header" onSubmit={this.toggleEdit}>
						{isNameEdit ? (
							<div className="paymentsite__header__edit__wrapper">
								<input
									name="name"
									id="name"
									disabled={isLoading}
									ref={this.inputRef}
									onChange={this.handleDataChange}
									value={name}
									className={paymentSiteNameClass}
									autoComplete="off"
								/>
								<button
									onClick={e => this.toggleEdit(e, undefined, 'Name')}
									className="btn btn--med btn--primary"
									disabled={hasNameError}
								>
									Save
								</button>
							</div>
						) : (
							<div className="paymentsite__header__title">
								<h3>{name}</h3>
								<button
									className="btn paymentsite__header__edit datatooltip--down-left datatooltip--auto"
									data-tooltip="Edit name"
									onClick={e => this.toggleEdit(e, undefined, 'Name')}
								>
									<i className="icon icon--med icon--edit"></i>
								</button>
							</div>
						)}

						<div className="flex--primary flex--gap--sml--alt flex--nowrap">
							<button
								disabled={isLoading || !enableUndo}
								className="btn btn--med btn--link btn--link--tertiary"
								type="button"
								onClick={this.handleUndo}
							>
								<i className="icon icon--sml icon--refund--light"></i>
								<span>Undo</span>
							</button>
							<button
								disabled={
									isLoading ||
									!isEmpty(errorMessages) ||
									invalidName ||
									this.hasFieldPreview() ||
									this.getRenderOptionsProperties(null, true)
								}
								className="btn btn--med btn--primary"
								type="button"
								onClick={this.handlePublish}
							>
								Publish
							</button>
						</div>
					</form>

					<ul className="tabs spc--bottom--lrg">
						<li className="tabs__item">
							<button
								onClick={() => this.handleSelectedTabChange(tabs.manageLayout)}
								className={`tabs__link${selectedTab === tabs.manageLayout ? ' is-active' : ''}`}
							>
								Manage Layout
							</button>
						</li>
						<li className="tabs__item">
							<button
								onClick={() => this.handleSelectedTabChange(tabs.generalSettings)}
								className={`tabs__link${selectedTab === tabs.generalSettings ? ' is-active' : ''}`}
							>
								General Settings
							</button>
						</li>
						{isAdmin && (
							<li className="tabs__item">
								<button
									onClick={() => this.handleSelectedTabChange(tabs.internalSettings)}
									className={`tabs__link${selectedTab === tabs.internalSettings ? ' is-active' : ''}`}
								>
									Internal Settings
								</button>
							</li>
						)}
					</ul>

					<div className="flex--primary flex--gap--sml--alt flex--right spc--bottom--lrg">
						{selectedTab === tabs.manageLayout && (
							<button
								disabled={isLoading}
								className="btn btn--sml btn--tertiary"
								type="button"
								onClick={this.handleExpandCollapseAll}
							>
								{`${this.allExpanded ? 'Collapse' : 'Expand'} All`}
							</button>
						)}
						{showRevert && (
							<button
								disabled={isLoading}
								className="btn btn--sml btn--tertiary"
								type="button"
								onClick={this.handleRevert}
							>
								Revert
							</button>
						)}
						{this.state.selectedTab === tabs.manageLayout && (
							<div className="pos--rel">
								<button
									disabled={isLoading}
									className={`btn btn--sml btn--secondary ${newSectionActive ? 'is-active' : ''} `}
									type="button"
									onClick={this.handleAddNewSection}
								>
									Add a New Section
								</button>
								<AddNewSection
									newSectionId={newSectionId}
									availableFields={availableFields}
									isAdmin={isAdmin}
									newSectionActive={newSectionActive}
									isKioskTheme={isKioskTheme}
									handleAddNewSection={this.handleAddNewSection}
									addNewSection={this.addNewSection}
									toggledSections={{
										entireBilling: this.hasEntireSection('entireBilling'),
										entireShipping: this.hasEntireSection('entireShipping'),
										entireTransactionDetails: this.hasEntireSection('entireTransactionDetails'),
									}}
								/>
							</div>
						)}
					</div>

					<div
						className={
							selectedTab !== 'internalSettings' && selectedTab !== 'generalSettings' ? 'paymentsite__body' : ''
						}
					>
						{!isEmpty(errorMessages) && (
							<div className="type--validation__wrapper spc--bottom--lrg">
								{map(errorMessages, (errorMessage, index) => (
									<div key={errorMessage}>
										<p key={index} className="type--validation">
											{errorMessage}
										</p>
									</div>
								))}
							</div>
						)}

						{nameError && (
							<div className="type--validation__wrapper">
								<p className="type--validation">{nameError}</p>
							</div>
						)}
						{selectedTab === tabs.generalSettings && (
							<div className="paymentsite__settings">
								<PaymentSiteGeneralSettingsTab
									state={this.state}
									renderComponent={this.renderComponent}
									removeFormSetting={this.removeFormSetting}
									toggleRemoveSectionPopup={this.toggleRemoveSectionPopup}
									toggleExpanded={this.toggleExpandedFormSettings}
									addTab={this.addTab}
									handleAdditionalSettingsDataChange={this.handleAdditionalSettingsDataChange}
									handleAdditionalSettingsClear={this.handleAdditionalSettingsClear}
									hasThemeAccess={this.state.hasThemeAccess}
									getFormSettingLabel={this.getFormSettingLabel}
									renderPaymentSections={this.renderPaymentSections}
								/>
							</div>
						)}
						{selectedTab === tabs.internalSettings && (
							<div className="paymentsite__settings">
								<PaymentSiteInternalSettings
									state={this.state}
									renderComponent={this.renderComponent}
									removeFormSetting={this.removeFormSetting}
									toggleRemoveSectionPopup={this.toggleRemoveSectionPopup}
									toggleExpanded={this.toggleExpandedFormSettings}
									handleAdditionalSettingsDataChange={this.handleAdditionalSettingsDataChange}
									handleAdditionalSettingsClear={this.handleAdditionalSettingsClear}
									getFormSettingLabel={this.getFormSettingLabel}
								/>
							</div>
						)}
						{selectedTab !== 'internalSettings' && selectedTab !== 'generalSettings' && (
							<Fragment>
								<div>
									{isKioskTheme ? this.renderFormSettings(true) : null}
									{this.renderFormSettings()}
									<DraggableSections
										isCanadian={this.isCanadian}
										items={sections}
										disable={isLoading}
										onHover={this.hoverSection}
										onChange={this.handleDataChange}
										onHoverField={this.hoverField}
										sectionReferrer={this.sectionSelector}
										expandOptions={this.openOptionsModal}
										isAdmin={isAdmin}
										isGoPlus={isGoPlus}
										disableAmountDropdown={this.isServiceFeeEnabled()}
										availableFields={availableFields}
										renderAvailableFields={this.renderAvailableFieldsOutsideClick}
										className="paymentsite__card"
										hideDropdownOption={this.state.isKioskTheme}
										createActionRef={this.createActionRef}
										disableFieldOutsideDrag={this.disableDragKioskAmount}
										renderOptions={this.renderOptions}
										isOpenOptionsModal={isOpenOptionsModal}
									/>
								</div>

								<div>
									{isKioskTheme ? null : this.renderFormSettings(true)}
									{this.renderPaymentSections()}
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

PaymentSiteEdit.propTypes = {
	history: object.isRequired,
	match: object.isRequired,
	isLoading: bool.isRequired,
	makePendingRequest: func.isRequired,
	handleError: func.isRequired,
	handleBlockChange: func.isRequired,
	breadcrumbRef: object,
};

export default withError(withCancelable(withLoader(withRouter(withBlock(PaymentSiteEdit)))));
