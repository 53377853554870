import React, { Component, forwardRef, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';

import { Notification } from './../notifications';
import { Modal } from '../modal';
import { UNEXPECTED_ERROR_MSG, SERVER_COMM_ERROR_MSG } from '../../../components/error';
import { parseError, getMail, logger } from '../../utilities';

function withError(WrappedComponent, mapError = identity) {
	class ErrorComponent extends Component {
		constructor(props) {
			super(props);
			this.notificationRef = createRef();
			this.state = {
				errorMessageVisible: false,
				hasUncaughtError: false,
			};
		}

		componentDidCatch(error) {
			this.handleError(error);
		}

		static getDerivedStateFromError = () => ({ hasUncaughtError: true });

		toggleErrorMessage = (errorMessageVisible = !this.state.errorMessageVisible) => {
			return new Promise(resolve => {
				this.setState(
					{
						errorMessageVisible,
					},
					resolve
				);
			});
		};

		handleError = (
			error,
			{ delayMessage = false, additionalInfo = {}, onClose = false, isNewTransaction = false } = {}
		) => {
			const mappedError = mapError(error);
			if (!mappedError || mappedError.isCanceled) {
				return false;
			}
			let { message, stack, requestInfo, responseInfo, displayMessage } = parseError(mappedError);
			if (!mappedError.isApiError) {
				message = getMail(
					stack || message || UNEXPECTED_ERROR_MSG,
					{ ...additionalInfo, requestInfo, responseInfo },
					displayMessage,
					isNewTransaction
				);
			} else if (!message) {
				message = SERVER_COMM_ERROR_MSG;
			}
			logger.logError({
				message,
				errorDetails: { stack, additionalInfo, ref: mappedError.ref },
			});
			const notification = {
				message,
				stack,
				additionalInfo,
				ref: mappedError.ref,
				success: false,
				onClose: () => {
					if (onClose) {
						onClose();
						this.toggleErrorMessage();
					} else {
						this.toggleErrorMessage();
					}
				},
				forceCloseHandler: true,
				show: override => {
					this.toggleErrorMessage(true).then(() => {
						if (this.notificationRef.current) {
							this.notificationRef.current.addNotification(override || notification);
						}
					});
				},
			};
			if (!delayMessage) {
				notification.show();
			}
			return notification;
		};

		render = () => {
			const { forwardedRef, ...rest } = this.props;
			const { errorMessageVisible, hasUncaughtError } = this.state;
			return hasUncaughtError ? null : (
				<Fragment>
					<Modal isOpen={errorMessageVisible} hideHeaderCloseButton={true} onClose={() => {}}>
						<Notification style={{ maxWidth: 'none' }} ref={this.notificationRef} />
					</Modal>
					<WrappedComponent
						handleError={this.handleError}
						errorMessageVisible={errorMessageVisible}
						ref={forwardedRef}
						{...rest}
					/>
				</Fragment>
			);
		};
	}

	ErrorComponent.propTypes = {
		forwardedRef: PropTypes.any,
	};

	return forwardRef((props, ref) => <ErrorComponent {...props} forwardedRef={ref} />);
}

export default withError;
