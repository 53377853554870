import React, { Component, createRef, Fragment } from 'react';
import { sumBy, isEmpty, each, cloneDeep, round } from 'lodash';
import PropTypes from 'prop-types';
import { ExportToCsv } from 'export-to-csv';
import { Data } from 'react-data-grid-addons';

import { SchedulePreviewColumns as Columns } from './../../Common/components/schedule-preview/column-filter/schedulePreviewColumns';
import { customerService } from '../../Common/services';
import { exportOptions } from '../../Common/components/export/export-options';
import { withError } from '../../Common/components/error';
import { withCancelable } from '../../Common/components/cancelable';
import { Modal } from '../../Common/components/modal';
import PopupGrid from 'common/components/grid/PopupGrid';
import { WhiteRenderer } from 'common/components/row';

class SchedulePreviewGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columns: cloneDeep(Columns),
			defaultColumns: cloneDeep(Columns),
			inlineFilters: {},
			data: null,
			filteredRows: [],
			fetchingData: false,
			fetchingAdditionalData: false,
		};

		this.components = {
			rowRenderer: WhiteRenderer,
			title: this.renderTitle,
		};

		this.classes = {
			title: 'type--break-word type--med type--wgt--medium spc--bottom--sml',
			filterContainer: '',
			filter: '',
			gridHeader: '',
			headerGroup: 'display--f',
			gridWrapper: '',
			gridHolder: 'react-grid',
			header: 'flex--tertiary flex--gap--med spc--bottom--lrg spc--right--xxxlrg',
			filterWrapper: '',
			printWrapper: 'datatooltip--v--bottom',
			exportWrapper: 'datatooltip--v--bottom',
		};

		this.gridRef = createRef();
		this.popupRef = createRef();
	}

	componentDidUpdate = ({ isOpen: wasOpen }) => {
		const { isOpen, schedule } = this.props;
		const grid = this.gridRef.current;

		if (!wasOpen && isOpen && schedule.isActive && grid) {
			grid.calculateColumnWidths();
			this.fetchData();
		}
	};

	fetchData = async () => {
		const {
			schedule: { totalPayments, paymentsProcessed, scheduleId, amount, currency, remainingCharges, calendarCulture },
			onClose,
			makePendingRequest,
			handleError,
		} = this.props;
		const remainingTotal = totalPayments - paymentsProcessed;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
		});

		let upcomingPaymentsCount = null;
		if (totalPayments) {
			upcomingPaymentsCount = totalPayments - paymentsProcessed;
		}

		try {
			const data = await makePendingRequest(
				customerService.filterUpcomingPayments(scheduleId, upcomingPaymentsCount, amount, currency, calendarCulture)
			);

			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({ rows: data.xReportData, filters: this.state.inlineFilters })
					: [];

			if (
				remainingCharges < 13 ||
				(remainingTotal < 13 && remainingTotal > 0) ||
				(data && data.xRecordsReturned < 13)
			) {
				if (!totalPayments) {
					let remainingPayments = data.xRecordsReturned;
					each(data.xReportData, record => {
						remainingPayments--;
						record.paymentsRemaining = remainingPayments;
					});
				}
				data.xReportData.push({
					amount: round(sumBy(data.xReportData, ({ amount }) => round(amount, 2)), 2),
					currency,
					date: '',
					paymentsRemaining: 'Total Amount:',
				});
			}

			if (!isEmpty(data.xReportData)) {
				this.setState(
					{
						data,
						fetchingData: false,
						filteredRows,
					},
					() => {
						const grid = this.gridRef.current;
						if (grid) {
							grid.calculateColumnWidths();
						}
					}
				);
			} else {
				this.setState({
					fetchingData: false,
					filteredRows,
				});
			}
		} catch (e) {
			if (handleError(e)) {
				onClose();
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	refetchData = () => {
		this.fetchData();
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	download = () => {
		const exporter = new ExportToCsv({
			filename: `${this.props.schedule.scheduleId}_schedule_preview`,
			...exportOptions,
		});
		const data = this.replaceColumns(this.state.data.xReportData, this.state.columns);

		exporter.generateCsv(data);
	};

	replaceColumns = (data, columnsSource) => {
		const columns = this.getColumns(columnsSource);
		const mappedData = [];
		for (let item of data) {
			let newObj = {};
			const columnKeys = Object.keys(columns);
			for (let colKey of columnKeys) {
				const newPropName = columns[colKey];
				newObj[newPropName] = item[colKey];
			}
			mappedData.push(newObj);
		}
		return mappedData;
	};

	getColumns = columns => {
		let returnObj = [];
		for (let col of columns) {
			returnObj[col.key] = col.name.replace(/\u00AD/g, '');
		}
		return returnObj;
	};

	renderTitle = () => {
		const { schedule } = this.props;

		return (
			<Fragment>
				<h4 className="type--wgt--medium spc--bottom--tny">Schedule Preview</h4>
				<p className="type--p3 type--color--text--light">
					Schedule {schedule.scheduleName ? 'Name' : 'ID'}: {schedule.scheduleName || schedule.scheduleId}
				</p>
			</Fragment>
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			defaultColumns,
			expanded,
		} = this.state;
		const { isOpen, onClose, className, schedule } = this.props;
		const remainingCharges = !isEmpty(schedule) && schedule.totalPayments - schedule.paymentsProcessed;

		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				className={className}
				hideHeaderCloseButton={isOpen && !schedule.isActive}
			>
				{isOpen ? (
					<div ref={this.popupRef}>
						<div className="modal__body">
							{schedule.isActive ? (
								<PopupGrid
									title="Schedule preview"
									printTitle="Schedule preview"
									emptyMessage="No upcoming schedules"
									fetchingData={fetchingData}
									fetchingAdditionalData={fetchingAdditionalData}
									filteredRows={filteredRows}
									columns={columns}
									data={data}
									inlineFilters={inlineFilters}
									components={this.components}
									classes={this.classes}
									onChange={this.handleChange}
									expanded={expanded}
									type="schedulePreview"
									fetchData={this.refetchData}
									showPrintDropdown={false}
									defaultColumns={defaultColumns}
									ref={this.gridRef}
									columnFilterType="/settings/user-settings/recurring-settings"
									kvaasResourceType="recurring"
									showPanel={false}
									displayHeaderMenu={false}
									displayShowHideSelectedFilters={false}
									enablePrint={true}
									enableExport={true}
									popupRef={this.popupRef}
									fetchExportData={{ current: this.download }}
								/>
							) : (schedule.remainingCharges || remainingCharges) === 0 ? (
								<div className="flex--primary flex--gap--sml">
									<i className="icon icon--med icon--regular--check"></i>
									<p className="type--p1">This schedule is completed.</p>
								</div>
							) : (
								<div className="flex--primary flex--gap--sml">
									<i className="icon icon--med icon--info"></i>
									<p className="type--p1">This schedule is inactive.</p>
								</div>
							)}
						</div>
					</div>
				) : (
					<div></div>
				)}
			</Modal>
		);
	}
}

SchedulePreviewGrid.propTypes = {
	schedule: PropTypes.object,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	className: PropTypes.string,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func.isRequired,
};

export default withCancelable(withError(SchedulePreviewGrid));
