import React, { Component, Fragment } from 'react';
import { each, get, includes, isEmpty, replace, startsWith, toLower, trim, upperFirst, split } from 'lodash';
import { func, object, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { withCancelable } from '../cancelable';
import { withLoader } from '../loader';
import { checkForUrls, emailTemplates, invokeIfFunction, kvaasResources } from 'common/utilities';
import { emailService, kvaasService } from 'common/services';
import { validators } from 'common/fields';
const { paymentSiteUrl } = ApplicationSettings;

const requestKeys = {
	SEND: 'send',
	KVAAS: 'kvaas',
	LOGO: 'logo',
};

class SendEmailPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isToEmailValid: props.toEmail ? validators.email(props.toEmail) : false,
			files: this.props.files,
			isContentValid: true,
			toEmail: props.toEmail,
			isCOGSettingClicked: false,
			displaySettingsButton: window.location.pathname !== '/portal-settings/send-payment-request',
			sendPaymentRequestManagement: props.sendPaymentRequestManagement,
			logoManagement: {
				includeCoBrandLogoOnPymntReqEmails: false,
			},
		};
	}

	async componentDidMount() {
		this.loadData();
	}

	loadData = async () => {
		try {
			const promises = [this.fetchLogoManagement()];
			if (isEmpty(this.props.sendPaymentRequestManagement)) {
				promises.push(this.fetchSendPaymentRequestTemplate(newState));
			}

			const [logoManagement, sendPaymentRequestManagement] = await Promise.all(promises);

			const newState = {
				logoManagement: get(logoManagement, 'data', { includeCoBrandLogoOnPymntReqEmails: false }),
			};

			if (isEmpty(this.props.sendPaymentRequestManagement)) {
				newState.sendPaymentRequestManagement = get(sendPaymentRequestManagement, 'data', { subject: '', content: '' });
			}
			newState.isContentValid = this.validateSendPaymentContent(
				get(this.props.sendPaymentRequestManagement, 'content', false) ||
					get(newState.sendPaymentRequestManagement, 'content', false)
			);

			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	fetchLogoManagement = async () => {
		try {
			const [[logoManagement]] = await this.props.makePendingRequest(
				Promise.all([kvaasService.get(kvaasResources.logoManagement)]),
				requestKeys.KVAAS
			);

			return logoManagement;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	fetchSendPaymentRequestTemplate = async () => {
		try {
			const [[sendPaymentRequestManagement]] = await this.props.makePendingRequest(
				Promise.all([kvaasService.getIgnoreCache(kvaasResources.sendPaymentRequestManagement)]),
				requestKeys.KVAAS
			);

			return sendPaymentRequestManagement;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	validateSendPaymentContent = content => {
		return includes(content, '[link]') && !checkForUrls(content);
	};
	setStateAsync = state => new Promise(resolve => this.setState(state, resolve));
	handleChange = async ({ target: { name, value } }) => {
		const newState = {};
		if (name === 'toEmail') {
			const isToEmailValid = validators.email(value);
			newState.isToEmailValid = isToEmailValid;
		}
		if (includes(['content', 'subject', 'fromCompany'], name)) {
			return this.onPaymentRequestManagementChange(name, value);
		}
		newState[name] = includes(toLower(name), 'email') ? value.replace(/\s/g, '') : value;
		await this.setStateAsync(newState);
	};
	onPaymentRequestManagementChange = async (name, value) => {
		let { sendPaymentRequestManagement, isContentValid } = this.state;
		sendPaymentRequestManagement[name] = value;
		if (name === 'content') {
			isContentValid = this.validateSendPaymentContent(value);
		}
		this.setState({ sendPaymentRequestManagement, isContentValid });
	};

	showLoader = (isLoading = false) => {
		this.setState({ isLoading });
		invokeIfFunction(this.props.setLoading, isLoading);
	};

	changeCOGSettingsState = () => {
		this.setState({ isCOGSettingClicked: true });
	};

	addValuesToString = (string, replaceKeys, replaceValues) => {
		let replacedString = string;

		each(replaceKeys, key => {
			replacedString = replace(replacedString, new RegExp(`\\[${key}\\]`, 'gi'), replaceValues[key]);
		});

		return replacedString;
	};

	setTemplateFiles = (templateParams, files) => {
		each(files, (fileData, i) => {
			if (i === 0) {
				templateParams.attachments = [];
			}
			templateParams.attachments.push({
				file: split(fileData.file, ',')[1],
				fileName: fileData.fileName,
			});
		});
	};

	sendEmail = async () => {
		const {
			closeModal,
			addNotification,
			handleError,
			makePendingRequest,
			newPaymentLink,
			paymentSiteName,
			paymentFields: { billFirstName, billLastName, invoice },
		} = this.props;
		const {
			files,
			toEmail,
			isCOGSettingClicked,
			sendPaymentRequestManagement: { subject, content, fromCompany },
			logoManagement: { includeCoBrandLogoOnPymntReqEmails },
		} = this.state;

		this.showLoader(true);
		try {
			if (isCOGSettingClicked) {
				const newState = {};
				await this.fetchSendPaymentRequestTemplate(newState);
				this.setState({ sendPaymentRequestManagement: newState.sendPaymentRequestManagement });
			}

			const name =
				billFirstName && billLastName
					? `${billFirstName} ${billLastName}`
					: billFirstName || billLastName || 'Customer';
			const company = trim(fromCompany) || 'The Cardknox Team';

			const paymentSiteLink = `${paymentSiteName}${newPaymentLink}${
				!paymentSiteName && !newPaymentLink ? 'exampleLink' : ''
			}`;
			let invoiceNumber = invoice ? `invoice #${invoice}` : 'invoice';
			if (startsWith(toLower(subject), '[invoice]')) {
				invoiceNumber = upperFirst(invoiceNumber);
			}

			const templateParams = {
				templateName: emailTemplates.sendPaymentRequest,
				to: toEmail,
				template: {
					subject: subject || `A new ${invoiceNumber} from ${company}`,
					useMerchantLogo: includeCoBrandLogoOnPymntReqEmails,
					fromCompany: company,
					paymentSiteLink,
				},
			};

			if (subject || content) {
				templateParams.template = {
					...templateParams.template,
					subject: this.addValuesToString(subject, ['invoice', 'company', 'link', 'name'], {
						invoice: invoiceNumber,
						company,
						link: `${paymentSiteUrl}${paymentSiteLink}`,
						name,
					}),
					customTemplate: this.addValuesToString(content, ['invoice', 'company', 'name'], {
						invoice: invoiceNumber,
						company,
						name,
					}),
					fromCompany: company,
				};

				templateParams.templateName = emailTemplates.customSendPaymentRequest;
			} else {
				templateParams.template.name = name;
			}
			this.setTemplateFiles(templateParams, files);
			await makePendingRequest(emailService.sendEmail(templateParams), requestKeys.SEND);

			await addNotification({
				message: 'Email sent successfully.',
				success: true,
			});
		} catch (e) {
			handleError(e);
		}
		this.showLoader();
		closeModal();
	};

	renderInvalidContent = () => (
		<label htmlFor="content">
			Content
			<span data-tooltip="Required" className="form__group__required">
				{' '}
				*
			</span>
			<div className="display--ib datatooltip--v--bottom datatooltip--w--170">
				<i
					className="icon icon--sml icon--info align--v--middle spc--left--tny"
					data-tooltip="The [link] content is required. No other links are allowed."
				></i>
			</div>
		</label>
	);

	render() {
		const {
			toEmail,
			isLoading,
			displaySettingsButton,
			isContentValid,
			sendPaymentRequestManagement: { subject, content, fromCompany },
			isToEmailValid,
		} = this.state;

		const disabled = !isToEmailValid || isLoading || !isContentValid;
		const contentClassName = `input input--textarea input--textarea--vertical${isContentValid ? '' : ' is-invalid'}`;

		return (
			<Fragment>
				<div className="modal__header">
					<h4 className="modal__header__title">Send Payment Request</h4>
					{displaySettingsButton && (
						<Link to="/settings/portal-settings/send-payment-request" onClick={this.changeCOGSettingsState}>
							<div
								className="btn btn--action btn--action--secondary datatooltip--auto datatooltip--no-wrap datatooltip--left"
								data-tooltip="Edit Email Template"
							>
								<i className="icon icon--sml icon--settings"></i>
							</div>
						</Link>
					)}
				</div>
				<div className="modal__body">
					{isLoading ? (
						<div className="loader--modal__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<Fragment>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="toEmail" className="form__group__label">
										To Email
									</label>
									<span data-tooltip="Required" className="form__group__required">
										*
									</span>
									<i
										className="icon icon--tny icon--regular--info datatooltip--w--150"
										data-tooltip="Separate multiple email addresses with a comma."
									></i>
								</div>
								<input
									name="toEmail"
									id="toEmail"
									type="text"
									className={`input input--med${isToEmailValid ? '' : ' is-invalid'}`}
									value={toEmail}
									autoComplete="one-time-code"
									onChange={this.handleChange}
								/>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="fromEmail" className="form__group__label">
										From Email
									</label>
								</div>
								<input
									name="fromEmail"
									id="fromEmail"
									type="email"
									disabled={true}
									className="input input--med"
									value="noreply@solapayments.com"
									autoComplete="one-time-code"
								/>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="fromCompany" className="form__group__label">
										From Company
									</label>
								</div>
								<input
									name="fromCompany"
									id="fromCompany"
									type="text"
									className="input input--med"
									value={fromCompany}
									onChange={this.handleChange}
								/>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="subject" className="form__group__label">
										Subject
									</label>
								</div>
								<input
									name="subject"
									id="subject"
									type="text"
									className="input input--med"
									value={subject}
									onChange={this.handleChange}
								/>
							</div>
							<div className="form__group">
								{!isContentValid && this.renderInvalidContent()}
								<textarea
									name="content"
									id="content"
									className={contentClassName}
									value={content}
									onChange={this.handleChange}
								/>
							</div>
						</Fragment>
					)}
				</div>
				<div className="modal__footer">
					<button
						type="button"
						tabIndex="-1"
						className="btn btn--med btn--primary"
						onClick={this.sendEmail}
						disabled={disabled}
					>
						Send Email
					</button>
				</div>
			</Fragment>
		);
	}
}

SendEmailPopup.defaultProps = {
	toEmail: '',
	newPaymentLink: '',
	paymentSiteName: '',
	paymentFields: { billFirstName: '', billLastName: '', invoice: '' },
	sendPaymentRequestManagement: {},
};

SendEmailPopup.propTypes = {
	toEmail: string,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	closeModal: func.isRequired,
	addNotification: func.isRequired,
	newPaymentLink: string,
	paymentSiteName: string,
	paymentFields: object,
	sendPaymentRequestManagement: object,
	files: object,
	setLoading: func,
};

export default withCancelable(withLoader(SendEmailPopup));
