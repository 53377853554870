import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, toLower, endsWith, isEmpty, trim } from 'lodash';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from '../../../utilities';
import { withError } from '../../error';
import { withCancelable } from '../../cancelable';

class AddEditTemplateForm extends Component {
	constructor(props) {
		super(props);

		this.amountRef = createRef();
		this.scheduleNameRef = createRef();

		this.state = {
			isLoading: false,
			errorMessages: [],
			formSubmitted: false,
			isScheduleNameValid: true,
			template: {
				xScheduleName: '',
				amount: '',
				xIntervalCount: '',
				xIntervalType: 'month',
				xTotalPayments: '',
				xDescription: '',
				xSkipSab: false,
			},
		};
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency();
	}

	componentDidMount = () => {
		const { template } = this.props;
		if (!isEmpty(template)) {
			this.setState({ template });
		}
		if (this.scheduleNameRef.current) {
			this.scheduleNameRef.current.focus();
		}
	};

	onChange = ({ target: { name, value, checked, type } }) => {
		this.setState(
			{
				template: {
					...this.state.template,
					[name]: type === 'checkbox' ? checked : value,
				},
			},
			this.validateInputs
		);
	};

	handleNumberOfPaymentsChange = ({ value }) => {
		this.setState({
			template: {
				...this.state.template,
				xTotalPayments: value,
			},
		});
	};

	handleAmountChange = ({ value }) => {
		this.setState(
			{
				template: {
					...this.state.template,
					amount: value,
				},
			},
			this.validateInputs
		);
		if (this.amountRef && !endsWith(value, '.')) {
			this.amountRef.blur();
			this.amountRef.focus();
		}
	};

	handleSave = async () => {
		try {
			const { template, isLoading } = this.state;
			if (isLoading) {
				return;
			}
			if (this.validateInputs(true)) {
				this.setState({ isLoading: true });
				await this.props.makePendingRequest(this.props.saveTemplate(template, template.xKey || Date.now()));
				this.props.handleCloseModal();
			}
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({ isLoading: false });
			}
		}
	};

	validateInputs = (isSaving = false) => {
		const {
			template: { xScheduleName },
			formSubmitted,
		} = this.state;
		const errorMessages = [];
		const isScheduleNameValid = trim(xScheduleName);

		if (formSubmitted || isSaving) {
			if (!isScheduleNameValid) {
				errorMessages.push('Schedule name is required');
			}
			const isErrorsEmpty = isEmpty(errorMessages);

			this.setState({
				errorMessages,
				isScheduleNameValid,
				formSubmitted: true,
			});
			return isErrorsEmpty;
		}
	};

	render = () => {
		const { errorMessages, isLoading, template, isScheduleNameValid } = this.state;
		const { type } = this.props;

		return (
			<React.Fragment>
				<div className="modal__header">
					<h4 className="modal__header__title spc--bottom--med">{`${
						type === 'add' ? 'New' : 'Edit'
					} Schedule Template`}</h4>
				</div>
				<div className="modal__body" tabIndex="-1">
					<div className="notes notes--primary spc--bottom--lrg">
						<i className="icon"></i>
						<p className="type--p3">
							Changes made here do not affect recurring schedules that currently use this template.
						</p>
					</div>
					{isLoading ? (
						<div className="loader__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<Fragment>
							{map(errorMessages, (errorMessage, index) => (
								<p className="type--validation" key={index}>
									{errorMessage}
								</p>
							))}

							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="xScheduleName" className="form__group__label">
										Schedule Name
									</label>
									<span className="form__group__required" data-tooltip="Required">
										*
									</span>
								</div>
								<input
									type="text"
									id="xScheduleName"
									name="xScheduleName"
									className={`input input--med ${!isScheduleNameValid && 'is-invalid'}`}
									placeholder="Schedule name"
									value={template.xScheduleName || ''}
									onChange={this.onChange}
									ref={this.scheduleNameRef}
									autoFocus={true}
								/>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="amount" className="form__group__label">
										Amount
									</label>
								</div>
								<NumberFormat
									className="input input--med"
									prefix={this.currencyCode}
									value={template.amount || ''}
									inputMode="decimal"
									onValueChange={this.handleAmountChange}
									name="amount"
									id="amount"
									placeholder={`${this.currencyCode}0`}
									thousandSeparator=","
									decimalSeparator="."
									allowNegative={false}
									decimalScale={2}
									getInputRef={el => (this.amountRef = el)}
								/>
							</div>

							<div className="flex--primary flex--gap--tny spc--top--sml spc--bottom--sml--alt">
								<h5>Frequency</h5>
								<i className="icon icon--tny icon--regular--info" data-tooltip="Interval type"></i>
							</div>
							<div className="flex--primary flex--bottom flex--gap--sml--alt flex--nowrap form__group">
								<div className="w--120">
									<div className="form__group__header">
										<label htmlFor="xIntervalCount" className="form__group__label">
											Every
										</label>
									</div>
									<input
										type="number"
										min="1"
										step="1"
										id="xIntervalCount"
										name="xIntervalCount"
										className="input input--med"
										placeholder="0"
										value={template.xIntervalCount || ''}
										onChange={this.onChange}
									/>
								</div>
								<select
									id="xIntervalType"
									name="xIntervalType"
									className="input input--select input--med"
									value={toLower(template.xIntervalType) || ''}
									onChange={this.onChange}
								>
									<option value="day">Day(s)</option>
									<option value="week">Week(s)</option>
									<option value="month">Month(s)</option>
									<option value="year">Year(s)</option>
								</select>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="xTotalPayments" className="form__group__label">
										Number of Payments
									</label>
								</div>
								<NumberFormat
									id="xTotalPayments"
									value={template.xTotalPayments || ''}
									onValueChange={this.handleNumberOfPaymentsChange}
									format="###"
									placeholder="Indefinite"
									className="input input--med"
									name="xTotalPayments"
									inputMode="numeric"
								/>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="xDescription" className="form__group__label">
										Description
									</label>
								</div>
								<input
									type="text"
									id="xDescription"
									name="xDescription"
									className="input input--med"
									placeholder="Description"
									value={template.xDescription || ''}
									onChange={this.onChange}
								/>
							</div>
							<div>
								<input
									type="checkbox"
									id="xSkipSab"
									name="xSkipSab"
									className="input--check"
									value={template.xSkipSab || ''}
									checked={!!template.xSkipSab}
									onChange={this.onChange}
								/>
								<label htmlFor="xSkipSab">Skip Sabbath and Holidays</label>
							</div>
						</Fragment>
					)}
				</div>
				<div className="modal__footer">
					<button
						disabled={isLoading}
						type="button"
						tabIndex="-1"
						className="btn btn--med btn--primary"
						onClick={this.handleSave}
					>
						Save
					</button>
				</div>
			</React.Fragment>
		);
	};
}

AddEditTemplateForm.propTypes = {
	template: PropTypes.object.isRequired,
	saveTemplate: PropTypes.func.isRequired,
	handleCloseModal: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	type: PropTypes.any,
};

export default withCancelable(withError(AddEditTemplateForm));
