import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, replace, get } from 'lodash';

import { principalService } from 'common/services';
import { logger } from 'common/utilities';
import PrivacyPolicyFooter from 'common/components/footer/PrivacyPolicyFooter';

class ConfirmRegistrationComponent extends Component {
	constructor(props) {
		super(props);

		const username = (props.location.state && props.location.state.username) || '';
		const resendCodeOnLoad = get(props, 'location.state.resendCodeOnLoad', false);

		this.state = {
			username: username,
			code: '',
			errorMessage: null,
			successMessage: null,
			resendCodeOnLoad,
		};
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
		if (this.state.resendCodeOnLoad) {
			this.resendCode();
		}
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: replace(event.target.value, /\s/g, ''),
		});
	};

	handleSubmit = async event => {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		const { history } = this.props;
		let { username, code } = this.state;
		username = trim(toLower(username));
		code = trim(code);

		try {
			const data = await Auth.confirmSignUp(username, code, {
				forceAliasCreation: true,
			});
			if (data === 'SUCCESS') {
				history.push({
					pathname: '/login',
					state: { username: username },
				});
			} else {
				logger.logError({ message: 'Confirm sign up error.' });
			}
		} catch (err) {
			logger.logError({
				message: 'Confirm sign up error.',
				errorDetails: err,
				username,
			});

			let message;
			switch (err && err.code) {
				case 'AliasExistsException': {
					message = 'An account with this email already exists.';
					break;
				}
				case 'CodeMismatchException': {
					message = 'The provided code does not match the expected value.';
					break;
				}
				case 'ExpiredCodeException': {
					message = 'The provided code has expired.';
					break;
				}
				case 'InvalidParameterException': {
					message = err.message;
					break;
				}
				case 'TooManyFailedAttemptsException': {
					message = 'You have made too many failed attempts.';
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'NotAuthorizedException': {
					message = 'User is already confirmed.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({
				errorMessage: message,
				successMessage: null,
			});
		}
	};

	formValidation = () => {
		const { username, code } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
				successMessage: null,
			});
			return false;
		}

		if (code.length <= 0) {
			this.setState({
				errorMessage: 'Please enter confirmation code sent to your email',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	redirectToLogin = e => {
		e.preventDefault();
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	resendCode = async e => {
		if (e) {
			e.preventDefault();
		}
		const { username } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
				successMessage: null,
			});
			return false;
		}

		try {
			await Auth.resendSignUp(username);
			if (e) {
				this.setState({
					errorMessage: null,
					successMessage: 'Code resent successfully',
				});
			}
		} catch (err) {
			logger.logError({
				message: 'Code resend error.',
				errorDetails: err,
				username,
			});
			let message;
			switch (err && err.code) {
				case 'CodeDeliveryFailureException': {
					message = 'Code failed to deliver succefully.';
					break;
				}
				case 'LimitExceededException': {
					message = 'Limit exceeded for requested resource.';
					break;
				}
				case 'InvalidParameterException': {
					message = err.message;
					break;
				}
				case 'TooManyRequestsException': {
					message = 'You have made too many resend attempts.';
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'NotAuthorizedException': {
					message = 'User is already confirmed.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({
				errorMessage: message,
				successMessage: null,
			});
		}
	};

	render() {
		const { username, code, errorMessage, successMessage } = this.state;

		return (
			<Fragment>
				<form className="auth__form" onSubmit={this.handleSubmit}>
					<h2 className="auth__form__title">Confirm registration</h2>
					<div className="notes notes--primary spc--bottom--lrg">
						<i className="icon"></i>
						<div>
							<p className="type--p2 type--p2--medium spc--bottom--sml">Thank you for registering with SOLA.</p>
							<p className="type--p3">
								Please check your email for a message from Cardknox with instructions about how to complete your
								registration.
							</p>
						</div>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Email</span>
						</div>
						<input
							name="username"
							type="email"
							className="input input--med"
							placeholder="user@gmail.com"
							value={username}
							onChange={this.handleChange}
							inputMode="email"
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Confirmation code</span>
						</div>
						<input
							name="code"
							type="text"
							className="input input--med"
							placeholder="000000"
							value={code}
							onChange={this.handleChange}
						/>
					</div>
					{errorMessage ? <div className="spc--bottom--med type--validation">{errorMessage}</div> : null}
					{successMessage ? <div className="spc--bottom--med type--color--success">{successMessage}</div> : null}
					<button type="submit" className="btn btn--lrg btn--primary spc--bottom--sml--alt">
						Confirm registration
					</button>
					<button onClick={this.resendCode} className="btn btn--lrg btn--secondary spc--bottom--lrg--alt">
						Resend code
					</button>
					<div className="auth__form__create-pass">
						<p>Do you have an account?</p>
						<button onClick={this.redirectToLogin} className="btn btn--sml btn--link btn--link--underline">
							Log in
						</button>
					</div>
				</form>
				<PrivacyPolicyFooter />
			</Fragment>
		);
	}
}

ConfirmRegistrationComponent.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
};

export default ConfirmRegistrationComponent;
