import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { trim, split, toLower, times, includes, isArray, get, padStart, isEmpty, uniqueId } from 'lodash';
import NumberFormat from 'react-number-format';

import { ReactToPrint } from '../react-to-print';
import { transactionService, kvaasService, logoManagementService } from '../../services';
import { CurrencyMap, renderIf, kvaasResources, mapAvsItem, invokeIfFunction } from '../../../Common/utilities';
import PaymentTransactionTypes from '../../utilities/paymentTransactionTypes';
import { WrappedPrintTransactionReceiptComponent as PrintTransactionReceiptComponent } from './printReceipt';
import { Notification } from '../notifications';
import { withCancelable } from '../../../Common/components/cancelable';
import { withError } from '../error';
import renderCustomFields from './printUtils/printUtils';
import { exportService } from '../export/exportService';

const requestKeys = {
	LOGO: 'logo',
	FETCH: 'fetch',
};

class TransactionReceiptComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			relatedData: null,
			transactionStatus: null,
			isLoading: true,
			showShareEmail: false,
			shareEmail: '',
			isPrinting: false,
			logoUrl: null,
			showTransactionInfo: true,
			showTransactionDetails: true,
			showAdditionalInfo: true,
		};

		this.emailNotification = createRef();
		this.setState = this.setState.bind(this);
	}

	get currency() {
		return this.state.data && this.state.data.currency;
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	get customFields() {
		const { data, originalCustomKey, convenienceCustomKey } = this.state;
		const customFields = [];
		if (data) {
			times(19, i => {
				const oneBasedIndex = padStart(i + 1, 2, 0);

				const key = `xCustom${oneBasedIndex}`;
				if (key !== originalCustomKey && key !== convenienceCustomKey) {
					customFields.push(data[key]);
				}
			});
		}
		return customFields;
	}

	showLoader = value => {
		invokeIfFunction(this.props.setLoading, value);
	};

	async componentDidMount() {
		const { refNum, xMerchantId, makePendingRequest } = this.props;

		this.showLoader(true);
		try {
			const [
				{ data, related, ...status },
				[convenienceFees, logoManagement, customDisplayLabels],
			] = await makePendingRequest(
				Promise.all([
					transactionService.getTransactionExtended(refNum, xMerchantId),
					kvaasService.get(
						kvaasResources.convenienceFees,
						kvaasResources.logoManagement,
						kvaasResources.transactionDisplayLabels
					),
				]),
				requestKeys.FETCH
			);
			const newState = {
				data,
				relatedData: related,
				transactionStatus: status,
				isLoading: false,
				customDisplayLabels: customDisplayLabels.data,
			};

			if (get(logoManagement, 'data.includeCoBrandLogoOnReceipts', false)) {
				newState.logoUrl = await this.fetchLogo();
			}

			if (convenienceFees && convenienceFees.data) {
				const { originalCustomKey, convenienceCustomKey } = convenienceFees.data;
				newState.originalCustomKey = originalCustomKey ? `x${originalCustomKey}` : '';
				newState.convenienceCustomKey = convenienceCustomKey ? `x${convenienceCustomKey}` : '';
			}
			if (data) {
				data.convenienceCustomKey = newState.convenienceCustomKey;
				data.originalCustomKey = newState.originalCustomKey;
			}

			this.setState(newState);
			this.showLoader(false);
		} catch (e) {
			this.onCatchReceiptError(e);
		}
	}

	onCatchReceiptError = e => {
		const emailNotification = get(this.emailNotification, 'current');
		const error = this.props.handleError(e, { delayMessage: true });
		const notificationRef = this.props.notificationRef || emailNotification;
		if (error && notificationRef) {
			this.handleEmailNotificationVisible();
			notificationRef.addNotification({
				...error,
				onClose: this.closeModal,
			});
			if (!isEmpty(notificationRef)) {
				this.closeModal();
			}
		}
	};

	fetchLogo = async () => {
		const { LogoUrl: logoUrl } = await this.props.makePendingRequest(
			logoManagementService.getLogoUrl(),
			requestKeys.LOGO
		);
		return logoUrl && `${logoUrl}?${Date.now()}`;
	};

	toggleSection = section => {
		this.setState(prevState => ({
			[section]: !prevState[section],
		}));
	};

	hasAnyInfoValues = (...values) => {
		let hasValues = false;
		for (let item of values) {
			if (isArray(item)) {
				for (let i of item) {
					if (i) {
						hasValues = true;
						break;
					}
				}
			} else {
				if (item) {
					hasValues = true;
					break;
				}
			}
		}
		return hasValues;
	};

	handlePrintError = (_, e) => {
		const emailNotification = get(this.emailNotification, 'current');
		const error = this.props.handleError(e, { delayMessage: true });
		const notificationRef = this.props.notificationRef || emailNotification;
		if (error && notificationRef) {
			this.handleEmailNotificationVisible();
			notificationRef.addNotification({
				...error,
				onClose: this.handleEmailNotificationVisible,
			});
		}
		this.setState({ isPrinting: false });
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false }, this.closeModal);
	};

	handleOnBeforeGetContent = () => {
		return new Promise(resolve => {
			this.setState({ isPrinting: true }, resolve);
		});
	};
	renderCustomReceiptItem = (name, values) => {
		values = [values];
		if (name && values) {
			return this.renderItem(name, values);
		}
	};

	renderItem = (name, values) => {
		return (
			<div className="f-col f-col-sml-12 f-col-med-6 form__group">
				<div className="form__group__header">
					<label className="form__group__label">{name}</label>
				</div>
				{this.renderReceiptMultipleValues(name, values)}
			</div>
		);
	};

	renderReceiptItem = (name, ...values) => {
		if (name && values.length && this.hasAnyInfoValues(...values)) {
			return this.renderItem(name, values);
		}
	};

	renderReceiptMultipleValues = (name, values) => {
		return values.map(value => (
			<div key={uniqueId(value)} className="type--p2">
				{includes(['electronic transfer fee', 'original amount', 'service fee', 'tax'], toLower(name))
					? this.renderNumberFormat(value)
					: value}
			</div>
		));
	};

	renderImage = (name, src) => {
		if (name && src) {
			return (
				<div className="f-col f-col-sml-4 spc--bottom--med">
					<div className="field__label spc--bottom--tny">{name}</div>
					<img src={'data:image/png;base64,' + src}></img>
				</div>
			);
		}
	};

	formatCcExpDate = d => {
		if (d && d.length === 4) {
			let date = d.split('', 4);
			date.splice(2, 0, '/');
			return date.join('');
		} else {
			return d;
		}
	};

	formatPhoneNumber = p => {
		const number = p ? p : '';
		return number && <span>{number}</span>;
	};

	handleShowEmailSend = () => {
		this.setState({ showShareEmail: !this.state.showShareEmail });
	};

	handleEmailChange = e => {
		let val = e.target.value;
		this.setState({ shareEmail: val });
	};

	handleEmailNotificationVisible = () => {
		this.props.handleEmailNotificationVisible();
	};

	closeModal = () => {
		if (this.props.closeModal != null) {
			this.props.closeModal();
		}
	};

	handleEmailSend = async () => {
		const { refNum, xMerchantId } = this.props;

		const notificationRef = this.props.notificationRef || get(this.emailNotification, 'current');
		const addNotification = get(notificationRef, 'addNotification');
		try {
			if (this.state.shareEmail.indexOf('@') > 0) {
				this.showLoader(true);
				const { xRecordsEmailed, xRefNum } = await transactionService.sendReceipt(
					this.state.shareEmail,
					refNum,
					xMerchantId
				);
				this.handleShowEmailSend();
				this.handleEmailNotificationVisible();
				let success = parseInt(xRecordsEmailed) > 0;
				this.showLoader(false);
				addNotification({
					message: success ? 'Transaction receipt was emailed successfully' : 'Error sending receipt',
					ref: xRefNum,
					success: success,
					onClose: !get(this.emailNotification, 'current') ? this.closeModal : this.handleEmailNotificationVisible,
				});
			}
		} catch (e) {
			if (!e || !e.isCanceled) {
				this.showLoader(false);
				this.handleShowEmailSend();
				this.handleEmailNotificationVisible();
				addNotification({
					message: (e && e.message) || 'Error sending receipt',
					ref: e && e.ref,
					success: false,
					forceCloseHandler: true,
					onClose: this.handleEmailNotificationVisible,
				});
			}
		}
	};

	renderNumberFormat = value => {
		return (
			<NumberFormat
				prefix={this.currencyCode}
				value={value}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);
	};

	renderTotal = () => {
		const data = { ...this.state.data };
		let amount = data.xVoid === '1' ? data.xRequestAmount : data.xAmount;
		if (exportService.shouldSubtract(data.xCommand)) {
			amount = -Math.abs(data.xAmount);
		}

		let value = (
			<NumberFormat
				prefix={this.currencyCode}
				value={amount}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);

		if (data.xVoid === '1') {
			value = <span className="type--linethrough">{value}</span>;
		}

		return value;
	};

	renderStatus = () => {
		const { transactionStatus } = this.state;
		let statusImage = null;

		if (transactionStatus.isVoid) {
			statusImage = <img src="/static/media/tags/void.svg" className="w--48p spc--top--tny" />;
		} else if (transactionStatus.isRefund) {
			statusImage = <img src="/static/media/tags/refund.svg" className="w--48p spc--top--tny" />;
		}

		if (statusImage !== null) {
			return (
				<div className="col col-sml-12 col-lrg-6 field">
					<div className="field__label">Status</div>
					<div>{statusImage}</div>
				</div>
			);
		}
		return null;
	};

	renderBody = () => {
		const { data, originalCustomKey, convenienceCustomKey, customDisplayLabels } = this.state;
		const [paymentType, transactionType] = split(data && toLower(data.xCommand), ':');
		let avs = mapAvsItem(data && data.xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}

		return (
			<Fragment>
				{this.state.isLoading ? (
					<div className="loader__holder">
						<div className="loader__spinner"></div>
					</div>
				) : (
					<Fragment>
						<div className="card card--lrg card--ref-number-info spc--bottom--lrg">
							<div>
								<span className="type--title type--uppercase spc--bottom--sml">Account</span>
								<p className="type--p3">{data.xMerchantName}</p>
							</div>
							<div>
								<span className="type--title type--uppercase spc--bottom--sml">Address</span>
								<p className="type--p3">{data.xMerchantStreet}</p>
								<p className="type--p3">
									{data.xMerchantCity} {data.xMerchantState} {data.xMerchantZip}
								</p>
							</div>
							<div>
								<span className="type--title type--uppercase spc--bottom--sml">Address</span>
								<p className="type--p3">{this.formatPhoneNumber(data.xMerchantPhone)}</p>
							</div>
						</div>
						<div className="info-panel info-panel--modal">
							<button
								className="info-panel__heading__wrapper is-expandable"
								onClick={() => this.toggleSection('showTransactionInfo')}
							>
								<h6 className="info-panel__heading">Transaction Information</h6>
								<i
									className={`icon icon--sml icon--chevron--${
										this.state.showTransactionInfo ? 'top' : 'down'
									}--primary`}
								></i>
							</button>
							{this.state.showTransactionInfo && (
								<div className="info-panel__section">
									<div className="spc--bottom--lrg">
										<span className="type--p2 type--p2--medium type--color--text--secondary">Amount</span>
										<div className="flex--primary flex--gap--sml">
											<h3>{this.renderTotal()}</h3>
											{this.renderStatus()}
										</div>
									</div>
									<p className="type--p2 type--p2--medium spc--bottom--lrg">Billing Information</p>
									<div className="f-row">
										{this.renderReceiptItem(
											'Billing information',
											trim(`${data.xBillFirstName || ''} ${data.xBillLastName || ''}`),
											data.xBillCompany,
											data.xBillStreet,
											data.xBillCity,
											data.xBillState,
											data.xBillZip,
											data.xBillCountry,
											data.xEmail,
											this.formatPhoneNumber(data.xBillPhone)
										)}
										{this.renderReceiptItem(
											'Shipping information',
											trim(`${data.xShipFirstName || ''} ${data.xShipLastName || ''}`),
											data.xShipCompany,
											data.xShipStreet,
											data.xShipCity,
											data.xShipState,
											data.xShipZip,
											data.xShipCountry,
											this.formatPhoneNumber(data.xShipPhone)
										)}
										{this.renderReceiptItem(
											'Entered date',
											data.xEnteredDate.format(ApplicationSettings.displayDateTimeFormat)
										)}
										{this.renderReceiptItem('Invoice', data.xInvoice)}
									</div>
								</div>
							)}
						</div>
						<div className="info-panel info-panel--modal">
							<button
								className="info-panel__heading__wrapper is-expandable"
								onClick={() => this.toggleSection('showTransactionDetails')}
							>
								<h6 className="info-panel__heading">Transaction Details</h6>
								<i
									className={`icon icon--sml icon--chevron--${
										this.state.showTransactionDetails ? 'top' : 'down'
									}--primary`}
								></i>
							</button>
							{this.state.showTransactionDetails && (
								<div className="info-panel__section">
									{this.renderReceiptItem('Error', data.xResponseError)}
									<div className="f-row">
										<div className="f-col f-col-sml-12 f-col-med-6">
											{this.renderReceiptItem(
												'Transaction type',
												PaymentTransactionTypes.getTransactionType(transactionType)
											)}
											{this.renderReceiptItem('Payment type', PaymentTransactionTypes.getPaymentType(paymentType))}
											{this.renderReceiptItem('Card type', data.xCardType)}
											{this.renderReceiptItem(
												(PaymentTransactionTypes.getPaymentType(paymentType) === 'Credit Card' ? 'Card' : 'Account') +
													' number',
												data.xMaskedCardNumber && data.xMaskedCardNumber.includes('xxx')
													? `**** ${data.xMaskedCardNumber.slice(-4)}`
													: data.xMaskedCardNumber
											)}
										</div>
										<div className="f-col f-col-sml-12 f-col-med-6">
											{this.renderReceiptItem('Transaction entry method', data.xEntryMethod)}
											{this.renderReceiptItem('AVS', avs)}
											{this.renderReceiptItem('AVS Street', data.xStreet)}
											{this.renderReceiptItem('AVS ZIP', data.xZip)}
											{this.renderImage('Signature', data.xSignature)}
											{renderIf(PaymentTransactionTypes.getPaymentType(paymentType) === 'Check')(
												this.renderReceiptItem('Account name', data.xName)
											)}

											{this.renderReceiptItem('Card expiration', this.formatCcExpDate(data.xExp))}
											{this.renderReceiptItem('Ref #', data.xRefNum)}
											{this.renderReceiptItem('Authorization Code', data.xResponseAuthCode)}
											{this.renderReceiptItem('Order Id', data.xOrderID)}
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="info-panel info-panel--modal">
							<button
								className="info-panel__heading__wrapper is-expandable"
								onClick={() => this.toggleSection('showAdditionalInfo')}
							>
								<h6 className="info-panel__heading">Additional info</h6>
								<i
									className={`icon icon--sml icon--chevron--${this.state.showAdditionalInfo ? 'top' : 'down'}--primary`}
								></i>
							</button>
							{this.state.showAdditionalInfo && (
								<div className="info-panel__section">
									{this.hasAnyInfoValues(
										data.xTax,
										data.xServiceFee,
										data.xDescription,
										data.xPONum,
										...this.customFields,
										data[convenienceCustomKey],
										data[originalCustomKey]
									) ? (
										<div className="f-row">
											<div className="f-col f-col-sml-12 f-col-med-6">
												{this.renderReceiptItem('Tax', data.xTax)}
												{this.renderReceiptItem('Service Fee', data.xServiceFee)}
												{renderCustomFields(data, customDisplayLabels, this.renderCustomReceiptItem, true)}
											</div>
											<div className="f-col f-col-sml-12 f-col-med-6">
												{this.renderReceiptItem('Description', data.xDescription)}
												{this.renderReceiptItem('PO Number', data.xPONum)}
											</div>
										</div>
									) : (
										''
									)}
								</div>
							)}
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderHeader = () => {
		const { refNum, isCashSale } = this.props;
		const {
			isPrinting,
			data,
			originalCustomKey,
			convenienceCustomKey,
			customDisplayLabels,
			isLoading,
			showShareEmail,
			transactionStatus,
			logoUrl,
		} = this.state;
		const printData = { ...data, originalCustomKey, convenienceCustomKey, customDisplayLabels };

		return (
			<div className="modal__header">
				<div className="modal__header__title">{'Ref Number ' + refNum}</div>
				{!isLoading && !isCashSale && (
					<Fragment>
						<div className="modal__header__actions">
							<ReactToPrint
								trigger={() => (
									<button
										disabled={isPrinting}
										className="btn btn--action btn--action--secondary datatooltip--auto datatooltip--down"
										data-tooltip="Print transaction receipt"
									>
										<i className="icon icon--sml icon--print" />
									</button>
								)}
								content={() => this.print}
								onPrintError={this.handlePrintError}
								onAfterPrint={this.handleAfterPrint}
								onBeforeGetContent={this.handleOnBeforeGetContent}
							/>
							{this.renderEmailButton()}
						</div>
						{renderIf(showShareEmail)(
							<div className="flex--primary flex--gap--sml flex--nowrap spc--top--med fullwidth">
								<input
									type="text"
									name="email"
									className="input input--med"
									placeholder="E-mail"
									onChange={this.handleEmailChange}
								/>
								<button onClick={this.handleEmailSend} className="btn btn--med btn--primary">
									Send
								</button>
							</div>
						)}
					</Fragment>
				)}
				<span style={{ display: 'none' }}>
					{renderIf(data && !isLoading)(
						<PrintTransactionReceiptComponent
							ref={el => (this.print = el)}
							data={{ ...printData, ...transactionStatus, customDisplayLabels }}
							logoUrl={logoUrl}
						/>
					)}
				</span>
			</div>
		);
	};

	renderEmailButton = () => {
		const { data } = this.state;
		const transactionType = split(toLower(get(data, 'xCommand')), ':')[1];

		return (
			!includes(['splitcapture', 'save'], transactionType) && (
				<button
					onClick={this.handleShowEmailSend}
					className="btn btn--action btn--action--secondary datatooltip--auto datatooltip--down-left"
					data-tooltip="Email transaction receipt"
				>
					<i className="icon icon--sml icon--mail" />
				</button>
			)
		);
	};

	render() {
		const { emailNotificationVisible } = this.props;

		return (
			<React.Fragment>
				<Notification style={{ maxWidth: 'none' }} ref={this.emailNotification} />
				{emailNotificationVisible ? null : (
					<div className="type--left">
						{this.renderHeader()}
						<div className="modal__body">{this.renderBody()}</div>
						<div className="modal__footer"></div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

TransactionReceiptComponent.propTypes = {
	refNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	closeModal: PropTypes.func,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	notificationRef: PropTypes.any,
	emailNotificationVisible: PropTypes.bool.isRequired,
	handleEmailNotificationVisible: PropTypes.func.isRequired,
	xMerchantId: PropTypes.any,
	isCashSale: PropTypes.bool.isRequired,
	setLoading: PropTypes.func,
};

export default withError(withCancelable(TransactionReceiptComponent));
