import React, { Component } from 'react';
import { func, string } from 'prop-types';

import { withCancelable } from '../cancelable';
import { messagingService, principalService } from 'common/services';
import { validators } from 'common/fields';
import { endsWith } from 'lodash';
import { invokeIfFunction } from 'common/utilities';

class SendSmsPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isContentValid: true,
			phoneNumber: props.phoneNumber,
		};
	}

	handleChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value,
		});
	};

	showLoader = (isLoading = false) => {
		invokeIfFunction(this.props.setLoading, isLoading);
		this.setState({ isLoading });
	};

	sendSms = async () => {
		const {
			closeModal,
			addNotification,
			handleError,
			makePendingRequest,
			paymentSiteName,
			newPaymentLink,
		} = this.props;
		const { phoneNumber } = this.state;

		this.showLoader(true);

		const paymentSiteLink = `${paymentSiteName}${newPaymentLink}${
			!paymentSiteName && !newPaymentLink ? 'exampleLink' : ''
		}`;
		try {
			await makePendingRequest(
				messagingService.sendPaymentRequest(phoneNumber, principalService.get().companyName, paymentSiteLink)
			);

			await addNotification({
				message: 'SMS sent successfully.',
				success: true,
			});
		} catch (e) {
			const notification = handleError(e, { delayMessage: true });
			if (endsWith(notification.message, 'number')) {
				notification.message = 'The phone number entered is invalid.';
			}

			if (notification) {
				notification.show();
			}
		}
		this.showLoader();
		closeModal();
	};

	render() {
		const { phoneNumber, isLoading } = this.state;
		const isPhoneNumberValid = !!phoneNumber && validators.phoneNumber(phoneNumber);

		const disabled = !isPhoneNumberValid || isLoading;

		return (
			<div className="modal">
				<div className="modal__header">
					<h4 className="modal__header__title">Send Payment Request</h4>
				</div>
				<div className="modal__body">
					{isLoading ? (
						<div className="loader--modal__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<div className="form__group">
							<div className="form__group__header">
								<label htmlFor="phoneNumber" className="form__group__label">
									Phone Number
								</label>
								<span data-tooltip="Required" className="form__group__required">
									*
								</span>
							</div>
							<input
								name="phoneNumber"
								id="phoneNumber"
								type="tel"
								className={`input input--med${isPhoneNumberValid ? '' : ' is-invalid'}`}
								value={phoneNumber}
								onChange={this.handleChange}
							/>
						</div>
					)}
				</div>
				<div className="modal__footer">
					<button
						type="button"
						tabIndex="-1"
						className="btn btn--med btn--primary"
						onClick={this.sendSms}
						disabled={disabled}
					>
						Send Text
					</button>
				</div>
			</div>
		);
	}
}

SendSmsPopup.propTypes = {
	phoneNumber: string,
	newPaymentLink: string,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	closeModal: func.isRequired,
	addNotification: func.isRequired,
	paymentSiteName: string,
	setLoading: func,
};

export default withCancelable(SendSmsPopup);
