import React, { Component, Fragment, createRef } from 'react';
import { get, noop, toLower } from 'lodash';
import { func } from 'prop-types';

import { emailService, principalService } from 'common/services';
import { Modal } from 'common/components/modal';
import { emailTemplates, hasFeaturePackage } from 'common/utilities';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { Notification } from 'common/components/notifications';
import { withLoader } from 'common/components/loader';
import { featurePackages } from 'common/utilities/has-feature-package';
import { plans } from 'common/utilities/plans';

const requestKeys = {
	SEND: 'send',
};

class UpgradePlan extends Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();
		const isTerminalOnly = hasFeaturePackage(featurePackages.terminalOnly);
		let yourPlan = plans.terminalOnly;

		if (!isTerminalOnly) {
			yourPlan = plans.starter;
		}

		this.state = {
			isFormOpen: false,
			isLoading: false,
			mid: get(principal, 'idInfo.xMerchantID', ''),
			dba: get(principal, 'idInfo.xMerchantDBA', ''),
			fromEmail: principal.email,
			isAdmin: toLower(principal.idInfo.permissions.role) === 'admin',
			yourPlan,
			isTerminalOnly,
			formPlan: {
				planName: '',
				setupFee: '',
				monthlyFee: '',
				transactionFee: '',
			},
		};

		this.notificationRef = createRef();
		this.upgradePlanContentRef = createRef();
	}

	handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

	sendEmail = async () => {
		try {
			const {
				formPlan: { planName },
				yourPlan,
			} = this.state;
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);

			this.props.showLoader(true);
			let templateParams = {};
			templateParams.template = {
				CurrentPlan: yourPlan.planName,
				NewPlan: planName,
			};
			const template = {
				TemplateName: emailTemplates.upgradePlan,
				...templateParams,
			};
			await this.props.makePendingRequest(emailService.sendEmail(template), requestKeys.SEND);
			addNotification({
				success: true,
				message: (
					<div>
						<p className="spc--bottom--sml">
							Thank you for your upgrade request. You will be receiving an email confirmation within the next 48 hours
							once your account has been successfully upgraded.
						</p>
						<p>—The Cardknox Team</p>
					</div>
				),
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.closeForm();
		this.props.showLoader();
	};

	openForm = formPlan => () => {
		this.setState({ isFormOpen: true, note: '', formPlan });
	};

	closeForm = () => {
		this.setState({ isFormOpen: false });
	};

	scrollToTable = () =>
		this.upgradePlanContentRef.current && this.upgradePlanContentRef.current.scrollIntoView({ behavior: 'smooth' });

	renderPopupForm = () => {
		const {
			isFormOpen,
			note,
			formPlan: { planName },
		} = this.state;

		return (
			<Modal
				isOpen={isFormOpen}
				onClose={this.closeForm}
				shouldCloseOnOverlayClick={false}
				overlayClassName="modal__overlay"
				className="modal__content"
			>
				<div>
					<div className="modal__header">
						<div className="modal__header__title">Request upgrade to {planName} plan</div>
					</div>
					<div className="modal__body">
						<div className="spc--bottom--sml">
							I understand that by submitting this upgrade request, the new transaction rate will be applied to all my
							transactions.
						</div>
						<div>
							<label htmlFor="note">Note</label>
							<input
								type="text"
								id="note"
								name="note"
								className="input input--med"
								placeholder="Note"
								value={note}
								onChange={this.handleChange}
							/>
						</div>
					</div>
					<div className="modal__footer">
						<button type="button" tabIndex="-1" className="btn btn--med btn--primary" onClick={this.sendEmail}>
							Send Request
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	renderTable = () => {
		const {
			isTerminalOnly,
			yourPlan: { monthlyFee, planName, setupFee, transactionFee },
			isAdmin,
		} = this.state;
		const { standard, premium } = plans;

		return (
			<Fragment>
				<table className="upgrade-plan__pricing__grid table">
					<colgroup className="tcolumn">
						<col className="col"></col>
						<col className="col current"></col>
						<col className="col"></col>
						<col className="col"></col>
					</colgroup>
					<thead className="thead">
						<tr className="trow">
							<th className="th empty"></th>
							<th className="th starter">{planName}</th>
							{!isTerminalOnly && <th className="th">{standard.planName}</th>}
							<th className="th">{premium.planName}</th>
						</tr>
					</thead>
					<tbody className="tbody">
						{isTerminalOnly && (
							<tr className="trow">
								<td className="td"></td>
								<td className="td">
									<span className="type--xsml type--uppercase type--wgt--medium type--color--text--regular">
										Setup fee
									</span>
									<p className="display--b type--xlrg">{setupFee}</p>
								</td>
								<td className="td">
									<span className="type--xsml type--uppercase type--wgt--medium type--color--text--regular">
										Setup fee
									</span>
									<p className="display--b type--xlrg">{premium.setupFee}</p>
								</td>
							</tr>
						)}
						<tr className="trow">
							<td className="td">
								Monthly Fee <span>Cardknox</span>
							</td>
							<td className="td starter type--xlrg">{monthlyFee}</td>
							{!isTerminalOnly && <td className="td type--xlrg">{standard.monthlyFee}</td>}
							<td className="td type--xlrg">{premium.monthlyFee}</td>
						</tr>
						<tr className="trow">
							<td className="td">Transaction</td>
							<td className="td starter type--xlrg">{transactionFee}</td>
							{!isTerminalOnly && <td className="td type--xlrg">{standard.transactionFee}</td>}
							<td className="td type--xlrg">{premium.transactionFee}</td>
						</tr>
						<tr className="trow">
							<td className="td">
								Non-Terminal Transaction <span>Cardknox Features</span>
							</td>
							<td className="td starter">
								<i className={`icon icon--sml icon--${isTerminalOnly ? 'not-' : ''}included icon--middle`}></i>
							</td>
							{!isTerminalOnly && (
								<td className="td">
									<i className="icon icon--sml icon--included icon--middle"></i>
								</td>
							)}
							<td className="td">
								<i className="icon icon--sml icon--included icon--middle"></i>
							</td>
						</tr>
						<tr className="trow">
							<td className="td">Custom Database + Recurring Schedules</td>
							<td className="td starter">
								<i className="icon icon--sml icon--not-included icon--middle"></i>
							</td>
							{!isTerminalOnly && (
								<td className="td">
									<i className="icon icon--sml icon--included icon--middle"></i>
								</td>
							)}
							<td className="td">
								<i className="icon icon--sml icon--included icon--middle"></i>
							</td>
						</tr>
						<tr className="trow">
							<td className="td">Intelligent Routing</td>
							<td className="td starter">
								<i className="icon icon--sml icon--not-included icon--middle"></i>
							</td>
							{!isTerminalOnly && (
								<td className="td">
									<i className="icon icon--sml icon--not-included icon--middle"></i>
								</td>
							)}
							<td className="td">
								<i className="icon icon--sml icon--included icon--middle"></i>
							</td>
						</tr>
						<tr className="trow">
							<td className="td">EMV</td>
							<td className="td starter">
								<i className={`icon icon--sml icon--${!isTerminalOnly ? 'not-' : ''}included icon--middle`}></i>
							</td>
							{!isTerminalOnly && (
								<td className="td">
									<i className={`icon icon--sml icon--${!isTerminalOnly ? 'not-' : ''}included icon--middle`}></i>
								</td>
							)}
							<td className="td">
								<i className="icon icon--sml icon--included icon--middle"></i>
							</td>
						</tr>
						<tr className="trow">
							<td className="td empty"></td>
							<td className="td starter">
								<button className="btn btn--med btn--primary" disabled>
									Your Plan
								</button>
							</td>
							{!isTerminalOnly && (
								<td className="td">
									<button
										className="btn btn--med btn--primary"
										onClick={this.openForm(plans.standard)}
										disabled={!isAdmin}
									>
										Upgrade Now
									</button>
								</td>
							)}
							<td className="td">
								<button
									className="btn btn--med btn--primary"
									onClick={this.openForm(plans.premium)}
									disabled={!isAdmin}
								>
									{isTerminalOnly ? 'Get Started' : 'Upgrade Now'}
								</button>
							</td>
						</tr>
					</tbody>
					<tfoot className="tfoot">
						<td></td>
						<td colSpan="3">
							<p className="spc--top--med">
								<span className="type--color--primary">*</span>For special pricing, such as volume discounts, please
								reach out to your Cardknox Sales Agent.
							</p>
						</td>
					</tfoot>
				</table>

				<div className="upgrade-plan__pricing__card__wrapper">
					<div className="upgrade-plan__pricing__card your-plan">
						<h4 className="upgrade-plan__pricing__card__title">{planName}</h4>

						<div className="upgrade-plan__pricing__card__separator cardknox"></div>
						<div className="upgrade-plan__pricing__card__price">
							<div className="row">
								<div className="col col-sml-6">
									<label>Mounthly Fee</label>
									<p className="type--wgt--bold type--med">{monthlyFee}</p>
								</div>
								<div className="col col-sml-6">
									<label>Transaction</label>
									<p className="type--wgt--bold type--med">{transactionFee}</p>
								</div>
							</div>
						</div>
						<div className="upgrade-plan__pricing__card__separator features"></div>

						<div className="upgrade-plan__pricing__card__item">
							<i
								className={`icon icon--sml icon--${isTerminalOnly ? 'not-' : ''}included icon--middle spc--right--sml`}
							></i>
							<label>Non-Terminal Transaction</label>
						</div>
						<div className="upgrade-plan__pricing__card__item">
							<i className="icon icon--sml icon--not-included icon--middle spc--right--sml"></i>
							<label>Custom Database + Recurring Schedules</label>
						</div>
						<div className="upgrade-plan__pricing__card__item">
							<i className="icon icon--sml icon--not-included icon--middle spc--right--sml"></i>
							<label>Intelligent Routing</label>
						</div>
						<div className="upgrade-plan__pricing__card__item">
							<i
								className={`icon icon--sml icon--${!isTerminalOnly ? 'not-' : ''}included icon--middle spc--right--sml`}
							></i>
							<label>EMV</label>
						</div>
						<div className="upgrade-plan__pricing__card__item center">
							<button className="btn btn--med btn--primary" disabled>
								Your Plan
							</button>
						</div>
					</div>

					{!isTerminalOnly && (
						<div className="upgrade-plan__pricing__card">
							<h4 className="upgrade-plan__pricing__card__title">{standard.planName}</h4>

							<div className="upgrade-plan__pricing__card__separator cardknox"></div>
							<div className="upgrade-plan__pricing__card__price">
								<div className="row">
									<div className="col col-sml-6">
										<label>Mounthly Fee</label>
										<p className="type--wgt--bold type--med">{standard.monthlyFee}</p>
									</div>
									<div className="col col-sml-6">
										<label>Transaction</label>
										<p className="type--wgt--bold type--med">{standard.transactionFee}</p>
									</div>
								</div>
							</div>
							<div className="upgrade-plan__pricing__card__separator features"></div>

							<div className="upgrade-plan__pricing__card__item">
								<i className="icon icon--sml icon--included icon--middle spc--right--sml"></i>
								<label>Non-Terminal Transaction</label>
							</div>
							<div className="upgrade-plan__pricing__card__item">
								<i className="icon icon--sml icon--included icon--middle spc--right--sml"></i>
								<label>Custom Database + Recurring Schedules</label>
							</div>
							<div className="upgrade-plan__pricing__card__item">
								<i className="icon icon--sml icon--not-included icon--middle spc--right--sml"></i>
								<label>Intelligent Routing</label>
							</div>
							<div className="upgrade-plan__pricing__card__item">
								<i
									className={`icon icon--sml icon--${
										!isTerminalOnly ? 'not-' : ''
									}included icon--middle spc--right--sml`}
								></i>
								<label>EMV</label>
							</div>
							<div className="upgrade-plan__pricing__card__item center">
								<button
									className="btn btn--med btn--primary"
									onClick={this.openForm(plans.standard)}
									disabled={!isAdmin}
								>
									Upgrade Now
								</button>
							</div>
						</div>
					)}

					<div className="upgrade-plan__pricing__card">
						<h4 className="upgrade-plan__pricing__card__title">Premium</h4>

						<div className="upgrade-plan__pricing__card__separator cardknox"></div>
						<div className="upgrade-plan__pricing__card__price">
							<div className="row">
								<div className="col col-sml-6">
									<label>Mounthly Fee</label>
									<p className="type--wgt--bold type--med">{premium.monthlyFee}</p>
								</div>
								<div className="col col-sml-6">
									<label>Transaction</label>
									<p className="type--wgt--bold type--med">{premium.transactionFee}</p>
								</div>
							</div>
						</div>
						<div className="upgrade-plan__pricing__card__separator features"></div>

						<div className="upgrade-plan__pricing__card__item">
							<i className="icon icon--sml icon--included icon--middle spc--right--sml"></i>
							<label>Non-Terminal Transaction</label>
						</div>
						<div className="upgrade-plan__pricing__card__item">
							<i className="icon icon--sml icon--included icon--middle spc--right--sml"></i>
							<label>Custom Database + Recurring Schedules</label>
						</div>
						<div className="upgrade-plan__pricing__card__item">
							<i className="icon icon--sml icon--included icon--middle spc--right--sml"></i>
							<label>Intelligent Routing</label>
						</div>
						<div className="upgrade-plan__pricing__card__item">
							<i className="icon icon--sml icon--included icon--middle spc--right--sml"></i>
							<label>EMV</label>
						</div>
						<div className="upgrade-plan__pricing__card__item center">
							<button className="btn btn--med btn--primary" onClick={this.openForm(plans.premium)} disabled={!isAdmin}>
								{isTerminalOnly ? 'Get Started' : 'Upgrade Now'}
							</button>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	render = () => (
		<div>
			<Notification ref={this.notificationRef} />
			{this.renderPopupForm()}
			<div className="upgrade-plan__hero">
				<div className="upgrade-plan__hero__header__wrapper">
					<div className="upgrade-plan__hero__header">
						<div className="upgrade-plan__hero__header__logo"></div>
					</div>
				</div>
				<div className="upgrade-plan__hero__content">
					<h1 className="upgrade-plan__hero__content__heading">
						Upgrade Now<br></br>
						to Enjoy Even More<br></br>
						Payment Features
					</h1>
					<p className="upgrade-plan__hero__content__text">
						Our powerful reporting capabilities are just the beginning. With the help of our user-friendly Recurring
						Schedule and Customers features, you’ll be able to securely store customer information and automate your
						billing process with unprecedented ease. Save your customers’ payment and billing information in the
						database, set default payment methods for your customers, create automated schedules that are fully
						customized with the desired transaction amount and frequency, and much more. No matter whether you primarily
						process one-time or repeat transactions, these powerful tools will be sure to free up your time and
						eliminate hassle.
					</p>
					<div className="upgrade-plan__hero__content__image"></div>
				</div>
			</div>
			<div className="upgrade-plan__content--angle__wrapper">
				<div className="upgrade-plan__content upgrade-plan__content--angle">
					<h1 className="type--xxxlrg type--wgt--regular spc--bottom--med">
						For more information on Portal account features and pricing, see below.
					</h1>
					<button onClick={this.scrollToTable} className="btn btn--lrg btn--primary type--wgt--bold">
						Upgrade Now
					</button>
				</div>
			</div>
			<div className="upgrade-plan__content--pricing">
				<div ref={this.upgradePlanContentRef} className="upgrade-plan__content">
					<h1 className="type--xxxlrg type--wgt--regular spc--bottom--xxxlrg">
						Cardknox Portal Plan Features and Pricing
					</h1>
					{!this.state.isAdmin && (
						<p className="message message--warning spc--bottom--lrg">
							To upgrade, contact your Cardknox Portal account administrator.
						</p>
					)}
					{this.renderTable()}
				</div>
			</div>
		</div>
	);
}

UpgradePlan.propTypes = {
	makePendingRequest: func.isRequired,
	handleError: func.isRequired,
	showLoader: func.isRequired,
};

export default withError(withLoader(withCancelable(UpgradePlan)));
