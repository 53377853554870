import React from 'react';

const DashboardBannerSola = () => {
	return (
		<div className="dashboard__message">
			<p className="type--p2">
				Your Portal,<span className="type--p2--medium"> Powered by Sola!</span> Enjoy a modern, intuitive experience
				with all the trusted features you rely on. Your login credentials, PaymentSITE links, and mobile app access
				remain unchanged. If you have questions we're here to help at&nbsp;
				<a
					className="btn btn--link align--v--baseline"
					href="mailto: gatewaysupport@solapayments.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					gatewaysupport@solapayments.com
				</a>
			</p>
		</div>
	);
};

export default DashboardBannerSola;
