import Amplify from 'aws-amplify';
import { getDomainName } from 'common/services/helper-service';

Amplify.configure({
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		//identityPoolId: 'eu-central-1:dc6b2c01-4851-41ec-9dda-617167bb74d1',

		// REQUIRED - Amazon Cognito Region
		region: 'us-west-2',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		identityPoolRegion: 'us-west-2',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-west-2_QnqRT3ms0',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '1tu11mscubdil1b7dg1psgrj6o', //'1t75foqp1qqvuv49eq07a99tf5',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,

		// OPTIONAL - Configuration for cookie storage
		cookieStorage: {
			// REQUIRED - Cookie domain (only required if cookieStorage is provided)
			domain: `.${getDomainName()}`,
			// OPTIONAL - Cookie path
			path: '/',
			// OPTIONAL - Cookie expiration in days
			expires: 90,
			// OPTIONAL - Cookie secure flag
			secure: true,
		},

		oauth: {
			domain: 'cardknoxdevsso.auth.us-west-2.amazoncognito.com',
			clientID: '1tu11mscubdil1b7dg1psgrj6o',
			redirectSignIn: `${window.location.origin}/saml-login`,
			redirectUri: `${window.location.origin}/saml-login`,
			audience: 'urn:amazon:cognito:sp:us-west-2_QnqRT3ms0',
			responseType: 'token', // id_token', // for now we only support implicit grant flow
			scope: ['email', 'openid', 'profile', 'phone', 'aws.cognito.signin.user.admin'], // the scope used by your app
			redirectSignOut: `${window.location.origin}/`,
		},

		//// OPTIONAL - customized storage object
		//storage: new MyStorage(),

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		//authenticationFlowType: 'USER_PASSWORD_AUTH'
	},
});
