import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';

import { merchantRegistrationService } from '../../services';
import { FormErrors, invokeIfFunction } from '../../utilities';

import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';

class RegisterApplePayCardknoxCertificate extends Component {
	constructor() {
		super();

		this.state = {
			domainAssociationFile: null,
			domainName: null,
			invalidDomainName: true,
			isLoading: false,
		};
	}

	validateInputs = () => {
		const { domainName } = this.state;
		let hasErrors = false;
		const newState = { errors: {} };
		if (!domainName) {
			newState.errors.domainName = 'Valid Domain Name is required';
			newState.invalidDomainName = true;
			hasErrors = true;
		} else {
			newState.invalidDomainName = false;
		}
		this.setState(newState);
		return hasErrors;
	};

	downloadDomainAssociationFile = async () => {
		if (this.state.isLoading) return;
		const addNotification = this.props.addNotification;
		let domainAssociationFile = this.state.domainAssociationFile;
		try {
			this.setState({ isLoading: true });
			invokeIfFunction(this.props.setLoading, true);
			if (!this.state.domainAssociationFile) {
				const domainData = await merchantRegistrationService.downloadAppleDomainAssociationFile();
				domainAssociationFile = get(domainData, 'domainAssociationFile', '');
				this.setState({ domainAssociationFile: domainAssociationFile });
			}
			const result = new Blob([Buffer.from(domainAssociationFile, 'base64')], {
				type: 'application/octet-stream',
			});
			saveAs(result, 'apple-developer-merchantid-domain-association');
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			addNotification({ ...notification, onClose: noop });
			this.props.closeModal();
		} finally {
			this.setState({ isLoading: false });
			invokeIfFunction(this.props.setLoading, false);
		}
	};

	registerDomain = async () => {
		const addNotification = this.props.addNotification;
		try {
			this.setState({ isLoading: true });
			invokeIfFunction(this.props.setLoading, true);

			const { domainName } = this.state;
			await merchantRegistrationService.registerAppleDomain(domainName);
			addNotification({
				message: `Domain '${domainName}' has been generated successfully!`,
				success: true,
			});
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			addNotification({ ...notification, onClose: noop });
		} finally {
			this.props.closeModal();
		}
	};

	setDomainName = e => {
		const newState = { domainName: e.target.value };
		this.setState(newState, this.validateInputs);
	};

	render = () => {
		const required = (
			<span className="form__group__required" data-tooltip="Required">
				*
			</span>
		);

		const { invalidDomainName, errors, isLoading } = this.state;
		const isRegisterDisabled = invalidDomainName || isLoading;

		return (
			<Fragment>
				<div className="modal__header">
					<h4 className="modal__title">Apple Pay Domain Registration</h4>
				</div>
				<div className="modal__body">
					{isLoading ? (
						<div className="loader--popup__holder type--center">
							<div className="spc--bottom--sml">Please wait, this may take a few seconds...</div>
							<div className="loader__spinner loader__spinner--apple-pay"></div>
						</div>
					) : (
						<Fragment>
							<div className="progress">
								<div className="progress__step">
									<div className="progress__step__aside"></div>
									<div className="progress__step__main">
										<p className="type--p2 spc--bottom--sml">Download required file</p>
										<p className="flex--primary flex--gap--tny type--p4 type--color--text--light">
											Download{' '}
											<button
												className="btn btn--link btn--link--underline"
												onClick={this.downloadDomainAssociationFile}
											>
												Apple developer association
											</button>{' '}
											file.
										</p>
									</div>
								</div>

								<div className="progress__step">
									<div className="progress__step__aside"></div>
									<div className="progress__step__main">
										<p className="type--p2 spc--bottom--sml">Upload the file under your domain</p>
										<p className="type--p4 spc--bottom--sml">
											Upload this file under <strong>https://yourDomain/.well-known/</strong>
											<br />
											Assuming your domain is <strong>www.mycompany.com</strong> the full URL will be
											<br />
											<strong>
												https://www.mycompany.com/.well-known/apple-developer-merchantid-domain-association
											</strong>
										</p>
									</div>
								</div>

								<div className="progress__step">
									<div className="progress__step__aside"></div>
									<div className="progress__step__main">
										<p className="type--p2 spc--bottom--sml">Register domain</p>
										<p className="type--p4 spc--bottom--sml--alt">
											Enter the domain name you would like to register and press <strong>Register Domain</strong>.
										</p>
										<div className="form__group__header">
											<label htmlFor="domainName" className="form__group__label">
												Domain Name {required}
											</label>
										</div>
										<input
											type="text"
											id="domainName"
											name="domainName"
											className="input input--med"
											onChange={this.setDomainName}
										/>
										<div>
											{!isEmpty(errors) && (
												<div className="spc--top--tny">
													<FormErrors errors={errors} />
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="notes notes--default">
								<p>
									<strong>Please note:</strong> This url must be publicly available. (No login required)
									<br />
									Once done, complete Step 3 by providing your Domain name in the text box
									<br />
									and clicking Register Domain button below in Step 3
								</p>
							</div>
						</Fragment>
					)}
				</div>
				<div className="modal__footer">
					<button
						type="button"
						className="btn btn--med btn--primary"
						onClick={this.registerDomain}
						disabled={isRegisterDisabled || isLoading}
					>
						Register Domain
					</button>
				</div>
			</Fragment>
		);
	};
}

RegisterApplePayCardknoxCertificate.propTypes = {
	handleError: PropTypes.func,
	addNotification: PropTypes.func,
	closeModal: PropTypes.func,
	setLoading: PropTypes.func,
};

export default RegisterApplePayCardknoxCertificate;
