import React from 'react';

const DashboardBannerCardknox = () => {
	return (
		<div className="dashboard__message">
			<div>
				<p className="type--p1 type--p1--medium spc--bottom--tny">Welcome to Sola!</p>
				<p className="type--p2">
					Starting March 18, 2025, the Merchant Portal will move to portal.solapayments.com. Your login details will
					remain the same, but be sure to update your bookmarks and password manager with the new URL
				</p>
			</div>
		</div>
	);
};

export default DashboardBannerCardknox;
