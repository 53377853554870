import React from 'react';
import PropTypes from 'prop-types';
const FormGroupPreview = ({ label, value }) => (
	<div className="grid-sidebar__field">
		<label className="type--p2 type--color--text--secondary">{label}</label>
		<p className={`type--p2 type--p2--medium ${value === '---' ? 'type--color--text--opaque' : ''}`}>{value}</p>
	</div>
);
FormGroupPreview.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	previewField: PropTypes.bool,
};

export default FormGroupPreview;
