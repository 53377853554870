import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { cloneDeep, filter, find, trim, get, keys } from 'lodash';
import moment from 'moment';
import Select from 'react-select';

import QuickReportsFilterComponent from './quick-reports-filter';
import ExpandFilterContainerComponent from './expand-filter-container';
import StatusReportsFilter from './StatusFilter';
import TransactionTypeReportsFilter from './TransactionTypeFilter';
import { predefinedDates, DatePickerPredefined } from 'common/components/date-picker';
import { TransactionsSortBy } from 'common/sorters/transactions-sort-options';
import { UserAccountPanel } from 'common/components/user-account-panel';
import { QuickReports } from './quick-reports';
import { principalService } from 'common/services';
import {
	NumberFilter,
	StringFilter,
	SelectFilterCustom,
	LastDigitsFilter,
	CardTypeFilter,
	CustomFieldsFilter,
} from 'common/components/filters';
import { OtherReports } from './other-reports';
import sectionKeys from 'routing/sections';

class MainFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.references = {};

		const sortOptions = this.getSortOptions();

		this.state = {
			activeKeys: [],
			isExpanded: false,
			transactionsSortBy: sortOptions[0],
			transactionStatusSelectedOptions: null,
			dates: cloneDeep(predefinedDates),
			editableFilter: '',
			permissions: {},
			userRole: null,
			expandedCards: {
				quickReports: true,
				customReports: true,
				otherReports: true,
			},
		};
	}

	componentDidMount() {
		const principal = principalService.get();
		this.references = {
			date: React.createRef(),
			status: React.createRef(),
			transactionType: React.createRef(),
			amount: React.createRef(),
			cardNumber: React.createRef(),
			cardholderName: React.createRef(),
			referenceNumber: React.createRef(),
			invoice: React.createRef(),
			cvv: React.createRef(),
			avs: React.createRef(),
			cardType: React.createRef(),
			batch: React.createRef(),
			description: React.createRef(),
			authCode: React.createRef(),
			terminal: React.createRef(),
			poNumber: React.createRef(),
			orderId: React.createRef(),
			custom: React.createRef(),
		};
		this.csRep = principal.hasAccess[sectionKeys.portalManagement];

		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
		const userRole = get(principal, 'idInfo.permissions.role');
		this.setState({ permissions, userRole });
	}

	componentDidUpdate = prevProps => {
		if (prevProps.transactionsSortBy !== this.props.transactionsSortBy) {
			const options = this.getSortOptions();
			const transactionsSortBy = find(options, ({ value }) => value === this.props.transactionsSortBy.key);
			if (transactionsSortBy) {
				this.setState({ transactionsSortBy });
			}
		}
	};

	updateFilters = (filterToUpdate, filterItem) => {
		const { isRadio, checkbox, type, values: filterValues, emptyValue } = filterToUpdate;
		let hasSelection = false;

		keys(filterValues).forEach(key => {
			const valueItem = isRadio ? filterItem.values[key] === key : filterItem.values.filter(item => item.key === key);

			if (valueItem.length > 0 || (isRadio && valueItem)) {
				const newValue = isRadio ? filterItem.values[key] : valueItem[0] && valueItem[0].value;

				if (moment.isMoment(newValue)) {
					filterToUpdate.values[key] = newValue;
					if (!newValue.isSame(emptyValue, 'day')) hasSelection = true;
				} else if (type !== 'object') {
					filterToUpdate.values[key] = newValue || emptyValue;
					if (newValue && newValue !== emptyValue && trim(newValue)) {
						hasSelection = true;
					}
				} else {
					filterToUpdate.values[key] = newValue || emptyValue[key];
					if (newValue && newValue !== emptyValue[key] && trim(newValue)) {
						hasSelection = true;
					}
				}
			} else {
				if (checkbox || isRadio) {
					filterToUpdate.values = filterItem.values;
					if (filterToUpdate.values[key]) hasSelection = true;
				} else if (type === 'object') {
					filterToUpdate.values[key] = emptyValue[key];
				} else {
					filterToUpdate.values[key] = emptyValue;
				}
			}

			const currentValue = filterToUpdate.values[key];
			if (moment.isMoment(currentValue)) {
				if (!currentValue.isSame(emptyValue, 'day')) hasSelection = true;
			} else if (typeof currentValue === 'string') {
				if (trim(currentValue) && currentValue !== emptyValue) hasSelection = true;
			} else if (currentValue !== null && currentValue !== undefined) {
				if (type === 'object' ? currentValue !== emptyValue[key] : currentValue !== emptyValue) {
					hasSelection = true;
				}
			}
		});

		const hasSelectionFinal = Object.entries(filterToUpdate.values).some(([k, val]) => {
			if (moment.isMoment(val)) return !val.isSame(emptyValue, 'day');
			if (type === 'object') return val !== emptyValue[k];
			return val !== emptyValue && val !== null && val !== undefined && trim(val);
		});

		const updatedFilters = cloneDeep(this.props.filters);
		const filterIndex = updatedFilters.findIndex(f => f.key === filterToUpdate.key);
		updatedFilters[filterIndex] = {
			...filterToUpdate,
			hasSelection: hasSelection || hasSelectionFinal,
		};

		return updatedFilters;
	};

	renderCustomSubMenuItemTitle = (_, key) => {
		const item = this.getItem(key);
		return (
			<span>
				<span className="selected rc-menu-input-text">
					{item.getSelectionText && item.getSelectionText(item.values, this.state.dates, true)}
				</span>
			</span>
		);
	};

	onOpenChange = activeKeys => {
		this.setState({ activeKeys: activeKeys });
	};

	getItem = key => {
		const item = find(this.props.filters, { key: key });
		return { ...item };
	};

	getActiveItem = key => {
		const item = find(this.props.activeFilters, { key: key });
		return { ...item };
	};

	onFilterChanged = item => {
		const filter = this.getItem(item.id);
		const updatedFilters = this.updateFilters(filter, item);
		this.setState({ activeKeys: [] });
		this.props.onFiltersUpdate(updatedFilters);
	};

	onActiveFilterChanged = item => {
		const filter = this.getActiveItem(item.id);
		const updatedFilters = this.updateFilters(filter, item);
		this.setState({ activeKeys: [] });
		this.props.onFiltersUpdate(null, updatedFilters);
	};

	onFilterClear = key => {
		const filter = this.getItem(key);
		const item = {
			id: key,
			emptyValue: key === 'cvv' ? filter.defaultValues : filter.defaultValues[key],
			values: key === 'cvv' ? filter.defaultValues : [filter.defaultValues],
		};
		this.onFilterChanged(item);
	};

	resetFilters = cacheOldFilters => {
		this.props.resetFilters(cacheOldFilters);
		const { filters } = this.props;
		for (let filter of filters) {
			if (filter.key !== 'date') {
				this.onFilterClear(filter.key);
			}
		}
	};

	applyFilter = () => {
		this.props.onFiltersUpdate(cloneDeep(this.props.filters), this.props.filters);
	};
	toggleCard = card => () => {
		const expandedCards = { ...this.state.expandedCards };
		expandedCards[card] = !expandedCards[card];
		this.setState({ expandedCards });
	};
	toggleExpanded = () => {
		this.setState({
			isExpanded: !this.state.isExpanded,
		});
	};

	setDaySelection = key => {
		this.references['date'].current.setDaySelection(key);
	};

	setCustomDaySelection = range => {
		this.references['date'].current.setCustomDaySelection(range);
	};

	getSortOptions = () => {
		const options = TransactionsSortBy;
		let visibleOptions = [];
		options.map(val => {
			if (val.visible === true) {
				visibleOptions.push({
					value: val.key,
					label: val.label,
				});
			}
		});
		return visibleOptions;
	};

	handleSortByChange = (selected, changed) => {
		let options = selected;
		if (changed.action === 'clear') {
			options = {};
		}
		this.setState(
			{
				transactionsSortBy: options,
			},
			() => this.updateSortFromState()
		);
	};

	updateSortFromState = () => {
		const options = TransactionsSortBy;
		const { transactionsSortBy } = this.state;
		const selectedItem = filter(options, { key: transactionsSortBy.value })[0];
		this.props.onSortUpdate(selectedItem);
	};

	filterReportByPermissons = reports => {
		const {
			permissions: { allowReportDeclined, allowReportApproved },
			userRole,
		} = this.state;

		if (!allowReportApproved) {
			reports = filter(reports, ({ filters }) => !filters.status || (filters.status && !filters.status.approved));
		} else if (!allowReportDeclined) {
			reports = filter(reports, ({ filters }) => !filters.status || (filters.status && !filters.status.declined));
		}
		if (!this.csRep && !['admin', 'advanced'].includes(userRole)) {
			reports = filter(reports, report => !report.advancedReport);
		}
		return reports;
	};

	renderCustomReportsMessage = () => (
		<div className="message message--default spc--bottom--sml">
			Create custom reports using the form
			<span className="hide--to--lrg--inline"> to the left</span>
			<span className="hide--from--lrg"> at the top</span>. Reports are saved for future access.
		</div>
	);

	render() {
		const {
			isExpanded,
			dates,
			permissions,
			permissions: { allowReportDeclined, allowReportApproved },
			expandedCards: { quickReports, customReports, otherReports },
		} = this.state;
		const { canShowMoreCustomReports, reportToEdit, renderToolbar, hideSourceKey } = this.props;
		const isAuth = get(this.getItem('transactionType'), 'values.auth', false);

		return (
			<Fragment>
				<header className="header">
					<div className="header__breadcrumbs">Reports</div>
					<div className="header__menu">
						<UserAccountPanel />
					</div>
				</header>

				<div className="l--content">
					<div className="row">
						<div className="col col-sml-12 col-xlrg-7 col-xxxlrg-8 spc--bottom--lrg">
							{reportToEdit ? (
								<div className="flex--tertiary spc--bottom--lrg">
									<h4>Edit - {reportToEdit.name} </h4>
									<button type="button" className="btn btn--med btn--tertiary" onClick={this.props.handleCancel}>
										Cancel
									</button>
								</div>
							) : (
								<h3 className="spc--bottom--lrg">Reports</h3>
							)}
							<div className="card">
								<div className="card__body">
									<h5 className="spc--bottom--lrg">Create new report</h5>
									<div className="f-row">
										<div className="f-col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<span className="form__group__label">Select Date:</span>
											</div>
											<div className="rc-menu-select">
												<DatePickerPredefined
													maxDaysRange={Infinity}
													subMenuTitle={this.renderCustomSubMenuItemTitle('Custom', 'date')}
													onOpenChange={this.onOpenChange}
													activeKeys={this.state.activeKeys}
													filter={this.getItem('date')}
													onApplyFilter={this.applyFilter}
													onFilterChanged={this.onFilterChanged}
													onActiveFilterChanged={this.onFilterChanged}
													ref={this.references['date']}
													predefinedDates={dates}
												/>
											</div>
										</div>
										<div className="f-col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<span className="form__group__label">View / Sort by:</span>
											</div>
											<Select
												value={this.state.transactionsSortBy}
												onChange={this.handleSortByChange}
												options={this.getSortOptions()}
												isClearable={false}
												isSearchable={false}
												className="react-select-container"
												classNamePrefix="react-select"
											/>
										</div>
										<div className="f-col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<span className="form__group__label">Transaction Type</span>
											</div>
											<TransactionTypeReportsFilter
												filter={this.getItem('transactionType')}
												onFilterChanged={this.onFilterChanged}
												ref={this.references['transactionType']}
												status={this.getItem('status')}
											/>
										</div>
										{!allowReportApproved && !allowReportDeclined ? null : (
											<div className="f-col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<span className="form__group__label">Transaction Status</span>
													{!isAuth && (
														<i
															className="icon icon--tny icon--regular--info"
															data-tooltip="Transaction status is disabled when Transaction type Auth is selected"
														></i>
													)}
												</div>
												<StatusReportsFilter
													filter={this.getItem('status')}
													onFilterChanged={this.onFilterChanged}
													ref={this.references['status']}
													permissions={permissions}
													transactionType={this.getItem('transactionType')}
												/>
											</div>
										)}
									</div>

									<div className="reports__filter">
										<div className="reports__filter__header">
											{isExpanded ? <h6>Advance filters</h6> : ''}
											<a
												href="javascript:void(0)"
												className="btn btn--sml btn--link align--h--right"
												onClick={this.toggleExpanded}
											>
												{isExpanded ? 'Hide advance filters' : 'Advance filters'}
											</a>
										</div>

										<div className="reports__filter__list" style={{ display: isExpanded ? 'block' : 'none' }}>
											<ExpandFilterContainerComponent
												label="Amount"
												filterKey="amount"
												component={
													<NumberFilter
														filter={this.getItem('amount')}
														onFilterChanged={this.onFilterChanged}
														inputMode="decimal"
														thousandSeparator=","
														decimalSeparator="."
													/>
												}
												filter={this.getItem('amount')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['amount']}
											/>
											<ExpandFilterContainerComponent
												label="Cardholder name"
												filterKey="cardholderName"
												component={
													<StringFilter
														filter={this.getItem('cardholderName')}
														onFilterChanged={this.onFilterChanged}
													/>
												}
												filter={this.getItem('cardholderName')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['cardholderName']}
											/>
											<ExpandFilterContainerComponent
												label="Invoice"
												filterKey="invoice"
												component={
													<StringFilter filter={this.getItem('invoice')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('invoice')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['invoice']}
											/>
											<ExpandFilterContainerComponent
												label="AVS"
												filterKey="avs"
												component={
													<SelectFilterCustom
														filter={this.getItem('avs')}
														onFilterChanged={this.onFilterChanged}
														isDropdown={true}
													/>
												}
												filter={this.getItem('avs')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['avs']}
											/>
											<ExpandFilterContainerComponent
												label="Batch"
												filterKey="batch"
												component={
													<NumberFilter filter={this.getItem('batch')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('batch')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['batch']}
											/>
											<ExpandFilterContainerComponent
												label="Description"
												filterKey="description"
												component={
													<StringFilter filter={this.getItem('description')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('description')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['description']}
											/>
											<ExpandFilterContainerComponent
												label="Auth code"
												filterKey="authCode"
												component={
													<StringFilter filter={this.getItem('authCode')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('authCode')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['authCode']}
											/>
											<ExpandFilterContainerComponent
												label="Terminal number"
												filterKey="terminal"
												component={
													<NumberFilter filter={this.getItem('terminal')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('terminal')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['terminal']}
											/>
											<ExpandFilterContainerComponent
												label="Card Number"
												filterKey="cardNumber"
												component={
													<LastDigitsFilter
														numberOfDigits={4}
														filter={this.getItem('cardNumber')}
														onFilterChanged={this.onFilterChanged}
													/>
												}
												filter={this.getItem('cardNumber')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['cardNumber']}
											/>
											<ExpandFilterContainerComponent
												label="Reference Number"
												filterKey="referenceNumber"
												component={
													<NumberFilter
														filter={this.getItem('referenceNumber')}
														onFilterChanged={this.onFilterChanged}
													/>
												}
												filter={this.getItem('referenceNumber')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['referenceNumber']}
											/>
											<ExpandFilterContainerComponent
												label="CVV Result"
												filterKey="cvv"
												component={
													<SelectFilterCustom
														filter={this.getItem('cvv')}
														onFilterChanged={this.onFilterChanged}
														singleSelect={true}
													/>
												}
												filter={this.getItem('cvv')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['cvv']}
											/>
											<ExpandFilterContainerComponent
												label="Card type"
												filterKey="cardType"
												component={
													<CardTypeFilter filter={this.getItem('cardType')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('cardType')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['cardType']}
											/>
											<ExpandFilterContainerComponent
												label="PO number"
												filterKey="poNumber"
												component={
													<StringFilter filter={this.getItem('poNumber')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('poNumber')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['poNumber']}
											/>
											<ExpandFilterContainerComponent
												label="Order ID"
												filterKey="orderId"
												component={
													<StringFilter filter={this.getItem('orderId')} onFilterChanged={this.onFilterChanged} />
												}
												filter={this.getItem('orderId')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['orderId']}
											/>
											<ExpandFilterContainerComponent
												label="Custom fields"
												filterKey="custom"
												component={
													<CustomFieldsFilter
														filter={this.getItem('custom')}
														numberOfCustomFields={3}
														onFilterChanged={this.onFilterChanged}
													/>
												}
												filter={this.getItem('custom')}
												onFilterChanged={this.onFilterChanged}
												onFilterClear={this.onFilterClear}
												ref={this.references['custom']}
											/>
											{!hideSourceKey && (
												<ExpandFilterContainerComponent
													label="Source key"
													filterKey="sourceKey"
													component={
														<StringFilter filter={this.getItem('sourceKey')} onFilterChanged={this.onFilterChanged} />
													}
													filter={this.getItem('sourceKey')}
													onFilterChanged={this.onFilterChanged}
													onFilterClear={this.onFilterClear}
													ref={this.references['sourceKey']}
												/>
											)}
										</div>
									</div>
								</div>
								<div className="card__footer">{renderToolbar()}</div>
							</div>
						</div>
						<div className="col col-sml-12 col-xlrg-5 col-xxxlrg-4">
							<div className={`card${customReports ? ' is-expanded' : ''} spc--bottom--sml--alt`}>
								<button
									className="card__header card__header--expandable"
									onClick={this.toggleCard('customReports')}
									onKeyDown={this.toggleCard('customReports')}
								>
									<h5>Custom Saved Reports</h5>
									<i className={`icon icon--med icon--chevron--${customReports ? 'down' : 'top'}--primary`}></i>
								</button>
								{customReports && (
									<div className="card__body">
										<ul className="reports__list">
											<QuickReportsFilterComponent
												filters={this.props.filters}
												reports={this.props.customReports}
												initialRecordsLimit={this.props.initialRecordsLimit}
												transactionsSortBy={this.props.transactionsSortBy}
												onFilterChanged={this.onFilterChanged}
												setDaySelection={this.setDaySelection}
												resetFilters={this.resetFilters}
												onSortUpdate={this.props.onSortUpdate}
												onQuickReportDownload={this.props.onQuickReportDownload}
												setFilters={this.props.setFilters}
												isLoading={this.props.isLoading}
												openDeleteModal={this.props.openDeleteModal}
												handleEdit={this.props.handleEdit}
												topRef={this.props.topRef}
												renderEmptyView={this.renderCustomReportsMessage}
												error={this.props.error}
											/>
										</ul>
										{canShowMoreCustomReports && !this.props.isLoading && (
											<div className="spc--top--sml">
												<a
													href="javascript:void(0)"
													onClick={this.props.showMoreCustomReports}
													className="btn btn--link btn--link--underline"
												>
													Show more reports
												</a>
											</div>
										)}
									</div>
								)}
							</div>
							<div className={`card${quickReports ? ' is-expanded' : ''} spc--bottom--sml--alt`}>
								<button
									className="card__header card__header--expandable"
									onClick={this.toggleCard('quickReports')}
									onKeyDown={this.toggleCard('quickReports')}
								>
									<h5>Quick Reports</h5>
									<i className={`icon icon--med icon--chevron--${quickReports ? 'down' : 'top'}--primary`}></i>
								</button>
								{quickReports && (
									<div className="card__body">
										<ul className="reports__list">
											<QuickReportsFilterComponent
												filters={this.props.filters}
												reports={this.filterReportByPermissons(cloneDeep(QuickReports))}
												initialRecordsLimit={this.props.initialRecordsLimit}
												transactionsSortBy={this.props.transactionsSortBy}
												onFilterChanged={this.onFilterChanged}
												setDaySelection={this.setDaySelection}
												resetFilters={this.resetFilters}
												onSortUpdate={this.props.onSortUpdate}
												onQuickReportDownload={this.props.onQuickReportDownload}
												setFilters={this.props.setFilters}
												isLoading={false}
											/>
										</ul>
									</div>
								)}
							</div>
							<div className={`card${otherReports ? ' is-expanded' : ''} spc--bottom--sml--alt`}>
								<button
									className="card__header card__header--expandable"
									onClick={this.toggleCard('otherReports')}
									onKeyDown={this.toggleCard('otherReports')}
								>
									<h5>Other Reports</h5>
									<i className={`icon icon--med icon--chevron--${otherReports ? 'down' : 'top'}--primary`}></i>
								</button>
								{otherReports && (
									<div className="card__body">
										<ul className="reports__list">
											<QuickReportsFilterComponent
												filters={this.props.filters}
												reports={this.filterReportByPermissons(cloneDeep(OtherReports()))}
												initialRecordsLimit={this.props.initialRecordsLimit}
												transactionsSortBy={this.props.transactionsSortBy}
												onFilterChanged={this.onFilterChanged}
												setDaySelection={this.setDaySelection}
												setCustomDaySelection={this.setCustomDaySelection}
												resetFilters={this.resetFilters}
												onSortUpdate={this.props.onSortUpdate}
												onQuickReportDownload={this.props.onQuickReportDownload}
												setFilters={this.props.setFilters}
												isLoading={false}
											/>
										</ul>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

MainFilterComponent.propTypes = {
	filters: PropTypes.array,
	customReports: PropTypes.array,
	activeFilters: PropTypes.array,
	initialRecordsLimit: PropTypes.number,
	onFiltersUpdate: PropTypes.func,
	onSortUpdate: PropTypes.func,
	onQuickReportDownload: PropTypes.func,
	setFilters: PropTypes.func,
	isLoading: PropTypes.bool,
	showMoreCustomReports: PropTypes.func,
	canShowMoreCustomReports: PropTypes.bool,
	openDeleteModal: PropTypes.func,
	handleEdit: PropTypes.func,
	handleCancel: PropTypes.func,
	reportToEdit: PropTypes.object,
	renderToolbar: PropTypes.func,
	topRef: PropTypes.object,
	transactionsSortBy: PropTypes.object,
	resetFilters: PropTypes.func,
	error: PropTypes.string,
	hideSourceKey: PropTypes.any,
};

MainFilterComponent.defaultProps = {
	filters: [],
	activeFilters: [],
	onFiltersUpdate: () => {
		//eslint-disable-next-line
		console.warning('Provide onFilterUpdate method');
	},
};

export default MainFilterComponent;
