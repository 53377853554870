import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, split } from 'lodash';

import { Modal } from 'common/components/modal';
import { kvaasService, principalService } from 'common/services';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { initialPageOptions, kvaasResources, logger } from 'common/utilities';
import { withRouter } from 'react-router-dom';

const requestKeys = {
	KVAAS: 'kvaas',
};

class UpgradeNotificationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			principal: {},
			userSettings: {},
			oldData: null,
			isLoading: true,
		};
	}

	componentDidMount = async () => {
		try {
			this.subscription = principalService.subscribe(principal => this.setState({ principal }));
			const principal = principalService.get();
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.KVAAS
			);

			this.setState({
				principal,
				userSettings,
				isLoading: false,
				oldData: userSettings,
			});
		} catch (e) {
			this.props.handleError(e);
			this.setState({ isLoading: false });
		}
	};

	componentWillUnmount() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
	redirect = async () => {
		const { history, location } = this.props;
		let search = '';
		let redirectUrl = '/';
		let additionalState;

		try {
			const initial = get(this.state.userSettings, 'data.initialPage', false);
			if (initial) {
				const route = find(initialPageOptions, { key: initial });
				if (route) {
					additionalState = route.state;
					redirectUrl = route.path;
				}
			}
		} catch (e) {
			logger.logError({
				message: 'Initial page redirect error.',
				errorDetails: e,
			});
		} finally {
			this.setState({ isLoading: false });
		}

		if (location.state && location.state.returnUrl) {
			[redirectUrl, search] = split(location.state.returnUrl, '?');
		}

		history.push({
			pathname: redirectUrl,
			search,
			...(additionalState || {}),
		});
	};

	render() {
		const { isLoading } = this.state;

		return (
			<Modal isOpen={true} onClose={this.redirect} isLoading={isLoading}>
				<div className="w--508p w--max--100">
					<div className="popup__header">
						<h2 className="popup__header__title">Important: Your Portal is Moving Soon!</h2>
					</div>
					<div className="popup__body">
						<p className="spc--bottom--med">
							Starting March 18, 2025, the Merchant Portal will move to portal.solapayments.com .
							<br />
							<br />
							<strong>Why the Change?</strong> Cardknox has evolved into Sola, bringing you a refreshed, modern
							experience while keeping the features you trust. This update is based on extensive user feedback and beta
							testing to improve usability. ✅ Your login credentials, PaymentSITE links, and mobile app remain the
							same. ✅ Update your bookmarks and password manager to portal.solapayments.com for easy access.
						</p>
						<p className="message message--default fullwidth">
							Security Reminder: This is an official update from Sola/Cardknox. If you have any concerns about fraud,
							visit our official rebrand page to verify the transition:
							<a
								className="btn btn--anchor"
								href="https://solapayments.com/meet-the-brand/"
								rel="noopener noreferrer"
								target="_blank"
							>
								Meet the Brand
							</a>
						</p>
					</div>
				</div>
			</Modal>
		);
	}
}

UpgradeNotificationModal.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	history: PropTypes.object,
};

export default withError(withLoader(withCancelable(withRouter(UpgradeNotificationModal))));
