import { isEqual, join, replace, split, takeRight } from 'lodash';

export const getCurrentDomain = () => {
	return join(takeRight(split(window.location.hostname, '.'), 2), '.');
};
export const getPortalEndpoints = () => {
	const { oldPortalEndpoint, newPortalEndpoint } = ApplicationSettings;
	let updatedOldPortalEndpoint = oldPortalEndpoint;
	let updatedNewPortalEndpoint = newPortalEndpoint;
	const currentDomain = getCurrentDomain();

	if (isEqual(currentDomain, 'solapayments.com')) {
		updatedOldPortalEndpoint = replace(oldPortalEndpoint, 'cardknox.com', 'solapayments.com');
		updatedNewPortalEndpoint = replace(newPortalEndpoint, 'cardknox.com', 'solapayments.com');
	} else if (isEqual(currentDomain, 'cardknox.com')) {
		updatedOldPortalEndpoint = replace(oldPortalEndpoint, 'solapayments.com', 'cardknox.com');
		updatedNewPortalEndpoint = replace(newPortalEndpoint, 'solapayments.com', 'cardknox.com');
	}

	return { updatedOldPortalEndpoint, updatedNewPortalEndpoint };
};
