import { apiTimezone } from 'common/utilities';
import { get, head, isEmpty } from 'lodash';
import moment from 'moment';
import sectionKeys from 'routing/sections';
import paymentSiteService from '../services/paymentSiteService';
import transactionService from '../services/transactionService';
const { apiDateTimeFormat } = ApplicationSettings;

let pendingPaymentSite = null;
let pendingProPay = null;

export const hasPaymentSites = async principal => {
	if (!principal) return;
	if (localStorage.getItem('hasPaymentSites')) {
		const hasPaymentSites = localStorage.getItem('hasPaymentSites') === 'true';
		principal.hasAccess[sectionKeys.sendPaymentRequest] = hasPaymentSites;
		return hasPaymentSites;
	} else {
		if (!pendingPaymentSite) {
			pendingPaymentSite = new Promise(async resolve => {
				const paymentSite = await paymentSiteService.find();
				resolve(paymentSite);
			});
		}
		let hasPS = false;
		try {
			const paymentSites = await pendingPaymentSite;
			hasPS = !isEmpty(get(paymentSites, 'xReportData'), {});
			principal.hasAccess[sectionKeys.sendPaymentRequest] = hasPS;
		} catch (err) {
			localStorage.removeItem('hasPaymentSites');
		}
		if (hasPS) {
			localStorage.setItem('hasPaymentSites', hasPS);
		} else {
			localStorage.removeItem('hasPaymentSites');
		}
		pendingPaymentSite = false;
		return !!hasPS;
	}
};

export const isProPay = async () => {
	let isProPayAccount = false;
	if (localStorage.getItem('isProPay')) {
		return localStorage.getItem('isProPay') === 'true';
	} else {
		if (!pendingProPay) {
			pendingProPay = new Promise(async resolve => {
				const proPayTransaction = await transactionService.filterTransactionsRequest(
					{
						xMaxRecords: 1,
						xCommand: 'Report:Transactions',
						xBeginDate: moment()
							.subtract(7, 'd')
							.tz(apiTimezone)
							.format(apiDateTimeFormat),
						xEndDate: moment.tz(apiTimezone).format(apiDateTimeFormat),
					},
					1,
					''
				);
				resolve(proPayTransaction);
			});
		}
	}
	try {
		const data = await pendingProPay;
		const transaction = head(get(data, 'xReportData', [{}]));
		isProPayAccount = !isEmpty(transaction) && transaction.xProcessingFee > 0;
		if (isProPayAccount) {
			localStorage.setItem('isProPay', isProPayAccount);
		} else {
			localStorage.removeItem('isProPay');
		}
	} catch (err) {
		localStorage.removeItem('isProPay');
	}
	pendingProPay = false;
	return !!isProPayAccount;
};

export const getDomainName = () => {
	const domainCookie = window.location.hostname
		.split('.')
		.slice(-2)
		.join('.');
	return domainCookie;
};

export const shouldRedirectToUpgrade = () => {
	const lastRedirectTime = localStorage.getItem('lastUpgradeRedirect');
	const now = new Date().getTime();
	const twoDaysInMs = 2 * 24 * 60 * 60 * 1000;

	const shouldRedirectToUpgrade = !lastRedirectTime || now - lastRedirectTime >= twoDaysInMs;
	if (shouldRedirectToUpgrade) {
		localStorage.setItem('lastUpgradeRedirect', now);
	}
	return shouldRedirectToUpgrade;
};
