import React, { useState } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

const PaymentSiteActionsComponent = ({ history, match: { path }, dependentValues }) => {
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const { paymentSiteUrl } = ApplicationSettings;

	const onEdit = () => {
		const { id } = dependentValues;
		history.push(`${path}/${id}`);
	};

	const onCopyToClipboard = e => {
		e.preventDefault();
		e.stopPropagation();
		const { path } = dependentValues;
		if (navigator.clipboard) {
			navigator.clipboard.writeText(`${paymentSiteUrl}${path}`).then(() => {
				setTooltipVisible(true);
				setTimeout(() => {
					setTooltipVisible(false);
				}, 3000);
			});
		}
	};

	return (
		<div className="flex--primary flex--gap--sml flex--right">
			<div className="pos--rel">
				{tooltipVisible && (
					<span className="datatooltip--copy-clipboard datatooltip--copy-clipboard--left">Copied to clipboard!</span>
				)}
				<button type="button" className="btn btn--link" onClick={onCopyToClipboard}>
					<i className="icon icon--sml icon--copy--light" />
				</button>
			</div>
			<button type="button" className="btn btn--link" onClick={onEdit}>
				<i className="icon icon--sml icon--edit--light" />
			</button>
		</div>
	);
};

PaymentSiteActionsComponent.propTypes = {
	dependentValues: object,
	history: object,
	match: object,
};

export default withRouter(PaymentSiteActionsComponent);
